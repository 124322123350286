"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:StatusValidate
 * @description
 * Allows the status to be validated
 */
angular.module("diceApp").component("statusValidate", {
  bindings: {
    transitions: "<",
    changeStatus: "&"
  },
  require: "ngModel",
  templateUrl: "es6/status/status.validate.html",
  controllerAs: "statusValidateController",
  controller: function ($scope) {
    const statusValidateController = this;

    function loadData() {
      statusValidateController.transition = _(
        statusValidateController.transitions
      )
        .filter((t) => angular.isDefined(t.fromStatus))
        .filter((t) => t.toStatus.sequence > t.fromStatus.sequence)
        .orderBy("toStatus.sequence")
        .first();
    }

    loadData();
    $scope.$watch("statusValidateController.transitions", loadData);
  }
});
