"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:generateReportModal
 */
angular.module("diceApp").component("generateReportModal", {
  bindings: {
    evaluations: "<",
    modal: "<"
  },
  templateUrl: "es6/report/generate.report.modal.html",
  controllerAs: "reportOptionsController",
  controller: function (
    Enums,
    CondensedReportTemplate,
    EvaluationType,
    ReportTemplate,
    language,
    $state,
    $window,
    $filter,
    $q,
    $scope
  ) {
    const reportOptionsController = this;
    const DEFAULT_OPTION = "DEFAULT";

    reportOptionsController.options = {
      layout: "CONSECUTIVE"
    };

    reportOptionsController.layoutOptions = [
      {
        name: "CONDENSED",
        href: "condensed-report",
        filterTemplates: function (template) {
          return (
            template.reportType === "CONDENSED" &&
            template.year === reportOptionsController.options.period
          );
        },
        getDefaultSelectedTemplate: function () {
          return _(reportOptionsController.templates)
            .filter({
              reportType: "CONDENSED",
              year: reportOptionsController.options.period
            })
            .map("id")
            .first();
        }
      },
      {
        name: DEFAULT_OPTION,
        href: "composed-report",
        filterTemplates: function (template) {
          return template.reportType !== "CONDENSED";
        },
        getDefaultSelectedTemplate: function () {
          return _(reportOptionsController.templates)
            .filter({ reportType: "PUBLIC_REPORT" })
            .map("id")
            .first();
        }
      }
    ];

    function getLayoutOptions(layoutType) {
      const availableOptions = _.find(
        reportOptionsController.layoutOptions,
        (option) => {
          return option.name === layoutType;
        }
      );

      if (availableOptions) {
        return availableOptions;
      } else {
        return _.find(reportOptionsController.layoutOptions, (option) => {
          return option.name === DEFAULT_OPTION;
        });
      }
    }

    reportOptionsController.$onInit = function () {
      const types = _(reportOptionsController.evaluations)
        .map("type")
        .uniq()
        .value();
      reportOptionsController.options.type = _.first(types);

      EvaluationType.query().$promise.then((found) => {
        reportOptionsController.types = _.filter(found, (type) => {
          return _.indexOf(types, type.id) >= 0;
        });
      });

      Enums.query({
        type: "report-layout"
      }).$promise.then(function (layouts) {
        reportOptionsController.layouts = _.map(layouts, buildLayout);
      });

      reportOptionsController.onTypeChange(
        reportOptionsController.options.type
      );

      $scope.$watch("reportOptionsController.options.period", () => {
        filterTemplates(
          reportOptionsController.options.layout,
          reportOptionsController.options.type
        );
        reportOptionsController.options.template = getLayoutOptions(
          reportOptionsController.options.layout
        ).getDefaultSelectedTemplate();
      });
    };

    function buildLayout(layout) {
      return {
        value: layout,
        name: $filter("enum")(layout),
        description: $filter("enum")(layout + ".Description")
      };
    }

    function filterTemplates(layout) {
      reportOptionsController.templates = _.filter(
        reportOptionsController.reports || [],
        (template) => {
          return getLayoutOptions(layout).filterTemplates(template);
        }
      );
    }

    reportOptionsController.setLayout = function (layout) {
      reportOptionsController.options.layout = layout;
      filterTemplates(layout, reportOptionsController.options.type);

      // Set default template if no template is selected
      if (
        angular.isUndefined(reportOptionsController.options.template) ||
        reportOptionsController.options.template === null
      ) {
        reportOptionsController.options.template = getLayoutOptions(
          reportOptionsController.options.layout
        ).getDefaultSelectedTemplate();
      }
    };

    reportOptionsController.onTypeChange = function (evaluationType) {
      getReports(evaluationType).then((reports) => {
        reportOptionsController.reports = reports;
        filterTemplates(reportOptionsController.options.layout);

        // Select the 'default' public report
        reportOptionsController.options.template = getLayoutOptions(
          reportOptionsController.options.layout
        ).getDefaultSelectedTemplate();
      });
    };

    function getReports(evaluationType) {
      if (angular.isDefined(evaluationType)) {
        return $q
          .all([
            ReportTemplate.query({ evaluationType, active: true }).$promise,
            CondensedReportTemplate.query({ evaluationType }).$promise
          ])
          .then(([reports, condensed]) => {
            return reports.concat(condensed);
          });
      } else {
        return $q.resolve([]);
      }
    }

    reportOptionsController.close = function () {
      reportOptionsController.modal.close();
    };

    reportOptionsController.generate = function () {
      reportOptionsController.close();

      const evaluationIds = _.map(reportOptionsController.evaluations, "id");
      const address = $state.href(
        getLayoutOptions(reportOptionsController.options.layout).href,
        {
          evaluations: evaluationIds.join(","),
          layout: reportOptionsController.options.layout,
          template: reportOptionsController.options.template,
          language: language.get()
        }
      );

      $window.open(address, "_blank");
    };
  }
});
