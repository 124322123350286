"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:organisationDropdown
 * @description
 * The organisationDropdown directive.
 */
angular.module("diceApp").component("organisationDropdown", {
  bindings: {
    disabled: "@?"
  },
  require: {
    ngModelCtrl: "ngModel"
  },
  templateUrl: "es6/organisation/organisation.dropdown.html",
  controllerAs: "organisationDropdownController",
  controller: function (Organisation) {
    const organisationDropdownController = this;

    organisationDropdownController.$onInit = function () {
      organisationDropdownController.editable =
        organisationDropdownController.disabled !== "disabled";

      Organisation.get().$promise.then((organisations) => {
        organisationDropdownController.organisations = organisations.content;
      });

      organisationDropdownController.ngModelCtrl.$render = () => {
        organisationDropdownController.value =
          organisationDropdownController.ngModelCtrl.$viewValue;
      };
    };

    organisationDropdownController.update = function () {
      organisationDropdownController.ngModelCtrl.$setViewValue(
        organisationDropdownController.value
      );
    };
  }
});
