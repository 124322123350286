"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:ScheduleWorkflow
 * @description
 * # ScheduleWorkflow
 * ScheduleWorkflow resource of the diceApp
 */
angular.module("diceApp").factory("ScheduleWorkflow", function (DiceResource) {
  return DiceResource("/schedule-workflow", {
    next: {
      url: "/schedule-workflow/next/:evaluationId",
      method: "GET",
      isArray: false
    },
    calculate: {
      url: "/schedule-workflow/calculate/:evaluationId",
      method: "GET",
      isArray: true
    },
    retry: {
      url: "/schedule-workflow/retry",
      method: "POST"
    }
  });
});
