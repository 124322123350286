"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:openEvaluations
 * @description
 * OpenEvaluations Shows the open evaluations of a participant.
 */
angular.module("diceApp").component("diceOpenEvaluations", {
  bindings: {
    evaluationId: "<",
    session: "<"
  },
  templateUrl: "es6/participate/open.evaluations.html",
  controllerAs: "openEvaluationsController",
  controller: function (Participate) {
    const openEvaluationsController = this;

    openEvaluationsController.$onChanges = function () {
      loadData();
    };

    function loadData() {
      openEvaluationsController.loading = true;

      Participate.open({
        evaluationId: openEvaluationsController.evaluationId,
        session: openEvaluationsController.session
      })
        .$promise.then((participants) => {
          openEvaluationsController.participants = participants;
        })
        .finally(() => {
          openEvaluationsController.loading = false;
        });
    }
  }
});
