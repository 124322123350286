"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:QueryTemplate
 * @description
 * # QueryTemplate
 * QueryTemplate resource of the diceApp
 */
angular
  .module("diceApp")
  .factory("QueryTemplate", function (DiceResource, LocalStorage) {
    const instance = DiceResource("/query-templates", {
      mine: {
        url: "/query-templates/mine",
        method: "GET",
        isArray: true
      }
    });

    instance.getDefault = function (type) {
      const key = getCacheKey(type);
      const name = LocalStorage.get(key);

      return instance.mine({ type }).$promise.then((templates) => {
        let template = undefined;
        if (name) {
          template = _.find(templates, { name });
        }
        if (angular.isUndefined(template)) {
          template = _(templates).sortBy(["sequence", "name", "id"]).first();
        }
        return template;
      });
    };

    instance.setDefault = function (type, template) {
      const key = getCacheKey(type);
      const name = _.result(template, "name");

      LocalStorage.set(key, name);
    };

    function getCacheKey(type) {
      return `query-${type}`;
    }

    return instance;
  });
