"use strict";

angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("user-logins", {
    url: "/user-logins",
    data: {
      root: true
    },
    controllerAs: "userLoginsStateController",
    template: "<user-logins-report></user-logins-report>"
  });
});
