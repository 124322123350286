"use strict";

/**
 * @ngdoc factory
 * @name diceApp.factory:EvaluationType
 * @description Resource for EvaluationType.
 */
angular
  .module("diceApp")
  .factory("EvaluationType", function ($q, DiceResource) {
    const resource = DiceResource("/evaluation-types", {
      search: {
        url: "/evaluation-types/search",
        isArray: true,
        method: "GET"
      },
      style: {
        url: "/evaluation-types/:id/style",
        isArray: false,
        method: "GET"
      }
    });

    resource.getStyle = function (id) {
      if (angular.isUndefined(id)) {
        return $q.resolve({});
      } else {
        return resource.style({
          id: id
        }).$promise;
      }
    };

    return resource;
  });
