"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Evaluation
 * @description
 * # Evaluation
 * Evaluation resource of the diceApp
 */
angular.module("diceApp").factory("EvaluationSession", function (DiceResource) {
  return DiceResource("/evaluation-sessions", {
    current: {
      method: "GET",
      url: "/evaluation-sessions/current",
      isArray: false
    }
  });
});
