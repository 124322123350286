"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:CopyButton
 * @CopyButton
 * The CreateButton service.
 */
angular.module("diceApp").directive("copyButton", function () {
  return {
    restrict: "E",
    scope: {
      btnType: "@?",
      btnSize: "@?",
      btnIconOnly: "=?",
      btnContext: "@?"
    },
    link: function (scope) {
      scope.btnContext = scope.btnContext || "default";
    },
    templateUrl: "es6/base/forms/buttons/copy.button.html"
  };
});
