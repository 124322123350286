"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceEditOptions
 * @description
 * diceEditOptions edit the options of a question
 */
angular.module("diceApp").component("diceEditOptions", {
  bindings: {
    templateId: "<",
    options: "<",
    onChange: "&",
    advanced: "<?"
  },
  templateUrl: "es6/templates/option/edit.options.html",
  controllerAs: "optionsController",
  controller: function (OptionTemplate) {
    const optionsController = this;

    optionsController.$onInit = function () {
      OptionTemplate.query().$promise.then((templates) => {
        optionsController.templates = templates;
        optionsController.onTemplate(false);
      });
    };

    optionsController.onTemplate = function (reset) {
      const template = _.find(optionsController.templates, {
        id: optionsController.templateId
      });

      optionsController.selected = template;
      if (angular.isDefined(template)) {
        optionsController.options = template.options;
      } else if (reset) {
        optionsController.options = [];
      }

      if (angular.isFunction(optionsController.onChange)) {
        optionsController.onChange({
          options: optionsController.options,
          templateId: optionsController.templateId
        });
      }
    };

    optionsController.optionsChanged = function (options) {
      optionsController.options = options;
      optionsController.onChange({
        options: optionsController.options,
        templateId: optionsController.templateId
      });
    };
  }
});
