"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceNotificationTemplateList
 * @description
 * # diceNotificationTemplateList
 * Controller of the diceApp
 */
angular.module("diceApp").component("diceNotificationTemplateList", {
  templateUrl: "es6/notifications/template/notifications.template.list.html",
  controllerAs: "listController",
  controller: function (
    NotificationTemplate,
    EvaluationType,
    $uibModal,
    message,
    $filter,
    $q
  ) {
    const listController = this;

    listController.$onInit = function () {
      loadData();
    };

    function loadData() {
      listController.loading = true;

      $q.all([
        NotificationTemplate.query().$promise,
        EvaluationType.query().$promise
      ])
        .then(([notifications, evaluationTypes]) => {
          listController.notifications = notifications;

          listController.evaluationTypes = evaluationTypes;
          listController.evaluationType = _.find(evaluationTypes, {
            defaultSelected: true
          });
          listController.filterData();
        })
        .finally(() => {
          listController.loading = false;
        });
    }

    listController.filterData = function () {
      const evaluationTypeId = _.result(listController.evaluationType, "id");

      listController.filteredNotifications = _.filter(
        listController.notifications,
        {
          evaluationType: evaluationTypeId
        }
      );
    };

    listController.create = function () {
      open({
        evaluationType: _.result(listController.evaluationType, "id")
      });
    };

    listController.copy = function (notificationTemplate, $event) {
      $event.stopPropagation();

      let copy = angular.copy(notificationTemplate);
      copy.subjects = _.map(copy.subjects, function (subject) {
        delete subject.id;
        subject.value =
          $filter("translate")("Generic.CopyOf") + " " + subject.value;
        return subject;
      });
      copy.bodies = _.map(copy.bodies, function (body) {
        delete body.id;
        return body;
      });

      delete copy.id;
      open(copy);
    };

    listController.edit = function (notificationTemplate) {
      NotificationTemplate.get({
        id: notificationTemplate.id
      }).$promise.then(open);
    };

    function open(notificationTemplate) {
      $uibModal.open({
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.modal = $uibModalInstance;
          modalController.notificationTemplate =
            angular.copy(notificationTemplate);
          _.forEach(modalController.notificationTemplate.memberTypes, (it) => {
            it.memberType = _.result(it, "memberType.id");
          });

          modalController.onSave = function (result) {
            var found = _.find(listController.notifications, { id: result.id });
            if (found) {
              _.extend(found, result);
            } else {
              listController.notifications.push(result);
            }
            listController.filterData();
            message.addSuccessLabel("Message.Save.Success");
          };
        },
        size: "lg",
        template: `
            <notification-template-modal
              notification-template="modalController.notificationTemplate"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </notification-template-modal>
          `
      });
    }

    listController.remove = function (notificationTemplate) {
      NotificationTemplate.remove(notificationTemplate.id).$promise.then(() => {
        message.addSuccessLabel("Message.Delete.Success");
        _.remove(listController.notifications, { id: notificationTemplate.id });
        listController.filterData();
      });
    };
  }
});
