"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:EvaluationTemplateStates
 * @description
 * # EvaluationTemplateStates
 * Configures all states for evaluation templates.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider
    .state("evaluation-template-list", {
      url: "/evaluation-templates",
      data: {
        root: true
      },
      template:
        '<dice-evaluation-template-list templates="templates"></dice-evaluation-template-list>'
    })
    .state("evaluation-template-create", {
      url: "/evaluation-templates/create",
      params: {
        type: undefined
      },
      controllerAs: "evaluationTemplateCreateController",
      controller: function ($stateParams) {
        const evaluationTemplateCreateController = this;
        evaluationTemplateCreateController.type = $stateParams.type;
      },
      template: `
      <dice-evaluation-template
        template="{type: evaluationTemplateCreateController.type}">
      </dice-evaluation-template>`
    })
    .state("evaluation-template", {
      url: "/evaluation-templates/{id:[0-9]{1,8}}",
      resolve: {
        template: function ($stateParams, EvaluationTemplate) {
          return EvaluationTemplate.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "evaluationTemplateController",
      controller: function ($scope, template) {
        const evaluationTemplateController = this;
        evaluationTemplateController.template = template;
      },
      template: `
      <dice-evaluation-template
        template="evaluationTemplateController.template">
      </dice-evaluation-template>`
    })
    .state("evaluation-template-copy", {
      url: "/evaluation-templates/{id:[0-9]{1,8}}/copy",
      resolve: {
        template: function ($stateParams, EvaluationTemplate) {
          return EvaluationTemplate.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "evaluationTemplateCopyController",
      controller: function ($filter, template) {
        const evaluationTemplateCopyController = this;

        const clone = angular.copy(template);
        clone.defaultSelected = false;
        clone.name = $filter("translate")("Generic.CopyOf") + " " + clone.name;
        clone.titles = _.map(clone.titles, (label) => {
          delete label.id;
          label.value =
            $filter("translate")("Generic.CopyOf") + " " + label.value;
          return label;
        });
        clone.blocks.forEach((block) => {
          delete block.id;
        });
        clone.defaultExtraQuestionsBlockNames.forEach((name) => {
          delete name.id;
        });
        evaluationTemplateCopyController.template = _.omit(clone, ["id"]);
      },
      template: `
      <dice-evaluation-template
        template="evaluationTemplateCopyController.template">
      </dice-evaluation-template>`
    });
});
