"use strict";

angular.module("diceApp").component("formGroup", {
  bindings: {
    label: "@",
    labelAlign: "@?",
    labelWidth: "@?",
    inputClasses: "<?",
    viewClasses: "<?",
    isRequired: "<?",
    requiredModel: "<?"
  },
  require: {
    diceForm: "?^diceForm"
  },
  transclude: true,
  templateUrl: "es6/base/forms/form.group.html",
  controllerAs: "formGroupController",
  controller: function ($sanitize) {
    const formGroupController = this;

    formGroupController.$onInit = function () {
      formGroupController.infoLabelHtml = formGroupController.infoLabelHtml
        ? $sanitize(formGroupController.infoLabelHtml)
        : undefined;

      formGroupController.labelAlign =
        formGroupController.labelAlign || "right";

      if (angular.isUndefined(formGroupController.labelWidth)) {
        formGroupController.labelWidth = _.get(
          formGroupController,
          "diceForm.labelWidth"
        );
      }

      formGroupController.labelWidth_ = `${formGroupController.labelWidth || 3}`;
      formGroupController.inputWidth =
        12 - parseInt(formGroupController.labelWidth_);
    };
  }
});
