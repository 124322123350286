"use strict";

angular.module("diceApp").component("condensedReportWidget", {
  templateUrl: "es6/dashboard/condensed.report.widget.html",
  controllerAs: "condensedReportWidgetController",
  controller: function (CondensedReportTemplate) {
    const condensedReportWidgetController = this;

    condensedReportWidgetController.$onInit = function () {
      CondensedReportTemplate.query().$promise.then((reports) => {
        condensedReportWidgetController.reports = _.filter(
          reports,
          "showOnDashboard"
        );
      });
    };
  }
});
