"use strict";

/**
 * @ngdoc service
 * @name diceApp.filter
 * @description
 * # answerOptions
 * filterStore in the diceApp.
 */
angular
  .module("diceApp")
  .factory(
    "ReportExcelExporter",
    function (Sheet, descriptionFilter, numberFilter, stripHtmlFilter) {
      return { buildReport, buildComposed };

      function buildComposed(args) {
        const { reports } = args;

        const sheet = Sheet.create();
        _.forEach(reports, (report) => {
          addQuestions(report, sheet);
        });
        return sheet;
      }

      function buildReport(args) {
        const { report } = args;

        const sheet = Sheet.create();
        addQuestions(report, sheet);
        return sheet;
      }

      function addQuestions(report, sheet) {
        _(report.reportBlocks)
          .orderBy("sequence")
          .forEach((block) => {
            _(block.questionReports)
              .orderBy("sequence")
              .forEach((question) => {
                let row = {
                  code: report.evaluation.code,
                  evaluation: descriptionFilter(report.evaluation.names),
                  period: _.get(report.evaluation, "period.period", "*"),
                  year: _.get(report.evaluation, "period.displayYear", "*"),
                  report: descriptionFilter(report.template.names),
                  block: stripHtmlFilter(block.blockLabel),
                  member: _.get(block.member, "name"),
                  question: stripHtmlFilter(question.questionLabel),
                  average: numberFilter(question.averageValue, 2),
                  deviation: numberFilter(question.standardDeviationValue, 2),
                  respondents: numberFilter(question.respondentCount, 0),
                  "period-average": getBenchmark(question.benchmark),
                  "period-difference": getDifference(
                    question.benchmark,
                    question.averageValue
                  ),
                  "similar-average": getBenchmark(question.benchmarkFilter),
                  "similar-difference": getDifference(
                    question.benchmarkFilter,
                    question.averageValue
                  ),
                  answers: getAnswers(question)
                };

                _(question.columns)
                  .sortBy("sequence")
                  .forEach((column) =>
                    addOption(row, `column-${column.value}`, column)
                  );
                _(question.options)
                  .sortBy("sequence")
                  .forEach((option) =>
                    addOption(row, `value-${option.value}`, option)
                  );

                sheet.push(row);
              });
          });
      }

      function addOption(row, key, option) {
        row[`${key}`] = option.count;
        if (angular.isDefined(option.percentage)) {
          row[`${key}-percentage`] = option.percentage;
        }
        if (angular.isDefined(option.averageValue)) {
          row[`${key}-average`] = option.averageValue;
        }
      }

      function getAnswers(question) {
        if (_.includes(["BULLET", "TEXT"], question.visualization)) {
          return _(question.options).map("value").uniq().sort().join(", ");
        }

        return "";
      }

      function getBenchmark(benchmark) {
        const benchmarkValue = _.get(benchmark, "average");
        return numberFilter(benchmarkValue, 2);
      }

      function getDifference(benchmark, averageValue) {
        const benchmarkValue = _.get(benchmark, "average");
        if (
          angular.isDefined(benchmarkValue) &&
          angular.isDefined(averageValue)
        ) {
          const difference = _.round(averageValue - benchmarkValue, 2);
          return numberFilter(difference, 2);
        }
      }
    }
  );
