"use strict";

angular.module("diceApp").component("reportQuestionSelect", {
  require: {
    ngModelCtrl: "ngModel"
  },
  bindings: {
    evaluationType: "<",
    hideOpenQuestions: "<?",
    onChange: "&?"
  },
  templateUrl: "es6/report/template/report.question.select.html",
  controllerAs: "reportQuestionSelectController",
  controller: function ($q, $uibModal, QuestionTemplate) {
    const reportQuestionSelectController = this;

    reportQuestionSelectController.$onInit = function () {
      reportQuestionSelectController.ngModelCtrl.$render = () => {
        const questions = angular.copy(
          reportQuestionSelectController.ngModelCtrl.$viewValue
        );
        const questionTemplateIds = _.map(questions, "questionTemplateId");

        getTemplates(questionTemplateIds).then((templates) => {
          reportQuestionSelectController.questions = _(questions)
            .orderBy("index")
            .map((question) => {
              const template = _.find(templates, {
                id: question.questionTemplateId
              });
              return {
                template,
                index: question.index
              };
            })
            .value();
        });
      };
    };

    function getTemplates(questionTemplateIds) {
      if (_.isEmpty(questionTemplateIds)) {
        return $q.resolve([]);
      }

      return QuestionTemplate.bulk({
        id: questionTemplateIds
      }).$promise;
    }

    reportQuestionSelectController.add = function () {
      $uibModal.open({
        size: "lg",
        templateUrl: "es6/report/template/report.question.select.modal.html",
        controllerAs: "modalController",
        controller: function (
          $uibModalInstance,
          descriptionFilter,
          EvaluationTemplate
        ) {
          const modalController = this;

          modalController.evaluationTypeId =
            reportQuestionSelectController.evaluationType;
          modalController.hideOpenQuestions =
            reportQuestionSelectController.hideOpenQuestions;
          setSelected();

          EvaluationTemplate.query().$promise.then((templates) => {
            modalController.templates = _(templates)
              .filter((template) => {
                return (
                  _.result(template.type, "id") ===
                  modalController.evaluationTypeId
                );
              })
              .map((template) => {
                return _.extend(template, {
                  label: descriptionFilter(template.titles)
                });
              })
              .orderBy(["name", "label", "id"])
              .value();
          });

          function setSelected() {
            modalController.selected = _.map(
              reportQuestionSelectController.questions,
              "template.id"
            );
          }

          modalController.close = function () {
            $uibModalInstance.dismiss();
          };

          modalController.add = function (templateId) {
            add(templateId);
            setSelected();
          };

          modalController.remove = function (templateId) {
            remove(templateId);
            setSelected();
          };
        }
      });
    };

    function add(questionTemplateId) {
      QuestionTemplate.get({ id: questionTemplateId }).$promise.then(
        (template) => {
          reportQuestionSelectController.questions.push({ template });
          onChange();
        }
      );
    }

    function remove(questionTemplateId) {
      reportQuestionSelectController.questions =
        reportQuestionSelectController.questions.filter(
          (question) => question.template.id !== questionTemplateId
        );
      onChange();
    }

    reportQuestionSelectController.onMove = function (index) {
      _.remove(
        reportQuestionSelectController.questions,
        reportQuestionSelectController.selected
      );
      reportQuestionSelectController.questions.splice(
        index,
        0,
        reportQuestionSelectController.selected
      );
      onChange();
    };

    reportQuestionSelectController.onDelete = function (index) {
      reportQuestionSelectController.questions.splice(index, 1);
      onChange();
    };

    function onChange() {
      updateIndex();

      const questions = _.map(
        reportQuestionSelectController.questions,
        (question) => {
          return {
            questionTemplateId: _.result(question, "template.id"),
            index: question.index
          };
        }
      );

      reportQuestionSelectController.ngModelCtrl.$setViewValue(questions);
    }

    function updateIndex() {
      let index = 0;
      _.forEach(reportQuestionSelectController.questions, (question) => {
        question.index = index += 1;
      });
    }
  }
});
