"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Relation
 * @description
 * # Relation
 * Relation resource of the diceApp
 */
angular.module("diceApp").factory("Relation", function (DiceResource) {
  return DiceResource("/relations");
});
