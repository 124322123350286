"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:readOnlyLabel
 * @description
 * The readOnlyLabel directive.
 */
angular.module("diceApp").component("readOnlyLabel", {
  templateUrl: "es6/base/forms/read.only.label.html",
  controllerAs: "readOnlyController"
});
