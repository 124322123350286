"use strict";

angular.module("diceApp").component("exportButton", {
  bindings: {
    getEvaluations: "&",
    isDisabled: "<",
    buttonType: "@"
  },
  templateUrl: "es6/export/export.button.html",
  controllerAs: "exportButtonController",
  controller: function (
    $uibModal,
    translateFilter,
    Export,
    systemPropertyStore
  ) {
    const exportButtonController = this;

    exportButtonController.$onInit = function () {
      if (exportButtonController.buttonType) {
        exportButtonController.buttonTypeClass =
          "btn-" + exportButtonController.buttonType;
      }

      Export.getStrategies().then(({ data: strategies }) => {
        exportButtonController.strategies = _(strategies)
          .sortBy((s) => s.name)
          .value();
      });
    };

    exportButtonController.export = function () {
      exportButtonController.getEvaluations().then((evaluations) => {
        const max = systemPropertyStore.findValue("exportMaxEvaluations");

        if (!max || evaluations.length <= parseInt(max)) {
          openExportModal(evaluations);
        } else {
          openLimitModal(max, evaluations.length);
        }
      });
    };

    function openExportModal(evaluations) {
      $uibModal.open({
        resolve: {
          strategies: function () {
            return buildStrategies(evaluations);
          }
        },
        controllerAs: "modalController",
        controller: function ($uibModalInstance, strategies) {
          this.size = _.size(evaluations);
          this.strategies = strategies;
          this.modal = $uibModalInstance;
        },
        template: `
          <export-modal
            size="modalController.size"
            strategies="modalController.strategies"
            modal="modalController.modal">
          </export-modal>
        `
      });
    }

    function openLimitModal(max, size) {
      $uibModal.open({
        controllerAs: "modalController",
        controller: function () {
          this.max = max;
          this.size = size;
        },
        templateUrl: "es6/export/export.limit.modal.html"
      });
    }

    function buildStrategies(evaluations) {
      return exportButtonController.strategies.map((strategy) => ({
        name: strategy.name,
        description: translateFilter(
          `ExportStrategy.${strategy.name}.Description`
        ),
        evaluations,
        usesReportTemplate: strategy.usesReportTemplate
      }));
    }
  }
});
