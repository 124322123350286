"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceNotificationBulkChange
 */
angular.module("diceApp").component("diceNotificationBulkChange", {
  bindings: {
    type: "<",
    evaluations: "<",
    onChange: "&",
    onCancel: "&"
  },
  templateUrl: "es6/evaluations/bulk/notification.bulk.change.html",
  controllerAs: "notificationBulkChangeController",
  controller: function (Evaluation, NotificationTemplate, $uibModal, message) {
    const notificationBulkChangeController = this;

    notificationBulkChangeController.$onInit = function () {
      loadData();
    };

    function loadData() {
      notificationBulkChangeController.loading = true;
      NotificationTemplate.getByEvaluationType(
        notificationBulkChangeController.type
      )
        .then((notificationTemplates) => {
          notificationBulkChangeController.notificationTemplates =
            notificationTemplates;
        })
        .finally(() => {
          notificationBulkChangeController.loading = false;
        });
    }

    notificationBulkChangeController.onTemplate = function () {
      const notificationTemplate =
        notificationBulkChangeController.selectedTemplate || {};
      notificationBulkChangeController.notification = {
        subjects: notificationTemplate.subjects,
        texts: notificationTemplate.bodies
      };
    };

    notificationBulkChangeController.submit = function () {
      send(notificationBulkChangeController.notification);
    };

    function send(notification) {
      openConfirm(notification, () => {
        notificationBulkChangeController.loading = true;
        Evaluation.bulkChangeNotification({
          evaluationIds: _.map(
            notificationBulkChangeController.evaluations,
            "id"
          ),
          templateId: _.get(
            notificationBulkChangeController.selectedTemplate,
            "id"
          ),
          notification
        })
          .$promise.then((result) => {
            message.addSuccess("Notification sent successfully");
            notificationBulkChangeController.onChange({ result });
          })
          .finally(() => {
            notificationBulkChangeController.loading = false;
          });
      });
    }

    function openConfirm(notification, callback) {
      $uibModal.open({
        template: `
            <notification-confirm-modal
              notification="modalController.notification"
              callback="modalController.callback"
              modal="modalController.instance">
            </notification-confirm-modal>
          `,
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.notification = notification;
          modalController.callback = callback;
          modalController.instance = $uibModalInstance;
        }
      });
    }
  }
});
