"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:FormStates
 * @description
 * # FormStates
 * Configures all states in relation to forms.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("element-list", {
    url: "/elements",
    data: {
      root: true
    },
    resolve: {
      elements: function (Element) {
        return Element.query().$promise;
      },
      tags: function (Tag) {
        return Tag.query().$promise;
      }
    },
    controllerAs: "elementListController",
    controller: function (elements, tags) {
      const elementListController = this;
      elementListController.elements = elements;
      elementListController.tags = tags;
    },
    template: `<dice-element-list
         elements="elementListController.elements"
         tags="elementListController.tags" instance="">
       </dice-element-list>`
  });
});
