"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:toggleInput
 * @description
 * The toggleInput directive.
 */
angular.module("diceApp").component("toggleInput", {
  templateUrl: "es6/base/forms/inputs/toggle.input.html",
  bindings: {
    id: "@?",
    name: "@?",
    ngDisabled: "<?",
    classes: "@?"
  },
  require: {
    ngModel: "ngModel"
  },
  controllerAs: "toggleInputController",
  controller: function ($scope) {
    const toggleInputController = this;
    if (angular.isUndefined(toggleInputController.ngDisabled)) {
      toggleInputController.ngDisabled = false;
    }

    toggleInputController.$onInit = function () {
      $scope.$watch("toggleInputController.ngModel.$modelValue", function () {
        toggleInputController.value = toggleInputController.ngModel.$modelValue;
      });
    };

    toggleInputController.toggle = function () {
      if (toggleInputController.ngDisabled !== true) {
        toggleInputController.value = toggleInputController.value !== true;
        onChange();
      }
    };

    function onChange() {
      toggleInputController.ngModel.$setViewValue(toggleInputController.value);
    }
  }
});
