"use strict";

angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("export-list", {
    url: "/exports",
    data: {
      root: true
    },
    controllerAs: "exportStateController",
    template: "<export-list></export-list>"
  });
});
