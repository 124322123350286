"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceGenerateReportButton
 */
angular.module("diceApp").component("diceGenerateReportButton", {
  bindings: {
    getEvaluations: "&",
    btnStyle: "@?"
  },
  templateUrl: "es6/report/generate.report.button.html",
  controllerAs: "generateReportController",
  controller: function ($uibModal) {
    const generateReportController = this;

    generateReportController.report = function () {
      generateReportController.getEvaluations().then(function (evaluations) {
        $uibModal.open({
          controllerAs: "modalCtrl",
          controller: function ($uibModalInstance) {
            const modalCtrl = this;
            modalCtrl.modal = $uibModalInstance;
            modalCtrl.evaluations = evaluations;
          },
          template: `
              <generate-report-modal
                evaluations="modalCtrl.evaluations"
                modal="modalCtrl.modal">
              </generate-report-modal>'
            `
        });
      });
    };
  }
});
