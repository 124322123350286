"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:diceEvaluationAdmin
 * @description
 * diceEvaluationAdmin Provides admin functions on an evaluation
 */
angular.module("diceApp").component("diceEvaluationAdmin", {
  bindings: {
    evaluation: "<",
    operations: "<"
  },
  templateUrl: "es6/evaluations/evaluation.admin.html",
  controllerAs: "evaluationAdminController",
  controller: function (Evaluation, authentication, message, $state) {
    const evaluationAdminController = this;

    evaluationAdminController.$onInit = function () {
      evaluationAdminController.isAdmin =
        authentication.hasRole("SYSTEM_ADMIN");
    };

    evaluationAdminController.remove = function () {
      Evaluation.remove(evaluationAdminController.evaluation.id).$promise.then(
        () => {
          $state.go("evaluation-list");
          message.addSuccessLabel("Message.Delete.Success");
        }
      );
    };
  }
});
