"use strict";

angular.module("diceApp").component("diceForgotPassword", {
  templateUrl: "es6/security/forgot-password/forgot-password.component.html",
  controllerAs: "forgotPasswordController",
  controller: function (forgotPasswordDatasource) {
    const forgotPasswordController = this;

    forgotPasswordController.$onInit = function () {
      forgotPasswordController.finished = false;
    };

    forgotPasswordController.submit = function () {
      forgotPasswordDatasource
        .forgotPassword(forgotPasswordController.username)
        .then(() => {
          forgotPasswordController.finished = true;
        });
    };
  }
});
