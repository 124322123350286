"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:statusTypeSequenceModal
 * @description Shows a modal to manage the order of status types.
 */
angular.module("diceApp").component("statusTypeSequenceModal", {
  bindings: {
    statusTypes: "<",
    evaluationType: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/status/type/status.type.sequence.modal.html",
  controllerAs: "statusTypeSequenceOrderController",
  controller: function (StatusType, $scope) {
    const statusTypeSequenceOrderController = this;

    statusTypeSequenceOrderController.$onInit = function () {
      statusTypeSequenceOrderController.currentEvaluationType = _.result(
        statusTypeSequenceOrderController.evaluationType,
        "id"
      );
    };

    statusTypeSequenceOrderController.onMoved = function (items, index) {
      items.splice(index, 1);
    };

    statusTypeSequenceOrderController.close = function () {
      statusTypeSequenceOrderController.modal.close();
    };

    statusTypeSequenceOrderController.save = function () {
      let statusTypeSequences = [];
      _(statusTypeSequenceOrderController.filteredStatusTypes).forEach(
        function (statusType, index) {
          statusTypeSequences.push({
            id: statusType.id,
            sequence: index
          });
        }
      );

      StatusType.storeOrderSequence(statusTypeSequences).$promise.then(
        (result) => {
          statusTypeSequenceOrderController.onSave(result);
          statusTypeSequenceOrderController.close();
        }
      );
    };

    $scope.$watch(
      "statusTypeSequenceOrderController.currentEvaluationType",
      () => {
        statusTypeSequenceOrderController.filteredStatusTypes = _(
          statusTypeSequenceOrderController.statusTypes
        )
          .filter({
            evaluationType:
              statusTypeSequenceOrderController.currentEvaluationType
          })
          .orderBy("sequence")
          .value();
      }
    );
  }
});
