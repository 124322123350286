"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationLogs
 * @description
 * evaluationLogs Shows the evaluation transition/notification logs.
 */
angular.module("diceApp").component("evaluationLogs", {
  bindings: {
    evaluation: "<"
  },
  templateUrl: "es6/evaluations/evaluation.logs.html",
  controllerAs: "evaluationLogsController",
  controller: function (
    Evaluation,
    EvaluationWorkflow,
    ScheduleWorkflow,
    $uibModal
  ) {
    const evaluationLogsController = this;
    evaluationLogsController.Math = Math;

    evaluationLogsController.statuses = {
      PLANNED: { icon: "fa fa-hourglass-start" },
      SKIPPED: { icon: "fa fa-hand-paper-o", text: "text-muted" },
      SUCCESS: { icon: "fa fa-check", text: "text-success" },
      FAILED: { icon: "fa fa-exclamation-triangle", text: "text-danger" }
    };

    evaluationLogsController.$onInit = function () {
      ScheduleWorkflow.next({
        evaluationId: evaluationLogsController.evaluation.id
      }).$promise.then((next) => {
        evaluationLogsController.next = next;
      });

      evaluationLogsController.search();
    };

    evaluationLogsController.search = function () {
      evaluationLogsController.loading = true;
      Evaluation.logs({
        evaluationId: evaluationLogsController.evaluation.id
      })
        .$promise.then((logs) => {
          evaluationLogsController.logs = logs;
        })
        .finally(() => {
          evaluationLogsController.loading = false;
        });
    };

    evaluationLogsController.open = function (workflow) {
      if (angular.isDefined(workflow) && workflow.type === "NOTIFICATION") {
        open(workflow);
      }
    };

    function open(workflow) {
      $uibModal.open({
        resolve: {
          entity: function () {
            return EvaluationWorkflow.get({
              evaluationId: evaluationLogsController.evaluation.id,
              workflowId: workflow.id
            }).$promise;
          }
        },
        controllerAs: "workflowModalController",
        controller: function ($uibModalInstance, entity) {
          const workflowModalController = this;
          workflowModalController.entity = entity;
          workflowModalController.workflow = angular.copy(workflow);
          workflowModalController.evaluation = angular.copy(
            evaluationLogsController.evaluation
          );

          workflowModalController.onChange = function () {
            workflowModalController.close();
            evaluationLogsController.search();
          };

          workflowModalController.close = function () {
            $uibModalInstance.dismiss();
          };
        },
        template: `
            <evaluation-workflow-edit-modal
              entity="workflowModalController.entity"
              evaluation="workflowModalController.evaluation"
              workflow="workflowModalController.workflow"
              on-change="workflowModalController.onChange()"
              on-close="workflowModalController.close()">
            </evaluation-workflow-edit-modal>
          `
      });
    }
  }
});
