"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:QuestionTemplateStates
 * @description
 * # QuestionTemplateStates
 * Configures all states in relation to question templates.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider
    .state("question-template-list", {
      url: "/question-templates",
      data: {
        root: true
      },
      template: "<dice-question-template-list></dice-question-template-list>"
    })
    .state("question-template", {
      url: "/question-templates/{id:[0-9]{1,8}}",
      resolve: {
        question: function ($stateParams, QuestionTemplate) {
          return QuestionTemplate.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "questionTemplateListController",
      controller: function (question) {
        const questionTemplateListController = this;
        questionTemplateListController.question = question;
      },
      template:
        '<dice-question-template question="questionTemplateListController.question"></dice-question-template>'
    })
    .state("question-template-copy", {
      url: "/question-template/{id:[0-9]{1,8}}/copy",
      resolve: {
        question: function ($stateParams, QuestionTemplate) {
          return QuestionTemplate.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "questionTemplateCopyController",
      controller: function ($filter, question) {
        const questionTemplateCopyController = this;
        const clone = angular.copy(question);
        clone.name = $filter("translate")("Generic.CopyOf") + " " + clone.name;
        clone.labels = _.map(clone.labels, (label) => {
          delete label.id;
          label.value =
            $filter("translate")("Generic.CopyOf") + " " + label.value;
          return label;
        });
        clone.options.forEach((option) => {
          delete option.id;
        });
        clone.tooltips.forEach((tooltip) => {
          delete tooltip.id;
        });
        questionTemplateCopyController.question = _.omit(clone, [
          "id",
          "questionNumber"
        ]);
      },
      template:
        '<dice-question-template question="questionTemplateCopyController.question"></dice-question-template>'
    })
    .state("question-template-create", {
      url: "/question-template/create",
      controllerAs: "questionTemplateCreateController",
      controller: function () {
        const questionTemplateCreateController = this;
        questionTemplateCreateController.question = {
          options: []
        };
      },
      template:
        '<dice-question-template question="questionTemplateCreateController.question"></dice-question-template>'
    });
});
