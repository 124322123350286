"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:StructureStates
 * @description
 * # StructureStates
 * Configures all states in relation to structures.
 */
angular.module("diceApp").config(function ($stateProvider) {
  // Persons
  $stateProvider.state("person-list", {
    url: "/persons?page&size&sort&text&active&employee&student&roleId",
    data: {
      root: true
    },
    params: {
      page: {
        value: "",
        dynamic: true
      },
      size: {
        value: "",
        dynamic: true
      },
      sort: {
        value: "externalId",
        dynamic: true
      },
      text: {
        value: "",
        dynamic: true
      },
      active: {
        value: "",
        dynamic: true
      },
      employee: {
        value: "",
        dynamic: true
      },
      student: {
        value: "",
        dynamic: true
      }
    },
    controllerAs: "personStateController",
    controller: function ($stateParams) {
      this.parameters = $stateParams;
    },
    template:
      '<dice-person-list parameters="personStateController.parameters"></dice-person-list>'
  });

  $stateProvider.state("substitute-list", {
    url: "/substitutes/:personId",
    controllerAs: "personStateController",
    resolve: {
      person: function ($stateParams, Person) {
        return Person.get({ id: $stateParams.personId }).$promise;
      }
    },
    controller: function (person) {
      const personStateController = this;
      personStateController.person = person;
    },
    template:
      '<substitute-list person="personStateController.person"></substitute-list>'
  });

  // Periods
  $stateProvider.state("period-list", {
    url: "/periods",
    data: {
      root: true
    },
    resolve: {
      periods: function (Period) {
        return Period.query().$promise;
      }
    },
    controllerAs: "periodListController",
    controller: function (periods) {
      const periodListController = this;
      periodListController.periods = periods;
    },
    template:
      '<dice-period-list periods="periodListController.periods"></dice-period-list>'
  });

  $stateProvider.state("period-progress", {
    url: "/period-progress",
    data: {
      root: true
    },
    template: "<dice-period-progress></dice-period-progress>"
  });

  $stateProvider.state("holiday-list", {
    url: "/holidays",
    data: {
      root: true
    },
    template: "<holiday-list></holiday-list>"
  });
});
