"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:EvaluationWidget
 * @description
 * # EvaluationWidget
 * EvaluationWidget resource of the diceApp
 */
angular.module("diceApp").factory("EvaluationWidget", function (DiceResource) {
  return DiceResource("/evaluation-widgets");
});
