"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:NotificationTemplateStates
 * @description
 * # NotificationTemplateStates
 * Configures all states for notification templates.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("notification-template-list", {
    url: "/notification-templates",
    data: {
      root: true
    },
    template:
      "<dice-notification-template-list></dice-notification-template-list>"
  });
});
