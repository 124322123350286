"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceSynchronize
 * @description
 * # diceSynchronize
 * Component of the diceApp
 */
angular.module("diceApp").component("diceSynchronize", {
  bindings: {
    periods: "<"
  },
  templateUrl: "es6/admin/synchronize/synchronize.html",
  controllerAs: "listController",
  controller: function (Synchronize, Period, $q) {
    const listController = this;

    listController.$onInit = function () {
      listController.loading = true;
      $q.all([Synchronize.get().$promise, Period.query().$promise])
        .then(([properties, periods]) => {
          listController.enabled = properties.components === true;
          listController.periods = _(periods)
            .sortBy("startDate")
            .reverse()
            .value();
        })
        .finally(() => {
          listController.loading = false;
        });
    };

    listController.syncAll = function (periodId) {
      listController.syncing = true;
      Synchronize.components(
        { periodId },
        {
          limit: listController.limit
        }
      ).$promise.finally(() => {
        listController.syncing = false;
      });
    };
  }
});
