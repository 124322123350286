"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:diceBenchmarkLabel
 * @description
 * diceBenchmarkLabel Shows the benchmark.
 */
angular.module("diceApp").component("diceBenchmarkLabel", {
  bindings: {
    question: "<",
    benchmark: "<",
    tooltip: "@?"
  },
  templateUrl: "es6/report/benchmark/benchmark.label.html",
  controllerAs: "benchmarkLabelController",
  controller: function () {
    const benchmarkLabelController = this;
    benchmarkLabelController.$onChanges = function () {
      const average = _.get(
        benchmarkLabelController.question,
        "averageValue",
        0
      );
      const benchmark = _.get(benchmarkLabelController.benchmark, "average", 0);
      benchmarkLabelController.diff = _.round(average - benchmark, 2).toFixed(
        2
      );
      benchmarkLabelController.positive = benchmarkLabelController.diff >= 0;
    };
  }
});
