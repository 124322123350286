"use strict";

import contentCopy from "../../../images/ic_content_copy_black_24dp_2x.png";

/**
 * @ngdoc function
 * @name diceApp.controller:BlockTemplateController
 * @description
 * # BlockTemplateController
 * Controller of the diceApp
 */
angular.module("diceApp").component("diceBlockTemplate", {
  bindings: {
    block: "<"
  },
  templateUrl: "es6/templates/block/block.template.html",
  controllerAs: "blockTemplateController",
  controller: function (
    BlockTemplate,
    QuestionTemplate,
    EvaluationTemplate,
    EvaluationType,
    language,
    message,
    $state
  ) {
    const blockTemplateController = this;

    blockTemplateController.$onInit = function () {
      blockTemplateController.order = false;
      blockTemplateController.showAllBlocks = angular.isUndefined(
        blockTemplateController.block.evaluationType
      );

      getTemplates();

      if (angular.isDefined(blockTemplateController.block.evaluationType)) {
        EvaluationType.get({
          id: blockTemplateController.block.evaluationType
        }).$promise.then((evaluationType) => {
          blockTemplateController.evaluationType = evaluationType;
        });
      }

      if (angular.isUndefined(blockTemplateController.block.filters)) {
        blockTemplateController.block.filters = [];
      }

      QuestionTemplate.query().$promise.then((questions) => {
        blockTemplateController.questions = questions;
        loadData();
      });
    };

    function getTemplates() {
      EvaluationTemplate.search({
        blockTemplate: blockTemplateController.block.id,
        language: language.get(),
        page: 0,
        size: 25,
        sort: "name,ASC"
      }).$promise.then((evaluations) => {
        blockTemplateController.evaluations = evaluations;
      });
    }

    blockTemplateController.onShowAll = function () {
      loadData();
    };

    blockTemplateController.remove = function () {
      BlockTemplate.remove(blockTemplateController.block.id).$promise.then(
        () => {
          message.addSuccessLabel("Message.Delete.Success");
          $state.go("block-template-list");
        }
      );
    };

    blockTemplateController.save = function (block, form) {
      blockTemplateController.prepare(block);

      var body = angular.copy(block);
      body.questions = _(block.questions)
        .map((question, index) => {
          const result = _.pick(question, ["questionTemplateId", "required"]);
          result.sequence = index + 1;
          return result;
        })
        .value();

      return BlockTemplate.store(body).$promise.then(function (result) {
        form.$setPristine();
        if (angular.isUndefined(blockTemplateController.block.id)) {
          $state.go("block-template", { id: result.id });
        }
        message.addSuccessLabel("Message.Save.Success");
      });
    };

    blockTemplateController.prepare = function (block) {
      const preview = angular.copy(block);
      prepareQuestions(preview.questions);
      return preview;
    };

    function prepareQuestions(questions) {
      if (questions !== null) {
        _.each(questions, (question) => {
          question.sequence = questions.indexOf(question);
        });
      }
    }

    function loadData() {
      blockTemplateController.block.questions = _(
        blockTemplateController.block.questions
      )
        .map((question) => {
          question.questionTemplateId = question.question.id;
          return question;
        })
        .orderBy("sequence")
        .value();

      blockTemplateController.filteredQuestions = _(
        blockTemplateController.questions
      )
        .filter((question) => {
          return (
            blockTemplateController.showAllBlocks ||
            blockTemplateController.block.evaluationType ===
              question.evaluationType
          );
        })
        .filter((question) => {
          return !blockTemplateController.block.questions.some((chosen) => {
            return question.id === chosen.questionTemplateId;
          });
        })
        .map((question) => {
          return {
            question: question,
            questionTemplateId: question.id
          };
        })
        .value();
    }

    // --- Drag and drop methods start here --- //

    /**
     * dnd-dragging determines what data gets serialized and send to the receiver
     * of the drop. While we usually just send a single object, we send the array
     * of all selected items here.
     */
    blockTemplateController.onSelect = function (list, item) {
      _.forEach(list, (it) => {
        it.selected = false;
      });

      item.selected = true;
    };

    /**
     * We set the list into dragging state, meaning the items that are being
     * dragged are hidden. We also use the HTML5 API directly to set a custom
     * image, since otherwise only the one item that the user actually dragged
     * would be shown as drag image.
     */
    blockTemplateController.onDragstart = function (list, event) {
      list.dragging = true;
      if (event.dataTransfer.setDragImage) {
        var img = new Image();
        img.src = contentCopy;
        event.dataTransfer.setDragImage(img, 0, 0);
      }
    };

    blockTemplateController.onMoved = function (items, question, index) {
      question.selected = false;
      items.splice(index, 1);
      resequence();
      loadData();
    };

    function resequence() {
      _.forEach(blockTemplateController.block.questions, (question, index) => {
        question.sequence = index;
      });
    }
  }
});
