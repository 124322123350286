"use strict";

/**
 * @ngdoc factory
 * @name diceApp.factory:FeedbackModal
 * @description
 * Opens a modal to create/edit feedback.
 */
angular
  .module("diceApp")
  .factory("FeedbackModal", function (Feedback, $uibModal, message, $filter) {
    function open({ feedback, evaluationId, participantId, onChange }) {
      $uibModal.open({
        templateUrl: "es6/report/feedback.modal.html",
        controllerAs: "feedbackModalController",
        controller: function ($uibModalInstance) {
          const feedbackModalController = this;
          feedbackModalController.feedback = angular.copy(feedback);

          feedbackModalController.close = function () {
            $uibModalInstance.close();
          };

          feedbackModalController.stripFormat = function ($html) {
            return $filter("htmlToPlaintext")($html);
          };

          feedbackModalController.save = function () {
            let entity = { type: "evaluation", id: evaluationId };
            if (angular.isDefined(participantId)) {
              entity = { type: "participant", id: participantId };
            }

            const body = {
              message: feedbackModalController.feedback.message,
              entity
            };

            Feedback.saveOrUpdate(
              {
                evaluationId,
                role: _.get(feedbackModalController.feedback, "role.id")
              },
              body
            ).$promise.then(() => {
              feedbackModalController.close();
              message.addSuccessLabel("Message.Save.Success");
              onChange();
            });
          };
        }
      });
    }

    return { open };
  });
