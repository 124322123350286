"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:modalTitle
 * @description
 * The modalTitle component. To determine if the create or edit title needs to be displayed.
 */
angular.module("diceApp").component("modalTitle", {
  bindings: {
    createTitle: "@",
    editTitle: "@",
    model: "<"
  },
  templateUrl: "es6/base/forms/modal.title.html",
  controllerAs: "modalTitleController"
});
