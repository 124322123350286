"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceOptionComponent
 * @description Manages an answer option component.
 */
angular.module("diceApp").component("diceAnswerOptions", {
  bindings: {
    options: "<",
    onChange: "&",
    advanced: "<"
  },
  templateUrl: "es6/templates/option/answer.options.html",
  controllerAs: "optionController",
  controller: function (language, OptionTemplate, $element) {
    const optionController = this;
    let counter = 0;

    optionController.$onInit = function () {
      optionController.language = language;
      optionController.optionTemplates = OptionTemplate.query();
      optionController.popover = [];

      loadData();
    };

    function loadData() {
      optionController.grouped = OptionTemplate.mapToOptions({
        options: optionController.options
      });

      _.each(optionController.language.supported, function (supported) {
        if (!optionController.grouped[supported]) {
          optionController.grouped[supported] = [];
          optionController.popover[supported] = { isOpen: false };
        }
      });
    }

    optionController.importTemplate = function (language_) {
      if (angular.isDefined(optionController.importOptionTemplate)) {
        optionController.options =
          optionController.importOptionTemplate.options;
        optionController.popover[language_].isOpen = false;

        loadData();
        onChange();
      }
    };

    optionController.isNumeric = function (option) {
      return /^(\d+)$/.test(option.value || "");
    };

    optionController.onValue = function (option) {
      option.calculate = optionController.isNumeric(option);
      onChange();
    };

    optionController.copyAnswerOptions = function (language_) {
      const values = _.filter(optionController.options, {
        language: language_
      });
      resequence(values);

      const supportedLanguages = optionController.language.supported;
      optionController.options = _(supportedLanguages)
        .map((supported) => {
          return _(values)
            .map((value) => {
              return {
                counter: (counter += 1),
                language: supported,
                value: value.value,
                label: value.label,
                calculate: value.calculate,
                externalId: value.externalId,
                sequence: value.sequence
              };
            })
            .sortBy("sequence")
            .value();
        })
        .flatten()
        .value();

      loadData();
      onChange();
    };

    optionController.addAnswerOption = function (language_) {
      optionController.options.push({
        counter: (counter += 1),
        language: language_,
        calculate: false,
        sequence: optionController.grouped[language_].length
      });

      loadData();
      onChange();
    };

    optionController.movedAnswerOption = function (index, language_) {
      optionController.grouped[language_].splice(index, 1);
      optionController.options = _.flatMap(optionController.grouped);

      resequence(optionController.grouped[language_]);
      loadData();
      onChange();
    };

    optionController.removeAnswerOption = function (index, language_) {
      const option = optionController.grouped[language_][index];
      _.remove(optionController.options, option);

      loadData();
      onChange();
    };

    optionController.setMode = function (event, mode) {
      event.stopPropagation();
      let elm = $element.find(event.currentTarget).closest("li");

      if (mode === "drag") {
        elm.attr("draggable", true);
      } else {
        elm.removeAttr("draggable");
      }
    };

    function resequence(draggableAnswers) {
      _.forEach(draggableAnswers, (answer, index) => {
        answer.sequence = index;
      });
    }

    function onChange() {
      optionController.onChange({
        options: optionController.options
      });
    }
  }
});
