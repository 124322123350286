"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:diceReport
 * @description Shows the dynamic and interactive evaluation report
 */
angular.module("diceApp").component("diceReport", {
  bindings: {
    report: "<",
    operations: "<?",
    filters: "<?",
    questions: "<?",
    onRefresh: "&",
    exportable: "@?"
  },
  templateUrl: "es6/report/report.html",
  controllerAs: "reportController",
  controller: function (
    Export,
    language,
    $document,
    $scope,
    $window,
    ReportExcelExporter,
    ScrollService,
    SectionService,
    visualizationFilter
  ) {
    const reportController = this;
    const VISUALIZATIONS_WITH_TABLE_HEADER = ["GRAPH", "HISTOGRAM", "TABLE"];

    reportController.$onInit = function () {
      reportController.feedbackEditable =
        _.get(reportController.report, "template.feedbackEditable") === true &&
        _.get(reportController.operations, "FEEDBACK") === true;
      reportController.questions = reportController.questions || [];

      if (angular.isDefined(reportController.report)) {
        prepare(reportController.report);

        const template = reportController.report.template || {};
        reportController.indexPerBlock =
          template.showBlocks && template.indexPerBlock;
      }
    };

    function prepare(report) {
      _.forEach(report.reportBlocks, (block) => {
        _.forEach(block.questionReports, (question) => {
          _.forEach(question.options, (option) => {
            option.label = option.label || option.value;
          });
        });
      });
    }

    reportController.focus = function (subReport) {
      const getEvaluationId = (report) => report.evaluation.id;

      const focused = getEvaluationId(subReport);
      const remainder = _(reportController.report.subReports)
        .map(getEvaluationId)
        .value();
      const sorted = _.concat([focused], _.without(remainder, focused));

      reportController.onRefresh({
        params: { evaluations: sorted }
      });
    };

    reportController.toggleTag = function (tag, tagGroup) {
      if (angular.isDefined(reportController.filters)) {
        if (tagGroup.active === true) {
          removeFilter("tags", tag.value);
        } else {
          updateFilter("tags", tag.value, tagGroup.name);
        }
        tag.active = tag.active !== true;
        reportController.onRefresh();
      }
    };

    reportController.toggleAnswer = function (option, question) {
      if (angular.isDefined(reportController.filters)) {
        const templateId = _.get(question, "questionTemplateId");
        if (option.active === true) {
          removeFilter("answers", templateId);
        } else {
          updateFilter("answers", templateId, option.value);
        }
        option.active = option.active !== true;
        reportController.onRefresh();
      }
    };

    function updateFilter(type, name, value) {
      let container = getFilter(type);
      container[name] = value;
    }

    function removeFilter(type, name) {
      let container = getFilter(type);
      container[name] = undefined;
    }

    function getFilter(type) {
      let container = reportController.filters[type] || {};
      reportController.filters[type] = container;
      return container;
    }

    reportController.addQuestion = function (question) {
      reportController.questions.push(_.toString(question.questionId));
      reportController.onRefresh();
    };

    reportController.removeQuestion = function (question) {
      _.remove(
        reportController.questions,
        (id) => id === _.toString(question.questionId)
      );
      if (_.some(question.options, { active: true })) {
        const templateId = _.get(question, "questionTemplateId");
        removeFilter("answers", templateId);
      }
      reportController.onRefresh();
    };

    reportController.isVisible = (question) => {
      return _.includes(
        reportController.questions,
        _.toString(question.questionId)
      );
    };

    reportController.$onChanges = function () {
      reportController.cardsView = _.get(
        reportController.report,
        "template.cardsView",
        false
      );
      reportController.navSidebarOpen = reportController.cardsView;

      if (reportController.report) {
        const available = _.get(reportController, "report.available");
        reportController.report.reportBlocks = _.sortBy(
          reportController.report.reportBlocks,
          "sequence"
        );

        setMemberIndices(reportController.report.reportBlocks);
        loadSummary(reportController.report);

        if (available === true) {
          reportController.groupedBlocks = groupBlocks();
          SectionService.getSections().then((sections) => {
            reportController.sections = sections;
            const sectionIds = _.map(reportController.sections, "sectionId");
            ScrollService.setupObserver(sectionIds);
          });
        }
      }
    };

    function groupBlocks() {
      const closedBlocks = visualizationFilter(
        reportController.report.reportBlocks,
        ["TABLE", "GRAPH", "HISTOGRAM", "TEXT"]
      );
      const openBlocks = visualizationFilter(
        reportController.report.reportBlocks,
        ["BULLET"]
      );
      const groupedBlocks = [];

      const inOrder = _.get(reportController, "report.template.inOrder", false);
      if (inOrder) {
        const blocks = _.sortBy(_.concat(openBlocks, closedBlocks), "sequence");
        _.transform(
          blocks,
          (result, block) => {
            const current = _.last(result);
            if (
              !current ||
              (_.includes(openBlocks, block) && !current.open) ||
              (_.includes(closedBlocks, block) && current.open)
            ) {
              result.push({
                open: _.includes(openBlocks, block),
                blocks: [block]
              });
            } else {
              current.blocks.push(block);
            }
          },
          groupedBlocks
        );
      } else {
        // Display open questions at the bottom of the report
        groupedBlocks.push({
          open: false,
          blocks: closedBlocks
        });
        groupedBlocks.push({
          open: true,
          blocks: openBlocks
        });
      }

      _.each(groupedBlocks, (group) => {
        _.each(group.blocks, (block, blockIndex) => {
          block.htmlId = SectionService.getUniqueValidHtmlId(
            block,
            "blockLabel"
          );

          _.each(block.questionReports, (question, indexInBlock) => {
            question.indexInBlock = indexInBlock;
            const previousQuestion = getPreviousQuestion(
              group.blocks,
              block,
              blockIndex,
              indexInBlock
            );

            question.includeTableHeader = shouldIncludeTableHeader(
              indexInBlock,
              question,
              previousQuestion
            );
          });

          block.questionReports = _.sortBy(block.questionReports, "sequence");
        });
      });

      return groupedBlocks;
    }

    function getPreviousQuestion(blocks, block, blockIndex, questionIndex) {
      if (questionIndex > 0) {
        return block.questionReports[questionIndex - 1];
      } else if (blockIndex > 0) {
        const previousBlock = blocks[blockIndex - 1];
        return _.last(previousBlock.questionReports);
      }
      return undefined;
    }

    function shouldIncludeTableHeader(
      indexInBlock,
      question,
      previousQuestion
    ) {
      if (
        !_.includes(VISUALIZATIONS_WITH_TABLE_HEADER, question.visualization)
      ) {
        return false;
      }

      if (reportController.cardsView && indexInBlock === 0) {
        return true;
      }

      return !isEqualQuestion(previousQuestion, question);
    }

    function isEqualQuestion(left, right) {
      if (angular.isUndefined(left) || angular.isUndefined(right)) {
        return false;
      }

      return _.isEqual(left.visualization, right.visualization);
    }

    reportController.exportAsPdf = function () {
      Export.generatePdf($window.location.href, language.get());
    };

    reportController.exportAsExcel = function () {
      if (_.isEmpty(reportController.report.subReports)) {
        const sheet = ReportExcelExporter.buildReport({
          report: reportController.report
        });

        sheet.download(
          "report-" + reportController.report.evaluation.code + ".xlsx"
        );
      } else {
        const sheet = ReportExcelExporter.buildComposed({
          reports: [reportController.report]
        });

        sheet.download("composed.xlsx");
      }
    };

    function loadSummary(report) {
      reportController.summary = {};
      if (angular.isDefined(report.template)) {
        _.each(report.template.attributes, function (attribute) {
          reportController.summary[attribute.type] = attribute;
        });
      }
    }

    function setMemberIndices(blocks) {
      let membersPerBlock = _(blocks)
        .filter((b) => angular.isDefined(b.member))
        .groupBy("blockLabel")
        .value();

      _.each(membersPerBlock, (memberBlocks) => {
        _.each(memberBlocks, (block, index) => {
          block.member.index = index + 1;
        });
      });
    }

    reportController.scrollTo = function (id) {
      const element = $document[0].querySelector(`[data-section-id=${id}]`);
      if (angular.isDefined(element)) {
        element.scrollIntoView(true);
      }
    };

    reportController.toggleNavSidebar = function () {
      reportController.navSidebarOpen = !reportController.navSidebarOpen;
    };

    $scope.$on("$destroy", function () {
      ScrollService.disconnectObserver();
    });
  }
});
