"use strict";

// The htmls are not automatically detected by angularjs-template-loader
// and are therefore manually required.
require("./preview.evaluation.modal.html");

/**
 * @ngdoc directive
 * @name diceApp.directive:TemplatePreviewButton
 * @description Opens a template preview modal.
 */
angular.module("diceApp").component("templatePreviewButton", {
  templateUrl: "es6/preview/modal/template.preview.button.html",
  bindings: {
    type: "@", // question, evaluation or block
    template: "<",
    callback: "<?",
    btnSize: "@?",
    btnType: "@?",
    btnContext: "@?"
  },
  controllerAs: "buttonController",
  controller: function ($uibModal, $q) {
    const buttonController = this;

    /**
     * Opens the modal with our preview.
     */
    buttonController.open = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      return loadData().then(open);
    };

    function open(template) {
      return $uibModal.open({
        templateUrl: "es6/preview/modal/preview.evaluation.modal.html",
        controllerAs: "previewController",
        controller: function ($uibModalInstance) {
          const previewController = this;
          previewController.evaluation = buildEvaluation(template);

          previewController.confirm = function () {
            $uibModalInstance.close();
          };

          previewController.close = function () {
            $uibModalInstance.dismiss("closed");
          };

          previewController.refresh = function () {
            loadData().then((newTemplate) => {
              previewController.close();
              open(newTemplate);
            });
          };
        },
        size: getSize(template)
      });
    }

    function buildEvaluation(template) {
      switch (buttonController.type) {
        case "question":
          return buildQuestion(template);
        case "block":
          return buildBlock(template);
        default:
          return template;
      }
    }

    function buildBlock(template) {
      const block = angular.copy(template);
      delete block.filters;

      return {
        blocks: [block]
      };
    }

    function buildQuestion(template) {
      const question = angular.copy(template);
      delete question.filters;

      return {
        blocks: [
          {
            questions: [question]
          }
        ]
      };
    }

    function getSize(template) {
      let size = "lg";

      const sidebarNavigation = _.get(
        template,
        "type.sidebarNavigation",
        false
      );
      if (sidebarNavigation) {
        size = "xl";
      }

      return size;
    }

    function loadData() {
      if (angular.isDefined(buttonController.callback)) {
        const result = buttonController.callback(buttonController.template);
        if (result.then) {
          return result;
        } else if (result.$promise) {
          return result.$promise;
        } else {
          return $q.resolve(result);
        }
      } else {
        return $q.resolve(buttonController.template);
      }
    }
  }
});
