"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:NotificationStates
 * @description
 * # NotificationStates
 * Configures all states in relation to notifications.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("workflow-list", {
    url: "/workflows",
    data: {
      root: true
    },
    template: "<workflow-list></workflow-list>"
  });

  $stateProvider.state("schedule-workflow-list", {
    url: "/schedule-workflows",
    data: {
      root: true
    },
    template: "<schedule-workflow-list></schedule-workflow-list>"
  });
});
