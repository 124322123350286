"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:FixToTop Component
 * @description
 * Fixes a given element to the top of the page.
 */
angular.module("diceApp").directive("emptyTable", function () {
  return {
    restrict: "AE",
    transclude: true,
    scope: {
      icon: "@?",
      label: "@?"
    },
    template:
      '<div class="empty-table-container"><i class="fa fa-{{icon ? icon : \'files-o\'}}" aria-hidden="true"></i> <span ng-transclude></span> <h6>{{label ? (label | translate) : (\'Generic.TryDifferentQuery\' | translate)}}</h6></div>'
  };
});
