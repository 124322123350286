"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:QuestionTemplate
 * @description
 * # QuestionTemplate
 * QuestionTemplate resource of the diceApp
 */
angular.module("diceApp").factory("QuestionTemplate", function (DiceResource) {
  return DiceResource("/question-templates", {
    search: {
      url: "/question-templates/search",
      method: "GET",
      isArray: false
    },
    bulk: {
      url: "/question-templates",
      method: "GET",
      isArray: true
    },
    tags: {
      url: "/question-templates/tags",
      method: "GET",
      isArray: true
    }
  });
});
