"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceComposedReport
 */
angular.module("diceApp").component("diceComposedReport", {
  bindings: {
    evaluations: "<",
    type: "<?",
    template: "<?",
    layout: "<?",
    filters: "<",
    questions: "<"
  },
  templateUrl: "es6/report/report.composed.html",
  controllerAs: "composedReportController",
  controller: function ($q, $state, Report, language) {
    const composedReportController = this;

    const CONSECUTIVE = "CONSECUTIVE";
    const CHUNK_SIZE = 5;

    composedReportController.$onInit = function () {
      composedReportController.exportable = true;
      composedReportController.loadReport();
    };

    composedReportController.loadReport = function (params) {
      composedReportController.loading = true;
      _.extend(composedReportController, params);

      let reports = [];

      var chunks;
      if (isBatchable()) {
        chunks = _.chunk(composedReportController.evaluations, CHUNK_SIZE);
      } else {
        chunks = [composedReportController.evaluations];
      }

      composedReportController.progress = {
        total: chunks.length,
        current: 0
      };

      addReports(chunks, reports).finally(() => {
        composedReportController.reports = reports;
        composedReportController.loading = false;
      });

      const newParams = angular.copy($state.params);
      newParams.evaluations = _.map(
        composedReportController.evaluations,
        (id) => "" + id
      ).join(",");
      newParams.questions = composedReportController.questions;
      newParams.filters = angular.toJson(composedReportController.filters);

      // Do not notify state change otherwise report will be generated twice
      $state.go($state.current.name, newParams, { notify: false });
    };

    function isBatchable() {
      return (composedReportController.layout || CONSECUTIVE) === CONSECUTIVE;
    }

    function addReports(chunks, reports) {
      const evaluationIds = _.first(chunks);
      if (_.isEmpty(evaluationIds)) {
        return $q.resolve(reports);
      }

      return Report.generate({
        evaluations: evaluationIds,
        language: language.get(),
        type: composedReportController.type,
        template: composedReportController.template,
        layout: composedReportController.layout,
        filters: composedReportController.filters
      }).$promise.then((results) => {
        _(results)
          .flatten()
          .forEach((report) => {
            reports.push(report);
          });
        composedReportController.progress.current += 1;

        const remainder = _.drop(chunks, 1);
        return addReports(remainder, reports);
      });
    }
  }
});
