"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Participate
 * @description
 * # Participate
 * Participate resource of the diceApp
 */
angular.module("diceApp").factory("Participate", function (DiceResource) {
  return DiceResource("/participate", {
    progress: {
      method: "GET",
      url: "/participate/progress",
      isArray: true
    },
    answers: {
      method: "GET",
      url: "/participate/:evaluationId/answers"
    },
    answer: {
      method: "PUT",
      url: "/participate/:evaluationId/answers"
    },
    complete: {
      method: "PUT",
      url: "/participate/:evaluationId/complete"
    },
    logout: {
      method: "PUT",
      url: "/participate/:evaluationId/logout"
    },
    report: {
      method: "GET",
      url: "/participate/:evaluationId/report"
    },
    open: {
      method: "GET",
      url: "/participate/:evaluationId/open",
      isArray: true
    }
  });
});
