"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:loader
 * @description
 * The loader directive.
 */
angular.module("diceApp").component("loader", {
  bindings: {
    loading: "<",
    progress: "<?",
    message: "@?",
    content: "@?",
    overlay: "<?"
  },
  transclude: true,
  templateUrl: "es6/base/loader/loader.html",
  controllerAs: "loaderController"
});
