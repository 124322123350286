"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:diceMailLog
 * @description
 * diceMailLog Shows the manually sent mail logs.
 */
angular.module("diceApp").component("diceMailLog", {
  bindings: {
    evaluation: "<"
  },
  templateUrl: "es6/notifications/logs/mail.log.html",
  controllerAs: "mailLogController",
  controller: function (MailLog) {
    const mailLogController = this;

    mailLogController.$onInit = function () {
      MailLog.getByEvaluation({
        evaluationId: mailLogController.evaluation.id
      }).$promise.then(function (result) {
        mailLogController.logs = result;
      });
    };
  }
});
