"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:evaluationTypeSelect
 * @description
 * The evaluationTypeSelect directive.
 */
angular.module("diceApp").component("evaluationTemplateSelect", {
  templateUrl: "es6/templates/evaluation/evaluation.template.select.html",
  require: {
    ngModel: "ngModel"
  },
  bindings: {
    evaluationType: "<",
    required: "@?"
  },
  controllerAs: "evaluationTemplateSelectController",
  controller: function (EvaluationTemplate, $scope) {
    const evaluationTemplateSelectController = this;

    evaluationTemplateSelectController.$onInit = function () {
      $scope.$watch(
        "evaluationTemplateSelectController.evaluationType",
        setContent
      );
      $scope.$watch(
        "evaluationTemplateSelectController.ngModel.$modelValue",
        function () {
          evaluationTemplateSelectController.value =
            evaluationTemplateSelectController.ngModel.$modelValue;
        }
      );
    };

    function setContent() {
      EvaluationTemplate.query().$promise.then((evaluationTemplates) => {
        evaluationTemplateSelectController.evaluationTemplates = _.filter(
          evaluationTemplates,
          (evaluationTemplate) => {
            return (
              evaluationTemplate.type.id ===
              evaluationTemplateSelectController.evaluationType
            );
          }
        );
      });
    }

    setContent();

    evaluationTemplateSelectController.onChange = function () {
      evaluationTemplateSelectController.ngModel.$setViewValue(
        evaluationTemplateSelectController.value
      );
    };
  }
});
