"use strict";

/**
 * @ngdoc factory
 * @name missingTranslationHandler
 * @description
 * The angular-translate filter doesn't support fallbacks. This custom handler works around that,
 * by returning the 'fallbackTo' property value from interpolation options for missing translations, else the translation key.
 */
angular.module("diceApp").factory("missingTranslationHandler", function () {
  return function (translationKey, language, options) {
    if (_.isObject(options) && !_.isEmpty(options)) {
      const fallbackText = _.get(options, "fallbackTo");

      if (fallbackText) {
        return fallbackText;
      }
    }

    return translationKey;
  };
});
