"use strict";

/**
 * @ngdoc directive
 * @name diceApp.component:enumSelectMultiple
 * @description
 * The enumSelectMultiple component.
 */
angular.module("diceApp").component("enumSelectMultiple", {
  templateUrl: "es6/form-elements/enum.select.multiple.html",
  bindings: {
    type: "@"
  },
  require: {
    ngModelCtrl: "ngModel"
  },
  controllerAs: "enumerationController",
  controller: function ($scope, $filter, Enums) {
    const enumerationController = this;

    enumerationController.$onInit = function () {
      enumerationController.enumerations = [];
      loadData();

      enumerationController.ngModelCtrl.$render = function () {
        enumerationController.value =
          enumerationController.ngModelCtrl.$modelValue;
        loadChecked();
      };
    };

    function loadData() {
      Enums.query({
        type: enumerationController.type
      }).$promise.then((enumerations) => {
        enumerationController.enumerations = _(enumerations)
          .map((value) => {
            return {
              value: value,
              name: $filter("enum")(value),
              checked: false
            };
          })
          .orderBy("name")
          .value();

        loadChecked();
      });
    }

    function loadChecked() {
      const value = enumerationController.value || [];
      _.each(enumerationController.enumerations, (enumeration) => {
        enumeration.checked = _.includes(value, enumeration.value);
      });
    }

    enumerationController.onChange = function () {
      enumerationController.value = _(enumerationController.enumerations)
        .filter({ checked: true })
        .map("value")
        .value();
      enumerationController.ngModelCtrl.$setViewValue(
        enumerationController.value
      );
    };
  }
});
