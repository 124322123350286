"use strict";

import { every } from "lodash";

/**
 * @ngdoc component
 * @name diceApp.component:previewEvaluation
 * @description Previews an evaluation.
 */
angular.module("diceApp").component("previewEvaluation", {
  templateUrl: "es6/preview/preview.evaluation.html",
  bindings: {
    name: "@",
    evaluation: "<", // 'type' is an object, a numeric id or undefined with a 'typeId' instead
    tags: "<",
    confirm: "&",
    onAnswer: "&?"
  },
  controllerAs: "previewEvaluationController",
  controller: function (
    $scope,
    $document,
    $timeout,
    SessionStorage,
    descriptionFilter,
    memberChecker,
    questionType,
    translateFilter,
    valueChecker
  ) {
    const previewEvaluationController = this;

    previewEvaluationController.$onInit = function () {
      previewEvaluationController.isSidebarNavigation = _.get(
        previewEvaluationController,
        "evaluation.type.sidebarNavigation",
        false
      );
      previewEvaluationController.blocks = buildBlocks();
      previewEvaluationController.questions = buildQuestions();
      updateBlockVisibility();
      previewEvaluationController.pages = buildPages();

      _.extend(previewEvaluationController, loadCurrent());
      navigateToCurrentIndexPage();

      bindShortcuts();
    };

    previewEvaluationController.$onDestroy = function () {
      unbindShortcuts();
    };

    function buildBlocks() {
      return _(previewEvaluationController.evaluation.blocks)
        .map((block) => _.extend(block, block.block))
        .sortBy("sequence")
        .value();
    }

    function buildQuestions() {
      return _(previewEvaluationController.blocks)
        .map("questions")
        .flatten()
        .value();
    }

    function questionIsAnswered(question) {
      return question.readOnly || !valueChecker.isEmpty(question.answer);
    }

    function updateBlockVisibility() {
      const isMemberVisible = buildIsMemberVisible();

      _.each(previewEvaluationController.blocks, function (block) {
        if (angular.isDefined(block.member)) {
          block.visible = isMemberVisible(block.member);
        } else {
          block.visible = isConditionVisible(block);
        }

        if (block.visible !== false) {
          _(block.questions).each((question) => {
            question.visible = isConditionVisible(
              question.question || question
            );
            if (!question.visible) {
              clearAnswer(question);
            }
          });
        } else {
          _.forEach(block.questions, (question) => {
            question.visible = false;
            clearAnswer(question);
          });
        }
      });
    }

    function isConditionVisible(object) {
      if (_.isEmpty(object.filters)) {
        return true;
      } else {
        return _(object.filters).some((filter) => {
          const value = getValue(filter);
          return hasValue(value, filter.expected);
        });
      }
    }

    function buildIsMemberVisible() {
      const question = _.find(previewEvaluationController.questions, {
        questionType: "MEMBER_LIST"
      });

      return memberChecker.isVisible(question);
    }

    function getValue(filter) {
      if (filter.property === "QUESTION") {
        const question = _.find(previewEvaluationController.questions, {
          questionNumber: filter.name
        });

        return _.get(question, "answer");
      } else if (filter.property === "TAG") {
        const tag = _.find(previewEvaluationController.tags, {
          name: filter.name
        });

        return _.get(tag, "value");
      }
    }

    function hasValue(value, expected) {
      if (_.isArray(value)) {
        return _.includes(value, expected);
      } else {
        return expected === value;
      }
    }

    function clearAnswer(question) {
      if (!valueChecker.isEmpty(question.answer)) {
        delete question.answer;
        doAnswer(question, []);
      }
    }

    function buildPages() {
      const pages = _(previewEvaluationController.blocks)
        .filter((block) => block.visible !== false && block.active !== false)
        .map(buildPage)
        .value();

      const hideSubmitPage = _.get(
        previewEvaluationController.evaluation,
        "type.hideSubmitPage",
        false
      );
      if (hideSubmitPage !== true) {
        pages.push(builders.CHECK_AND_SUBMIT());
      }

      return pages;
    }

    function buildPage(block) {
      const type = _.defaultTo(block.type, "STANDARD_BLOCK");
      const page = builders[type](block);

      if (page.block && page.block.questions) {
        _.each(page.block.questions, (question) => {
          question.readOnly = questionType.getProperty(
            question.questionType,
            "readOnly"
          );
          question.answered = questionIsAnswered(question);
        });

        page.block.readOnlyQuestions =
          _.filter(page.block.questions, (question) => question.readOnly) || [];
        page.block.editableQuestions =
          _.filter(page.block.questions, (question) => !question.readOnly) ||
          [];
        page.hasOnlyReadOnlyQuestions = every(page.block.questions, "readOnly");
      }

      return page;
    }

    const builders = {
      STANDARD_BLOCK: buildStandardBlockPage,
      MEMBER_BLOCK: buildMemberBlockPage,
      CHECK_AND_SUBMIT: buildOverviewPage
    };

    function buildStandardBlockPage(block) {
      return {
        page: "block",
        label: descriptionFilter(block.titles),
        block: block
      };
    }

    function buildMemberBlockPage(block) {
      return {
        page: "block",
        label:
          _.get(block.member, "person.fullName") ||
          descriptionFilter(block.titles),
        icon: "user-o",
        block: block
      };
    }

    function buildOverviewPage() {
      const showAnswers = _.get(
        previewEvaluationController.evaluation,
        "type.showAnswers",
        false
      );
      const labelKey = showAnswers
        ? "Page.Participate.Page.Overview.Check"
        : "Page.Participate.Page.Overview";

      return {
        page: "overview",
        label: translateFilter(labelKey),
        icon: "envelope-o",
        before: updateUnanswered
      };
    }

    function updateUnanswered() {
      previewEvaluationController.unanswered = _(
        previewEvaluationController.pages
      )
        .filter((page) => angular.isDefined(page.block))
        .map((page, $index) => {
          let result = angular.copy(page);
          result.index = $index;

          const { block } = result;
          const availableQuestions =
            _.filter(
              block.editableQuestions,
              (question) => question.visible !== false
            ) || [];
          block.answered = _.filter(availableQuestions, (question) => {
            return question.answered === true;
          }).length;

          block.amountOfQuestions = availableQuestions.length;
          block.remaining = availableQuestions.length - block.answered;
          block.done = block.remaining === 0;
          block.percentage = block.done
            ? 100
            : (block.answered / availableQuestions.length) * 100;
          return result;
        })
        .filter((page) => page.block.done === false)
        .value();
    }

    function loadCurrent() {
      const json = SessionStorage.get(previewEvaluationController.name) || "{}";
      const current = angular.fromJson(json);

      if (_.isEmpty(current)) {
        return {
          index: getInitialPageIndex(),
          finished: false
        };
      } else {
        return current;
      }
    }

    /**
     * Determines the index of the first relevant page to start or resume on.
     *
     * If there are unanswered questions on a page, navigate to that page.
     * But if there are readonly pages (1 or more) directly before it, navigate to the first such readonly page.
     * This ensures readonly blocks assumed to be relevant to unanswered blocks are always shown when resuming the evaluation.
     *
     * Otherwise, navigate to the first page.
     */
    function getInitialPageIndex() {
      const FIRST_PAGE = 0;
      const pages = previewEvaluationController.pages || [];

      const firstUnansweredIndex = pages.findIndex((page) =>
        page.block?.editableQuestions?.some((question) => !question.answered)
      );

      if (firstUnansweredIndex === -1) {
        return FIRST_PAGE;
      }

      let firstRelevantIndex = firstUnansweredIndex;
      while (
        firstRelevantIndex > 0 &&
        firstRelevantIndex - 1 < pages.length &&
        pages[firstRelevantIndex - 1]?.hasOnlyReadOnlyQuestions
      ) {
        firstRelevantIndex--;
      }

      return firstRelevantIndex;
    }

    function navigateToCurrentIndexPage() {
      const current =
        previewEvaluationController.pages[previewEvaluationController.index];
      if (current.before) {
        current.before(current);
      }
      updateRequiredAnswered();
      resetCrumbsToCurrentIndex();
      previewEvaluationController.hasNext =
        previewEvaluationController.index <
        previewEvaluationController.pages.length - 1;
      previewEvaluationController.finished =
        previewEvaluationController.finished ||
        previewEvaluationController.index ===
          previewEvaluationController.pages.length - 1;
      previewEvaluationController.hasPrevious =
        previewEvaluationController.index > 0;
      previewEvaluationController.shortcuts = buildShortcuts();
      previewEvaluationController.progress = buildProgress();
      previewEvaluationController.current = current;
      storeCurrent();
    }

    function updateRequiredAnswered() {
      previewEvaluationController.currentRequiredAnswered =
        requiredAnsweredUntil(previewEvaluationController.index);
      previewEvaluationController.allRequiredAnswered = requiredAnsweredUntil(
        previewEvaluationController.pages.length - 1
      );
    }

    function resetCrumbsToCurrentIndex() {
      previewEvaluationController.crumbs = [];
      previewEvaluationController.unvisited = [];
      _.each(
        previewEvaluationController.pages.slice(
          0,
          previewEvaluationController.index + 1
        ),
        addCrumb
      );
      _.each(
        previewEvaluationController.pages.slice(
          previewEvaluationController.index + 1
        ),
        addUnvisited
      );
    }

    function addCrumb(page) {
      previewEvaluationController.crumbs.push(createCrumb(page));
    }

    function addUnvisited(page) {
      previewEvaluationController.unvisited.push(createCrumb(page));
    }

    function createCrumb(page) {
      return {
        label: page.label,
        icon: page.icon,
        member: _.get(page, "block.member"),
        counted: isCountedPage(page)
      };
    }

    function isCountedPage(page) {
      return angular.isUndefined(_.get(page, "block.member"));
    }

    function buildShortcuts() {
      const KEY_LEFT_ARROW = "37";
      const KEY_RIGHT_ARROW = "39";
      const KEY_ENTER = "13";
      const KEY_BACKSPACE = "8";

      let shortcuts = {};

      shortcuts[KEY_LEFT_ARROW] = previewEvaluationController.previous;

      if (previewEvaluationController.hasNext) {
        shortcuts[KEY_RIGHT_ARROW] = previewEvaluationController.next;
      } else {
        shortcuts[KEY_ENTER] = previewEvaluationController.submit;
      }

      if (previewEvaluationController.finished) {
        shortcuts[KEY_BACKSPACE] = previewEvaluationController.goToEnd;
      }

      return shortcuts;
    }

    function buildProgress() {
      const total = _.filter(
        previewEvaluationController.pages,
        isCountedPage
      ).length;
      const current = _.filter(previewEvaluationController.crumbs, {
        counted: true
      }).length;
      const percentage = Math.ceil((current / total) * 100);
      const currentPercentage = Math.floor((1 / total) * 100);
      const donePercentage = Math.ceil(((current - 1) / total) * 100);

      return { total, current, percentage, currentPercentage, donePercentage };
    }

    function storeCurrent() {
      if (previewEvaluationController.name) {
        const json = angular.toJson({
          index: previewEvaluationController.index,
          finished: previewEvaluationController.finished
        });

        SessionStorage.set(previewEvaluationController.name, json);
      }
    }

    previewEvaluationController.next = function () {
      const allowedToGoNext =
        previewEvaluationController.isSidebarNavigation ||
        previewEvaluationController.currentRequiredAnswered;

      if (previewEvaluationController.hasNext && allowedToGoNext) {
        previewEvaluationController.goTo(previewEvaluationController.index + 1);
      }
    };

    previewEvaluationController.previous = function () {
      if (previewEvaluationController.hasPrevious) {
        previewEvaluationController.goTo(previewEvaluationController.index - 1);
      }
    };

    previewEvaluationController.goTo = function (index) {
      previewEvaluationController.index = index;
      navigateToCurrentIndexPage();
    };

    previewEvaluationController.goToEnd = function () {
      if (previewEvaluationController.allRequiredAnswered) {
        previewEvaluationController.goTo(
          previewEvaluationController.pages.length - 1
        );
      }
    };

    previewEvaluationController.submit = function () {
      if (
        !previewEvaluationController.hasNext &&
        previewEvaluationController.allRequiredAnswered
      ) {
        previewEvaluationController.confirm();
        if (previewEvaluationController.name) {
          SessionStorage.remove(previewEvaluationController.name);
        }
      }
    };

    function requiredAnsweredUntil(pageIndex) {
      const pages = previewEvaluationController.pages
        .slice(0, pageIndex + 1)
        .filter(
          (page) =>
            angular.isDefined(page.block) &&
            angular.isDefined(page.block.questions)
        );

      const questions = _(pages)
        .map((page) => page.block.questions)
        .flatten()
        .filter((question) => question.visible !== false)
        .value();
      const unanswered = _.filter(
        questions,
        (question) => question.required && !question.answered
      );
      return unanswered.length === 0;
    }

    previewEvaluationController.answer = function (question, value) {
      doAnswer(question, value);
    };

    function doAnswer(question, value) {
      updateBlockVisibility();
      previewEvaluationController.pages = buildPages();
      previewEvaluationController.progress = buildProgress();

      if (previewEvaluationController.onAnswer) {
        previewEvaluationController.onAnswer({ question, value });
      }

      updateRequiredAnswered();
      updateUnanswered();
    }

    function bindShortcuts() {
      $document.on("keydown", onKeyDown);
    }

    function unbindShortcuts() {
      $document.off("keydown", onKeyDown);
    }

    function onKeyDown(event) {
      const binding = previewEvaluationController.shortcuts["" + event.which];
      if (binding && isShortcutAllowed()) {
        event.preventDefault();
        $scope.$apply(() => binding());
      }
    }

    function isShortcutAllowed() {
      return !angular
        .element($document[0].activeElement)
        .hasClass("no-shortcut");
    }
  }
});
