"use strict";

/**
 * @ngdoc service
 * @name diceApp.filter:Description
 * @description
 * The Description filter.
 */
angular.module("diceApp").filter("label", function (language) {
  return function (input, fallback) {
    return (
      _(input || [])
        .filter((d) => d.language === language.get())
        .map((d) => d.label)
        .head() || fallback
    );
  };
});
