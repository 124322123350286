"use strict";

angular.module("diceApp").component("diceTag", {
  bindings: {
    onRemove: "&"
  },
  transclude: true,
  templateUrl: "es6/tags/tag.html",
  controllerAs: "tagController"
});
