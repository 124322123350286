"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:diceEvaluationReport
 * @description
 * diceEvaluationReport Shows the evaluation report.
 */
angular.module("diceApp").component("diceEvaluationReport", {
  bindings: {
    evaluation: "<",
    operations: "<",
    filters: "<?",
    questions: "<?",
    templateId: "<?"
  },
  templateUrl: "es6/evaluations/evaluation.report.html",
  controllerAs: "reportController",
  controller: function (Report, language, $state) {
    const reportController = this;

    reportController.$onInit = function () {
      loadData().then(() => {
        reportController.loadReport();
      });

      reportController.onLanguage = language.onChange(
        reportController.loadReport
      );
    };

    reportController.$onDestroy = function () {
      reportController.onLanguage();
    };

    function loadData() {
      return Report.templates({
        evaluation: reportController.evaluation.id
      }).$promise.then((templates) => {
        reportController.templates = _.sortBy(templates, ["sequence", "id"]);

        if (angular.isDefined(reportController.templateId)) {
          reportController.template = _.parseInt(reportController.templateId);
        } else {
          reportController.template = _.get(
            _.head(reportController.templates),
            "id"
          );
        }
      });
    }

    reportController.templateChanged = function () {
      reportController.filters = {};
      reportController.questions = [];
      reloadPage(true);
    };

    reportController.loadReport = function (params) {
      if (angular.isDefined(reportController.template)) {
        reportController.loading = true;
        _.extend(reportController, params);

        Report.generate({
          evaluations: [reportController.evaluation.id],
          template: reportController.template,
          language: language.get(),
          filters: reportController.filters
        })
          .$promise.then((reports) => {
            reportController.report = _.head(reports);
          })
          .finally(() => {
            reportController.loading = false;
          });
      }

      // Do not notify state change otherwise an infinite loop will occur
      reloadPage(false);
    };

    function reloadPage(notify) {
      const newParams = angular.copy($state.params);
      newParams.questions = reportController.questions;
      newParams.filters = angular.toJson(reportController.filters);
      newParams.templateId = reportController.template;
      $state.go($state.current.name, newParams, { notify: notify });
    }
  }
});
