"use strict";

angular.module("diceApp").factory("DownloadObserver", function () {
  let refreshCallback = _.noop();

  return {
    setRefreshCallback,
    refresh
  };

  function setRefreshCallback(callback) {
    refreshCallback = callback;
  }

  function refresh() {
    refreshCallback();
  }
});
