"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:participantSessionList
 * @description
 * participantSessionList Shows the participant sessions
 */
angular.module("diceApp").component("participantSessionList", {
  bindings: {
    evaluationId: "<?"
  },
  templateUrl: "es6/base/audit/participant.session.list.html",
  controllerAs: "sessionController",
  controller: function (Pageable, ParticipantSession) {
    const sessionController = this;

    sessionController.$onInit = function () {
      sessionController.parameters = {
        evaluationId: sessionController.evaluationId,
        success: true
      };

      sessionController.pageable = Pageable.of({
        order: "started",
        reverse: true
      });

      sessionController.search();
    };

    sessionController.search = function () {
      const params = sessionController.pageable.build(
        sessionController.parameters
      );

      sessionController.loading = true;
      ParticipantSession.get(params)
        .$promise.then((sessions) => {
          sessionController.sessions = sessions;
        })
        .finally(() => {
          sessionController.loading = false;
        });
    };
  }
});
