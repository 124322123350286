"use strict";

angular.module("diceApp").factory("Promises", function ($q) {
  function chain(requests) {
    let progress = {
      loading: true,
      current: 0,
      total: requests.length
    };

    progress.$promise = _.reduce(
      requests,
      (current, request) => {
        return current.then(() => {
          return request().finally(() => {
            progress.current += 1;
          });
        });
      },
      $q.resolve()
    ).finally(() => {
      progress.loading = false;
    });

    return progress;
  }

  return {
    chain
  };
});
