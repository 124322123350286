"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Block
 * @description
 * Block resource
 */
angular.module("diceApp").factory("Block", function (DiceResource) {
  return DiceResource("/blocks");
});
