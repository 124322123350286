"use strict";

angular.module("diceApp").directive("ngFileSelect", function (fileReader) {
  return {
    scope: {
      ngFileSelect: "&"
    },
    link: function ($scope, el) {
      el.bind("change", function (e) {
        $scope.file = (e.srcElement || e.target).files[0];
        fileReader.readAsDataUrl($scope.file, $scope).then(function (result) {
          $scope.ngFileSelect({ result });
        });
      });
    }
  };
});
