"use strict";

angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("relation-list", {
    url: "/relations",
    data: {
      root: true
    },
    template: "<dice-relation-list></dice-relation-list>"
  });
});
