"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:statusTransitionModal
 * @description Shows a modal to configure a status transition.
 */
angular.module("diceApp").component("statusTransitionModal", {
  bindings: {
    statusTransition: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/status/transition/status.transition.modal.html",
  controllerAs: "transitionController",
  controller: function (StatusTransition) {
    const transitionController = this;

    transitionController.close = function () {
      transitionController.modal.close();
    };

    transitionController.save = function () {
      StatusTransition.store(
        transitionController.statusTransition
      ).$promise.then(function (result) {
        transitionController.onSave(result);
        transitionController.close();
      });
    };
  }
});
