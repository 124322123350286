"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:StatusTransitionStates
 * @description
 * # StatusTransitionStates
 * Configures all states for status transitions
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider
    .state("status-transition-list", {
      url: "/status-transition",
      data: {
        root: true
      },
      resolve: {
        transitions: function (StatusTransition) {
          return StatusTransition.query().$promise;
        }
      },
      controllerAs: "statusTransitionListController",
      controller: function (transitions) {
        const statusTransitionListController = this;
        statusTransitionListController.transitions = transitions;
      },
      template:
        '<dice-status-transition-list transitions="statusTransitionListController.transitions"></dice-status-transition-list>'
    })
    .state("status-transition-create", {
      url: "/status-transition/create",
      template:
        '<status-transition-modal status-transition="{}"></status-transition-modal>'
    })
    .state("status-transition", {
      url: "/status-transition/{id:[0-9]{1,8}}",
      resolve: {
        transition: function ($stateParams, StatusTransition) {
          return StatusTransition.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "statusTransitionController",
      controller: function (transition) {
        const statusTransitionController = this;
        statusTransitionController.transition = transition;
      },
      template:
        '<status-transition-modal status-transition="statusTransitionController.transition"></status-transition-modal>'
    });
});
