"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:RequestLog
 * @description
 * # RequestLog
 * RequestLog resource of the diceApp
 */
angular.module("diceApp").factory("RequestLog", function (DiceResource) {
  return DiceResource("/request-logs");
});
