"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:EntityLabel
 * @description
 * EntityLabel Clickable link to an entity
 */
angular.module("diceApp").component("entityLabel", {
  templateUrl: "es6/base/audit/entity.label.html",
  controllerAs: "entityLabelController",
  bindings: {
    entity: "<"
  }
});
