"use strict";

/**
 * @ngdoc service
 * @name diceApp.Message
 * @description
 * # Message
 * Messages in the diceApp.
 */
angular.module("diceApp").factory("message", function ($interval, $filter) {
  const messages = [];

  return {
    getMessages,
    addInfo,
    addSuccess,
    addInfoLabel,
    addSuccessLabel,
    addErrorLabel,
    addWarning,
    addError,
    onSaved
  };

  function getMessages() {
    return messages;
  }

  function addInfoLabel(label) {
    addInfo($filter("translate")(label));
  }

  function addInfo(text) {
    addMessage("info", text, 5);
  }

  function addSuccessLabel(label) {
    addSuccess($filter("translate")(label));
  }

  function addErrorLabel(label) {
    addError($filter("translate")(label));
  }

  function addSuccess(text) {
    addMessage("success", text, 3);
  }

  function addWarning(text) {
    addMessage("warning", text, 5);
  }

  function addError(text) {
    addMessage("danger", text, 7);
  }

  function onSaved() {
    addSuccessLabel("Message.Save.Success");
  }

  function addMessage(type, text, duration) {
    let existMessage = _.find(messages, {
      text: text
    });

    if (existMessage) {
      $interval.cancel(existMessage.timer);
      existMessage.timer = $interval(function () {
        existMessage.remove();
      }, existMessage.duration * 1000);
      return;
    }

    messages.push(createMessage(type, text, duration));
  }

  function createMessage(type, text, duration) {
    const self = {
      type: type,
      text: text,
      duration: duration
    };

    self.remove = function () {
      let index = messages.indexOf(this);
      messages.splice(index, 1);
    };

    self.timer = $interval(function () {
      $interval.cancel(self.timer);
      self.remove();
    }, self.duration * 1000);

    return self;
  }
});
