"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:StatusType
 * @description
 * # StatusType
 * StatusType resource of the diceApp
 */
angular.module("diceApp").factory("StatusType", function (DiceResource) {
  return DiceResource("/status-types", {
    storeOrderSequence: {
      method: "PUT",
      url: "/status-types/sequence",
      isArray: true
    }
  });
});
