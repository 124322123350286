"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:diceMemberAvatar
 * @description Shows the avatar of a member
 */
angular.module("diceApp").component("diceMemberAvatar", {
  templateUrl: "es6/members/member.avatar.html",
  bindings: {
    member: "<"
  },
  controllerAs: "avatarController",
  controller: function ($scope, memberService) {
    const avatarController = this;

    avatarController.$onInit = loadData;
    $scope.$watch("avatarController.member", loadData);

    function loadData() {
      avatarController.person = memberService.buildPerson(
        avatarController.member
      );
    }
  }
});
