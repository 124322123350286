"use strict";

angular.module("diceApp").component("changePasswordModal", {
  templateUrl: "es6/base/password-change/change-password.modal.html",
  controllerAs: "changePasswordModalController",
  bindings: {
    modal: "<",
    person: "<"
  },
  controller: function (Person) {
    const changePasswordModalController = this;

    changePasswordModalController.form = {};

    /**
     * Submits the user change password form to the backend.
     * The passwordConfirm field is removed because we only use that on the client side.
     */
    changePasswordModalController.save = () => {
      Person.changePassword(
        {
          id: changePasswordModalController.person.id
        },
        {
          currentPassword: changePasswordModalController.form.currentPassword,
          newPassword: changePasswordModalController.form.newPassword
        }
      ).$promise.then(function () {
        changePasswordModalController.modal.close();
      });
    };

    changePasswordModalController.dismiss = function () {
      changePasswordModalController.modal.close();
    };

    changePasswordModalController.validator = function () {
      return (
        changePasswordModalController.form.newPassword ===
        changePasswordModalController.form.confirmNewPassword
      );
    };
  }
});
