"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:EvaluationWorkflow
 * @description
 * # EvaluationWorkflow
 * EvaluationWorkflow resource of the diceApp
 */
angular
  .module("diceApp")
  .factory("EvaluationWorkflow", function (DiceResource) {
    return DiceResource("/evaluation-workflows");
  });
