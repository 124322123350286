"use strict";

/**
 * @ngdoc service
 * @name diceApp.LanguageLoaderFactory
 * @description
 * # LanguageLoader
 * LanguageLoader service.
 */
angular.module("diceApp").factory("languageLoader", function ($q, Label) {
  return function (options) {
    // $q.defer is needed for language angular-translate directive
    var deferred = $q.defer();
    Label.query().$promise.then(
      function (data) {
        // Get dictionary by language code
        var dictionary = _.filter(data, {
          language: options.key.toUpperCase()
        });

        // Create dictionary object to resolve
        var translations = {};
        _.each(dictionary, function (dictionaryItem) {
          var key = dictionaryItem.key;
          translations[key] = dictionaryItem.value;
          translations.language = dictionaryItem.language;
        });

        // Resolves the stored language dictionary
        deferred.resolve(translations);
      },
      function () {
        // On error, Reject the promise
        deferred.reject(options.key);
      }
    );
    // Returns the deffered language.
    return deferred.promise;
  };
});
