"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:EvaluationTemplate
 * @description
 * # EvaluationTemplate
 * EvaluationTemplate resource of the diceApp
 */
angular
  .module("diceApp")
  .factory("EvaluationTemplate", function (DiceResource) {
    return DiceResource("/templates", {
      search: {
        url: "/templates/search",
        method: "GET",
        isArray: false
      },
      blocks: {
        url: "/templates/:id/blocks",
        method: "GET",
        isArray: true
      }
    });
  });
