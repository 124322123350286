"use strict";

angular.module("diceApp").component("substituteBanner", {
  templateUrl: "es6/base/alerts/substitute.banner.html",
  controllerAs: "substituteBannerController",
  controller: function (authentication) {
    const substituteBannerController = this;
    substituteBannerController.getCurrent = authentication.getCurrent;
    substituteBannerController.restore = function () {
      authentication.restore();
    };
  }
});
