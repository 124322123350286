"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceReportTemplate
 * @description
 * Create or edit a report template
 */
angular.module("diceApp").component("diceReportTemplate", {
  templateUrl: "es6/report/template/report.template.html",
  bindings: {
    reportTemplate: "<"
  },
  controllerAs: "reportTemplateController",
  controller: function (
    ReportTemplate,
    Enums,
    language,
    $filter,
    $state,
    message
  ) {
    const reportTemplateController = this;
    reportTemplateController.tags = [
      "programme",
      "programmeType",
      "registrationType",
      "gender",
      "formerEducation"
    ];
    reportTemplateController.$onInit = loadData;

    function loadData() {
      reportTemplateController.blockTypes = _.map(
        reportTemplateController.reportTemplate.blockTypes,
        "blockType"
      );
      buildQuestionTypes(reportTemplateController.reportTemplate);
      buildTags(reportTemplateController.reportTemplate);
      buildHeaders(reportTemplateController.reportTemplate);
      buildQuestionTemplates(reportTemplateController.reportTemplate);
    }

    reportTemplateController.remove = function () {
      ReportTemplate.delete({
        id: reportTemplateController.reportTemplate.id
      }).$promise.then(function () {
        message.addSuccessLabel("Message.Delete.Success");
        $state.go("report-template-list");
      });
    };

    reportTemplateController.save = function (form) {
      const body = buildSaveBody(reportTemplateController.reportTemplate);
      return ReportTemplate.store(body).$promise.then(function (result) {
        delete reportTemplateController.tag;
        form.$setPristine(false);
        message.addSuccessLabel("Message.Save.Success");
        if (angular.isUndefined(body.id)) {
          $state.go("report-template-edit", { id: result.id });
        } else {
          reportTemplateController.reportTemplate = result;
          loadData();
        }
      });
    };

    function buildSaveBody() {
      const original = reportTemplateController.reportTemplate;
      let body = angular.copy(original);

      body.blockTypes = _.map(
        reportTemplateController.blockTypes,
        (blockType) => {
          const found = _.find(original.blockTypes, { blockType });
          return {
            id: _.get(found, "id"),
            blockType: blockType
          };
        }
      );

      body.memberTypes = _.filter(body.memberTypes, (member) => {
        return member.memberType !== null;
      });

      body.questions = _.filter(
        reportTemplateController.questions,
        (question) => {
          return !_.isEmpty(question.visualization);
        }
      );

      body.headers = _.map(reportTemplateController.headers, (header) => {
        const result = angular.copy(header);
        result.values = (header.values || "").split(",");
        return result;
      });

      if (!body.filterQuestions) {
        original.questionTemplates = [];
        body.questionTemplates = [];
      }

      body.tags = _.map(reportTemplateController.tags, (tag) =>
        angular.copy(tag)
      );

      _.each(body.tags, updateSequence);
      _.each(body.headers, updateSequence);
      return body;
    }

    reportTemplateController.onMoved = function (items, index) {
      items.splice(index, 1);
    };

    // Questions

    function buildQuestionTypes(template) {
      Enums.query({
        type: "question-type"
      }).$promise.then(function (types) {
        let questions = template.questions || [];
        reportTemplateController.questions = _(types)
          .map(function (type) {
            let args = { questionType: type };
            let result = _.find(questions, args) || args;
            result.name = $filter("enum")(type);
            return result;
          })
          .value();
      });
    }

    // Header

    function buildHeaders(template) {
      reportTemplateController.headers = _(template.headers || [])
        .map((header) => {
          const result = angular.copy(header);
          result.values = (header.values || []).join(",");
          return result;
        })
        .sortBy("sequence")
        .value();
    }

    reportTemplateController.addHeader = function () {
      const header = {
        labels: []
      };
      reportTemplateController.headers.push(header);
    };

    reportTemplateController.removeHeader = function (index) {
      reportTemplateController.headers.splice(index, 1);
    };

    // Tag

    function buildTags(template) {
      reportTemplateController.tags = _.sortBy(template.tags || [], "sequence");
    }

    reportTemplateController.showTag = function (tag) {
      reportTemplateController.tag = tag;
    };

    reportTemplateController.addTag = function () {
      const tag = {};
      reportTemplateController.tags.push(tag);
      reportTemplateController.showTag(tag);
    };

    reportTemplateController.removeTag = function (index) {
      reportTemplateController.tags.splice(index, 1);
      delete reportTemplateController.tag;
    };

    reportTemplateController.addTagGroup = function (tag) {
      if (angular.isUndefined(tag.groups)) {
        tag.groups = [];
      }
      tag.groups.push({ label: "", value: "" });
    };

    reportTemplateController.removeTagGroup = function (tag, $index) {
      tag.groups.splice($index, 1);
    };

    // Attributes

    reportTemplateController.addAttribute = function () {
      reportTemplateController.reportTemplate.attributes.push({});
    };

    reportTemplateController.removeAttribute = function (index) {
      reportTemplateController.reportTemplate.attributes.splice(index, 1);
    };

    // Member type

    reportTemplateController.addMemberType = function () {
      reportTemplateController.reportTemplate.memberTypes.push({
        memberType: null,
        showMember: false,
        showFeedback: false
      });
    };

    reportTemplateController.removeMemberType = function (index) {
      reportTemplateController.reportTemplate.memberTypes.splice(index, 1);
    };

    function updateSequence(container, $index) {
      container.sequence = $index;
    }

    // Question templates
    function buildQuestionTemplates(template) {
      if (template.questionTemplates && template.questionTemplates.length > 0) {
        template.filterQuestions = true;
      }
    }
  }
});
