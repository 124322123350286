"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:evaluationUrl
 * @description
 * evaluationUrl Shows the evaluation URL.
 */
angular.module("diceApp").component("evaluationUrl", {
  bindings: {
    isSecured: "<",
    url: "<"
  },
  templateUrl: "es6/evaluations/evaluation.url.html",
  controllerAs: "evaluationUrlController"
});
