"use strict";

/**
 * Pretty print a duration value (in milliseconds), for example: 1 day and 23 hours
 * Usage goes as follows:
 * {{ milliseconds | duration : pattern : maximum : defaultText }}
 */
angular.module("diceApp").filter("duration", function ($filter) {
  var DurationBuilder = function (millis, maximum) {
    var instance = this;

    instance.text = "";
    instance.count = 0;
    instance.remainder = millis;
    instance.maximum = maximum;

    instance.addUnit = function (unit, amountPerUnit, roundUp) {
      if (!instance.maximum || instance.count < instance.maximum) {
        if (instance.remainder >= amountPerUnit) {
          if (instance.text) {
            instance.text += " " + $filter("translate")("Duration.And") + " ";
          }

          instance.count += 1;

          let amount = Math.floor(instance.remainder / amountPerUnit);
          instance.remainder %= amountPerUnit;

          if (roundUp === true) {
            amount += 1;
          }
          if (amount > 1) {
            unit += "s";
          }

          instance.text += amount + " " + $filter("translate")(unit);
        }
      }
    };

    instance.toString = function (defaultText) {
      return instance.text ? instance.text : defaultText;
    };
  };

  var units = [
    {
      code: "d",
      name: "Duration.Day",
      amount: 86400000
    },
    {
      code: "h",
      name: "Duration.Hour",
      amount: 3600000
    },
    {
      code: "m",
      name: "Duration.Minute",
      amount: 60000
    },
    {
      code: "s",
      name: "Duration.Second",
      amount: 1000
    },
    {
      code: "S",
      name: "Duration.Millisecond",
      amount: 1
    }
  ];

  return function (millis, pattern, maximum, defaultText, roundUp) {
    var options = {
      pattern: pattern,
      maximum: maximum,
      defaultText: defaultText
    };

    if (!options.pattern) {
      options.pattern = "dhmsS";
    }
    if (angular.isUndefined(options.maximum)) {
      options.maximum = 1;
    }

    var builder = new DurationBuilder(millis, options.maximum);
    var lowestUnitName;
    angular.forEach(units, function (unit) {
      if (options.pattern.indexOf(unit.code) !== -1) {
        builder.addUnit(unit.name, unit.amount, roundUp);
        lowestUnitName = unit.name;
      }
    });
    return builder.toString(
      lowestUnitName ? "< 1 " + $filter("translate")(lowestUnitName) : "?"
    );
  };
});
