"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:workflowLabel
 * @description
 * workflowLabel Shows the workflow task in a human readable form
 */
angular.module("diceApp").component("workflowLabel", {
  bindings: {
    workflow: "<",
    classes: "<?"
  },
  templateUrl: "es6/workflow/workflow.label.html",
  controllerAs: "workflowLabelController",
  controller: function () {
    const workflowLabelController = this;

    workflowLabelController.$onInit = function () {
      const fromStatus = _.result(
        workflowLabelController.workflow,
        "statusTransition.fromStatus"
      );
      workflowLabelController.expectedStatus = _.result(
        workflowLabelController.workflow,
        "expectedStatus",
        fromStatus
      );
    };
  }
});
