"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:holidayList
 * @description Manages a list of holiday periods.
 */
angular.module("diceApp").component("holidayList", {
  templateUrl: "es6/period/holiday/holiday.list.html",
  controllerAs: "holidayListController",
  controller: function ($uibModal, Holiday, Pageable, message) {
    const holidayListController = this;

    holidayListController.$onInit = function () {
      holidayListController.pageable = Pageable.of({
        order: "startDate",
        reverse: true
      });

      loadData();
    };

    holidayListController.search = function () {
      loadData();
    };

    function loadData() {
      const params = holidayListController.pageable.build();

      holidayListController.loading = true;
      Holiday.get(params)
        .$promise.then((holidays) => {
          holidayListController.holidays = holidays;
        })
        .finally(() => {
          holidayListController.loading = false;
        });
    }

    holidayListController.open = function (holiday) {
      $uibModal.open({
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.modal = $uibModalInstance;
          modalController.holiday = angular.copy(holiday);

          modalController.onSave = function () {
            loadData();
          };
        },
        template: `
            <holiday-modal
              holiday="modalController.holiday"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </holiday-modal>'
           `
      });
    };

    holidayListController.remove = function (holiday) {
      Holiday.remove(holiday.id).$promise.then(() => {
        message.addSuccessLabel("Message.Delete.Success");
        loadData();
      });
    };
  }
});
