"use strict";

angular
  .module("diceApp")
  .factory("serverErrorInterceptor", function (message, $q, errorFieldsStore) {
    return {
      responseError: function (response) {
        if (response.status === 422) {
          message.addErrorLabel("Error.UnprocessableContent");
          errorFieldsStore.publish(response.data);
        } else if (response.status === 400 || response.status === 500) {
          if (response.data.message) {
            message.addErrorLabel(response.data.message);
          } else {
            message.addError("Fout op de server");
          }
        }

        return $q.reject(response);
      }
    };
  });
