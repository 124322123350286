"use strict";

angular.module("diceApp").component("dateInput", {
  templateUrl: "es6/base/forms/inputs/date.input.html",
  bindings: {
    inputName: "@",
    placeholder: "@?",
    disabled: "@?",
    required: "@?",
    isReadOnly: "<?",
    viewClasses: "<?",
    min: "<?",
    max: "<?",
    appendToBody: "<?",
    classes: "@?"
  },
  require: {
    ngModelCtrl: "ngModel"
  },
  controllerAs: "dateInputController",
  controller: function ($element) {
    const dateInputController = this;

    dateInputController.$onInit = function () {
      setModelFormatter();
      setModelRender();

      // eslint-disable-next-line no-undef
      dateInputController.format = TE_EV_APP.dateFormat;
      dateInputController.opened = false;
      dateInputController.options = {};
      dateInputController.altInputFormats = ["d!-M!-yyyy", "yyyy-MM-dd"];
    };

    dateInputController.$onChanges = function () {
      dateInputController.minDate = parse(dateInputController.min);
      dateInputController.maxDate = parse(dateInputController.max);

      if (!dateInputController.isReadOnly) {
        validate();
      }
    };

    function validate() {
      const value = format(dateInputController.model);
      const min = format(dateInputController.minDate);
      const max = format(dateInputController.maxDate);

      dateInputController.ngModelCtrl.$setValidity(
        "min",
        !value || !min || value >= min
      );
      dateInputController.ngModelCtrl.$setValidity(
        "max",
        !value || !max || value <= max
      );
    }

    function setModelFormatter() {
      dateInputController.ngModelCtrl.$formatters.push((modelValue) => {
        // If model is a number then value is epoch millis and value is converted to number
        const value = parseInt(modelValue);
        if (isNumber(value)) {
          return value;
        }
        return modelValue;
      });
    }

    function isNumber(value) {
      return !isNaN(value);
    }

    function setModelRender() {
      dateInputController.ngModelCtrl.$render = () => {
        dateInputController.model = parse(
          dateInputController.ngModelCtrl.$viewValue
        );
        validate();
      };
    }

    function parse(value) {
      if (value) {
        return new Date(value);
      }
    }

    dateInputController.isDisabled = function () {
      const inputElement = $element.find("input")[0];
      return dateInputController.disabled === "true" || inputElement.disabled;
    };

    dateInputController.onChange = function () {
      validate();

      const value = format(dateInputController.model);
      dateInputController.ngModelCtrl.$setViewValue(value);
    };

    function format(date) {
      if (date) {
        return date.getTime();
      }
    }
  }
});
