"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:authorizationFactory
 * @description
 * # authorizationService
 * Authorization factory of the diceApp
 */
angular.module("diceApp").factory("authorization", function (AccessRule) {
  return { operations };

  function operations(evaluationId) {
    return AccessRule.granted({ evaluationId }).$promise.then(
      function (granted) {
        var result = {};
        _.each(granted.operations, (operation) => (result[operation] = true));
        return result;
      }
    );
  }
});
