"use strict";

import avatar from "../../images/avatar.jpeg";

/**
 * @ngdoc directive
 * @name diceApp.directive:diceMemberEdit
 * @description Opens a modal to add members.
 */
angular.module("diceApp").factory("memberService", function () {
  function buildPerson(member) {
    if (angular.isUndefined(member)) {
      return {};
    }

    let person = angular.copy(member.person || {});
    if (!_.isEmpty(member.fullName)) {
      person.displayName = member.fullName;
    }
    if (!_.isEmpty(member.email)) {
      person.email = member.email;
    }
    return _.extend(
      {
        photoUrl: avatar
      },
      person
    );
  }

  return { buildPerson };
});
