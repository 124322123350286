"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:widgetModal
 * @description manage the dashboard widgets
 */
angular.module("diceApp").component("widgetModal", {
  templateUrl: "es6/dashboard/widget.modal.html",
  controllerAs: "widgetController",
  bindings: {
    modal: "<",
    widget: "<",
    onSave: "<"
  },
  controller: function (EvaluationWidget, $state) {
    const widgetController = this;

    widgetController.$onInit = function () {
      if (angular.isUndefined(widgetController.widget.id)) {
        widgetController.widget.roles = [];
      }

      widgetController.evaluationPages = _.filter($state.get(), {
        parent: "evaluation"
      });
      _.each(widgetController.widget.roles, map);
    };

    function map(widgetRole) {
      let status = widgetRole.status.id;
      let type = widgetRole.type.id;
      let evaluationType = widgetRole.status.evaluationType.id;

      widgetRole.status = status;
      widgetRole.type = type;
      widgetRole.evaluationType = angular.isDefined(evaluationType)
        ? evaluationType
        : "";
    }

    widgetController.add = function () {
      widgetController.widget.roles.push({ evaluationType: "" });
    };

    widgetController.remove = function (widgetRole) {
      _.remove(widgetController.widget.roles, {
        id: widgetRole.id,
        status: widgetRole.status,
        type: widgetRole.type
      });
    };

    widgetController.dismiss = function () {
      widgetController.modal.close();
    };

    widgetController.save = function () {
      let body = prepare();
      EvaluationWidget.store(body).$promise.then(function (result) {
        widgetController.onSave(result);
        widgetController.modal.close();
      });
    };

    function prepare() {
      let widget = angular.copy(widgetController.widget);
      return widget;
    }
  }
});
