"use strict";

angular.module("diceApp").filter("descriptionTranslate", function () {
  return function (description, language) {
    let result = "";
    if (description) {
      _.forEach(description, function (field) {
        if (field.language === language) {
          result = field.value;
        }
      });
      return result;
    }
  };
});
