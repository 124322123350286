"use strict";

/**
 * Formats a date to the dice datetime format.
 * Usage goes as follows:
 * {{ dateTime | diceDateTime }}
 */
angular.module("diceApp").filter("diceDateTime", function ($filter) {
  return function (dateTime) {
    if (!dateTime) {
      return "";
    } else {
      // eslint-disable-next-line no-undef
      return $filter("date")(dateTime, TE_EV_APP.dateTimeFormat);
    }
  };
});
