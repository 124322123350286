"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:SecurityStates
 * @description
 * # SecurityStates
 * Configures all states in relation to security.
 */
angular.module("diceApp").config(function ($stateProvider) {
  const PUBLIC = {
    public: true,
    root: true
  };

  const STANDALONE = _.extend(
    {
      visible: {
        breadcrumb: false
      }
    },
    PUBLIC
  );

  $stateProvider.state("login", {
    url: "/login?successUrl",
    template: `
      <dice-login
        redirect="true"
        success-url="loginRouteController.successUrl">
      </dice-login>
    `,
    controllerAs: "loginRouteController",
    controller: function ($stateParams) {
      const loginRouteController = this;
      loginRouteController.successUrl = $stateParams.successUrl;
    },
    data: PUBLIC
  });

  $stateProvider.state("support", {
    url: "/support",
    template: `
      <dice-login
        redirect="false">
      </dice-login>
    `,
    data: PUBLIC
  });

  $stateProvider.state("token", {
    url: "/token?action",
    template: `
        <authenticate-token
            action="authenticationTokenStateController.action">
        </authenticate-token>
    `,
    data: STANDALONE,
    controllerAs: "authenticationTokenStateController",
    controller: function ($stateParams) {
      const authenticationTokenStateController = this;
      authenticationTokenStateController.action = $stateParams.action;
    }
  });

  $stateProvider.state("forbidden", {
    url: "/forbidden",
    template: `
      <div class="m-t-15">
        <dice-forbidden></dice-forbidden>
      </div>
    `,
    data: STANDALONE
  });

  $stateProvider.state("expired", {
    url: "/expired",
    template: `
      <div class="m-t-15">
        <dice-expired></dice-expired>
      </div>
    `,
    data: STANDALONE
  });

  $stateProvider.state("forgot-password", {
    url: "/forgot-password",
    template: "<dice-forgot-password></dice-forgot-password>",
    data: STANDALONE
  });

  $stateProvider.state("password-change-request", {
    url: "/password-change-request/:personId/forgot-password/:code",
    template: `
      <dice-forgot-password-perform
        person-id="{{ passwordChangeController.personId }}"
        code="{{ passwordChangeController.code }}">
      </dice-forgot-password-perform>
    `,
    controller: function ($stateParams) {
      this.personId = $stateParams.personId;
      this.code = $stateParams.code;
    },
    controllerAs: "passwordChangeController",
    data: STANDALONE
  });

  $stateProvider.state("external-system-list", {
    url: "/external-systems",
    template: "<dice-external-system-list></dice-external-system-list>",
    data: {
      root: true,
      visible: {
        breadcrumb: false
      }
    }
  });
});
