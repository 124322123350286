"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:OptionTemplateController
 * @description
 * # OptionTemplateController
 * Controller of the diceApp
 */
angular.module("diceApp").component("diceOptionTemplate", {
  bindings: {
    option: "<"
  },
  templateUrl: "es6/templates/option/option.template.html",
  controllerAs: "optionTemplateController",
  controller: function (OptionTemplate, Threshold, $state, message) {
    const optionTemplateController = this;

    optionTemplateController.$onInit = function () {
      Threshold.query().$promise.then((thresholds) => {
        optionTemplateController.thresholds = thresholds;
      });
    };

    optionTemplateController.save = function (form) {
      return OptionTemplate.store(
        optionTemplateController.option
      ).$promise.then(function (result) {
        form.$setPristine();

        if (angular.isUndefined(optionTemplateController.option.id)) {
          $state.go("option-template", { id: result.id });
        }
        message.addSuccessLabel("Message.Save.Success");
      });
    };

    optionTemplateController.remove = function () {
      OptionTemplate.remove(optionTemplateController.option.id).$promise.then(
        function () {
          message.addSuccessLabel("Message.Delete.Success");
          $state.go("option-template-list");
        }
      );
    };

    optionTemplateController.onOptions = function (options) {
      optionTemplateController.option.options = options;
    };
  }
});
