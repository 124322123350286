"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:memberChecker
 * @description
 * Check members in an evaluation.
 */
angular.module("diceApp").factory("memberChecker", function () {
  return { isVisible };

  function isVisible(question) {
    let original = _.result(question, "answer");

    let answered = original || [];
    answered = _.isArray(original) ? original : [original];
    answered = _.map(answered, function (answer) {
      return parseInt(answer);
    });

    const autoSelect =
      _.result(question, "autoSelectQuestion", false) &&
      angular.isUndefined(original);
    return function (member) {
      return autoSelect || _.includes(answered, member.id);
    };
  }
});
