"use strict";

/**
 * @ngdoc component
 * @name diceApp.directive:noResults
 * @description
 * The noResults component.
 */
angular.module("diceApp").component("noResults", {
  bindings: {
    explain: "@?"
  },
  controllerAs: "noResultsController",
  templateUrl: "es6/base/tables/no.results.html"
});
