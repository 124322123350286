"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:DiceResource
 * @description
 * The DiceResource service.
 */
angular
  .module("diceApp")
  .factory("DiceResource", function ($resource, $http, $store, $q) {
    function wrapUrl(url) {
      if (_.startsWith(url, "/")) {
        url = "/api" + url;
      }
      return url;
    }

    return function (url, extensions) {
      const transformed = {};
      _.each(extensions, function (extension, name) {
        const result = angular.copy(extension);
        result.url = wrapUrl(result.url);
        transformed[name] = result;
      });

      if (!transformed.update) {
        // Update requests should be send as 'PUT'
        transformed.update = { method: "PUT" };
      }

      const baseUrl = wrapUrl(url);
      const resource = $resource(baseUrl + "/:id", {}, transformed);
      const result = _.extend(resource, {
        find: function (id) {
          if (angular.isUndefined(id)) {
            const promise = $q.resolve();
            return wrap(promise);
          }

          return resource.get({ id });
        },
        store: function (data) {
          if (angular.isDefined(data.id)) {
            return resource.update(
              {
                id: data.id
              },
              _.omit(data, "id")
            );
          } else {
            return resource.save(data);
          }
        },
        remove: function (id) {
          const promise = $http({
            url: baseUrl + "/" + id,
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
          });

          return wrap(promise);
        }
      });

      function wrap(promise) {
        return {
          $promise: promise
        };
      }

      // Store methods
      result.reload = function () {
        result.clearStore();

        return result.query().$promise.then((data) => {
          result.dataStore = $store();
          result.dataStore.saveAll(data);
          return data;
        });
      };

      result.load = function () {
        if (angular.isDefined(result.dataStore)) {
          return $q.resolve(result.dataStore.query());
        }
        return result.reload();
      };

      result.clearStore = function () {
        if (angular.isDefined(result.dataStore)) {
          result.dataStore.remove();
        }
        delete result.dataStore;
      };

      result.getFromStore = function (args) {
        if (angular.isDefined(result.dataStore)) {
          return result.dataStore.get(args);
        }
      };

      return result;
    };
  });
