"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceElementModal
 * @description
 * # diceElementModal
 * Controller of the diceApp
 */
angular.module("diceApp").component("diceElementModal", {
  bindings: {
    element: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/elements/element.modal.html",
  controllerAs: "modalController",
  controller: function (Element, message) {
    const modalController = this;

    modalController.close = function () {
      modalController.modal.close();
    };

    modalController.save = function () {
      Element.store(modalController.element).$promise.then(function (result) {
        message.onSaved();
        modalController.onSave(result);
        modalController.close();
      });
    };

    modalController.addValue = function (value) {
      if (angular.isUndefined(modalController.element.values)) {
        modalController.element.values = [];
      }

      if (value && !_.some(modalController.element.values, value)) {
        modalController.element.values.push(value);
      }

      delete modalController.value;
    };

    modalController.removeValue = function (value) {
      _.pull(modalController.element.values, value);
    };
  }
});
