"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Image
 * @description
 * # Image
 * Image resource of the diceApp
 */
angular.module("diceApp").factory("Image", function (DiceResource) {
  return DiceResource("/images", {});
});
