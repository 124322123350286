"use strict";

angular.module("diceApp").component("toggleBadge", {
  bindings: {
    label: "@",
    toggled: "<",
    onClick: "&"
  },
  templateUrl: "es6/base/toggle.badge.html",
  controllerAs: "toggleBadgeController"
});
