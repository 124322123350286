"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:diceSyncronizationLog
 * @description
 * diceSyncronizationLog Shows the available sync logs.
 */
angular.module("diceApp").component("diceSyncronizationLog", {
  templateUrl: "es6/base/audit/syncronization.log.html",
  controllerAs: "logsController",
  controller: function (SyncronizationLog) {
    const logsController = this;
    logsController.size = 10;
    logsController.$onInit = loadData;

    function loadData() {
      logsController.logs = SyncronizationLog.get({
        page: 0,
        size: logsController.size,
        sort: "started,desc"
      });
    }

    logsController.more = function () {
      logsController.size += 10;
      loadData();
    };
  }
});
