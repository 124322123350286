"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:notificationPreview
 * @description Displays a preview of notification to be sent.
 */
angular.module("diceApp").component("notificationPreview", {
  bindings: {
    notification: "<",
    evaluation: "<"
  },
  templateUrl: "es6/notifications/notification.preview.html",
  controllerAs: "notificationPreviewController",
  controller: function (Notifications) {
    const notificationPreviewController = this;

    notificationPreviewController.$onInit = function () {
      loadData();
    };

    function loadData() {
      notificationPreviewController.loading = true;
      Notifications.preview(
        {
          evaluation: notificationPreviewController.evaluation.id
        },
        notificationPreviewController.notification
      )
        .$promise.then((previews) => {
          const languages = _(previews.subjects).map("language").uniq().value();

          notificationPreviewController.previews = _.map(
            languages,
            (language) => {
              const subject = _.find(previews.subjects, { language });
              const text = _.find(previews.texts, { language });

              return {
                language,
                subject: _.result(subject, "value"),
                text: _.result(text, "value")
              };
            }
          );
        })
        .finally(() => {
          notificationPreviewController.loading = false;
        });
    }
  }
});
