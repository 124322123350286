"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Csv
 * @description
 * # Csv
 * Csv resource of the diceApp
 */
angular.module("diceApp").factory("Csv", function (DiceResource) {
  return DiceResource("/csv", {
    document: {
      method: "GET",
      url: "/csv/document",
      isArray: false
    }
  });
});
