"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:AuthenticateToken
 * @description
 * The AuthenticateToken service.
 */
angular
  .module("diceApp")
  .factory("AuthenticateToken", function (authentication, $log, $state) {
    const APP_ID = "TE_EVALUATION";
    const AUTH_MESSAGE_NAME = "te_auth_request";
    const AUTH_LOGUOUT_MESSAGE = "te_auth_logout_request";

    function authenticate(config, action) {
      if (action === "logout") {
        return logout(config.environment);
      }

      return getToken(config).then((token) => {
        if (token) {
          return authentication
            .authenticateToken(token)
            .then(() => {
              $state.go(
                "dashboard",
                {},
                {
                  reload: true
                }
              );
            })
            .catch(() => {
              return logout(config.environment);
            });
        }
      });
    }

    async function logout(environment) {
      const authUrl = getAuthURL(environment);
      try {
        await runIframe(authUrl, AUTH_LOGUOUT_MESSAGE);
        window.location.href = authUrl;
      } catch (error) {
        $log.error(error);
      }
    }

    function getToken(config) {
      return retrieveToken(
        APP_ID,
        config.environment,
        config.customerSignature
      );
    }

    async function retrieveToken(appId, environment, customerSignature) {
      const authUrl = getAuthURL(environment);
      // Workflow
      // See if a local token exists in localstorage
      // No local token, fetch remote token using iframe
      const token = await getRemoteAuthTokenSilently(authUrl);
      if (token) {
        // Remote token found
        try {
          // Validate that it appears to be a TimeEdit token
          const accessToken = token.accessToken;
          const decodedToken = parseJwt(accessToken);
          const tokenIsValid = tokenPassesValidation(decodedToken, appId);
          if (tokenIsValid) {
            return accessToken;
          } else {
            await logout(environment);
            window.location.href = authUrl;
          }
        } catch (error) {
          window.location.href = authUrl;
        }
      } else {
        const params = new URLSearchParams({ customerSignature, appId });
        window.location.href = `${authUrl}/deep-link?${params.toString()}`;
      }
    }

    function getAuthURL(environment) {
      let authUrl;
      switch (environment) {
        case "development":
          authUrl = "http://localhost:3000";
          break;
        case "beta":
          authUrl = "https://auth.timeedit.dev";
          break;
        case "staging":
          authUrl = "https://auth.timeedit.io";
          break;
        case "production":
          authUrl = "https://auth.timeedit.net";
          break;
        default:
          authUrl = "https://auth.timeedit.net";
          break;
      }
      return authUrl;
    }

    async function getRemoteAuthTokenSilently(authUrl) {
      try {
        const value = await runIframe(authUrl, AUTH_MESSAGE_NAME);
        if (!value) {
          return null;
        }
        return value;
      } catch (e) {
        return null;
      }
    }

    async function runIframe(authUrl, message) {
      return new Promise((resolve, reject) => {
        // Create Iframe
        let iframe;
        try {
          iframe = window.document.createElement("iframe");
          iframe.setAttribute("width", "0");
          iframe.setAttribute("height", "0");
          iframe.style.display = "none";
        } catch (e) {
          reject("Failed creating iframe");
        }

        // If no response within 5 sec, kill iframe and throw error
        const cleanupTimeout = setTimeout(() => {
          window.removeEventListener("message", iframeEventHandler, false);
          window.document.body.removeChild(iframe);
          reject("TIMEOUT");
        }, 5000);

        const iframeEventHandler = (e) => {
          if (!ensureValidHostname(e.origin)) {
            $log.error("Invalid hostname, ignoring...", e);
            return;
          }

          if (e.data.type !== message) {
            $log.error("Message type wrong, ignoring...", e);
            return;
          }

          // Remove timeout timer, kill iframe since it has served its purpose
          clearTimeout(cleanupTimeout);
          window.removeEventListener("message", iframeEventHandler, false);
          window.document.body.removeChild(iframe);

          // Does the response contain expected data?
          const validData =
            e && e.data && e.data.response && !e.data.response.error;
          if (validData) {
            resolve(e.data.response);
          } else {
            reject("Response is not valid - rejecting...", e);
          }
        };

        const onLoadFn = () => {
          if (!iframe.contentWindow) {
            // Cleanup
            clearTimeout(cleanupTimeout);
            window.removeEventListener("message", iframeEventHandler, false);
            window.document.body.removeChild(iframe);

            reject("NO_CONTENT_WINDOW");
          } else {
            // Send message
            iframe.contentWindow.postMessage({ type: message }, "*");
          }
        };

        const dt = new Date(); // Epoch time used for cache-busting
        iframe.onload = onLoadFn;
        window.addEventListener("message", iframeEventHandler, false);
        iframe.setAttribute(
          "src",
          `${authUrl}/authorization.html?dt=${dt.getTime()}`
        );
        window.document.body.append(iframe);
      });
    }

    function parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }

    function tokenPassesValidation(decodedToken, appId) {
      if (
        decodedToken?.exp <= Date.now() / 1000 ||
        decodedToken?.type !== "USER" ||
        !decodedToken?.id ||
        !decodedToken?.organizationId
      ) {
        return false;
      }
      return tokenPassesAuthorization(decodedToken, appId);
    }

    function tokenPassesAuthorization(accessToken, appId) {
      if (accessToken.scopes.includes("TE_ROOT_USER")) {
        return true;
      }
      return accessToken.scopes.some((scope) => scope.includes(appId));
    }

    function ensureValidHostname(origin) {
      const { hostname } = window.location;
      const timeEditDomain = hostname.substring(
        hostname.lastIndexOf("timeedit.")
      );
      const allowedDomains = ["localhost", timeEditDomain, "timeedit.net"];
      const eventHostname = new URL(origin).hostname;

      return allowedDomains.some((d) => eventHostname.endsWith(d));
    }

    return { authenticate };
  });
