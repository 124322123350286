"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:thresholdList
 * @description
 * thresholdList Shows the thresholds.
 */
angular.module("diceApp").component("thresholdList", {
  templateUrl: "es6/report/threshold/threshold.list.html",
  controllerAs: "thresholdListController",
  controller: function (Threshold) {
    const thresholdListController = this;

    thresholdListController.$onInit = function () {
      loadData();
    };

    function loadData() {
      thresholdListController.loading = true;
      Threshold.query()
        .$promise.then((thresholds) => {
          thresholdListController.thresholds = thresholds;
        })
        .finally(() => {
          thresholdListController.loading = false;
        });
    }
  }
});
