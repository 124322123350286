"use strict";

/**
 * @ngdoc directive
 * @name diceApp.component:questionTooltip
 * @description Show a question tooltip.
 */
angular.module("diceApp").component("questionTooltip", {
  templateUrl: "es6/preview/question.tooltip.html",
  bindings: {
    tooltip: "<"
  },
  controllerAs: "questionTooltipController",
  controller: function ($uibModal) {
    const questionTooltipController = this;

    questionTooltipController.open = function () {
      $uibModal.open({
        templateUrl: "es6/preview/question.tooltip.modal.html",
        controllerAs: "questionTooltipModal",
        controller: function ($uibModalInstance) {
          this.tooltip = questionTooltipController.tooltip;
          this.close = () => $uibModalInstance.dismiss();
        }
      });
    };
  }
});
