"use strict";

angular.module("diceApp").component("diceBreadcrumb", {
  templateUrl: "es6/base/breadcrumbs/breadcrumb.html",
  controllerAs: "breadcrumbController",
  controller: function (breadcrumb) {
    const breadcrumbController = this;
    breadcrumbController.breadcrumb = breadcrumb;
  }
});
