"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:memberLabel
 * @memberLabel Displays a member
 */
angular.module("diceApp").component("memberLabel", {
  bindings: {
    member: "<"
  },
  template: `
    <person-label
      ng-if="!memberLabelController.member.disabled || memberLabelController.showDeleted"
      person="memberLabelController.person"
      active="!memberLabelController.member.disabled"
      label-context="memberLabelController.member.person ? 'primary' : 'warning'">
    </person-label>`,
  controllerAs: "memberLabelController",
  controller: function (memberService, Parameter) {
    const memberLabelController = this;

    memberLabelController.$onChanges = function () {
      if (memberLabelController.member) {
        loadData();
      }
    };

    function loadData() {
      Parameter.load().then(() => {
        memberLabelController.person = memberService.buildPerson(
          memberLabelController.member
        );
        memberLabelController.showDeleted = Parameter.getParameterAsBoolean(
          "show.deleted.members"
        );
      });
    }
  }
});
