"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceParticipateReport
 * @description
 */
angular.module("diceApp").component("diceParticipateReport", {
  bindings: {
    evaluationId: "<"
  },
  templateUrl: "es6/participate/participate.report.html",
  controllerAs: "participateReportController",
  controller: function (Participate, language) {
    const participateReportController = this;

    participateReportController.$onInit = function () {
      participateReportController.open = false;

      loadData();
    };

    function loadData() {
      participateReportController.loading = true;
      Participate.report({
        evaluationId: participateReportController.evaluationId,
        language: language.get()
      })
        .$promise.then((report) => {
          participateReportController.report = report;
        })
        .finally(() => {
          participateReportController.loading = false;
        });
    }
  }
});
