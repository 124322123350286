"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:evaluationTypeModal
 * @description Shows a modal to manage an evaluation type.
 */
angular.module("diceApp").component("evaluationTypeModal", {
  bindings: {
    evaluationType: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/templates/type/evaluation.type.modal.html",
  controllerAs: "evaluationTypeController",
  controller: function (EvaluationType) {
    const evaluationTypeController = this;

    evaluationTypeController.close = function () {
      evaluationTypeController.modal.close();
    };

    evaluationTypeController.save = function () {
      EvaluationType.store(
        evaluationTypeController.evaluationType
      ).$promise.then(function (result) {
        evaluationTypeController.onSave(result);
        evaluationTypeController.close();
      });
    };
  }
});
