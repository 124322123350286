"use strict";

angular
  .module("diceApp")
  .factory("Export", function ($rootScope, $http, DownloadObserver) {
    return {
      exportSingle,
      exportMultiple,
      getStrategies,
      findAll,
      findMine,
      deleteDownload,
      generatePdf
    };

    function exportSingle(evaluationId, strategy, language, compress) {
      return exportMultiple([evaluationId], strategy, language, compress);
    }

    function exportMultiple(
      evaluationIds,
      strategy,
      language,
      compress,
      reportType,
      reportTemplate
    ) {
      return $http
        .post("api/export", {
          evaluationIds,
          strategy,
          language,
          compress: compress === true,
          reportType,
          reportTemplateId: reportTemplate
        })
        .then(DownloadObserver.refresh)
        .then((result) => {
          $rootScope.$emit("download");
          return result;
        });
    }

    function getStrategies() {
      return $http.get("api/export/strategies");
    }

    function findAll(params) {
      return $http.get("api/downloads", { params });
    }

    function findMine(params) {
      return $http.get("api/downloads/mine", { params });
    }

    function deleteDownload({ id }) {
      return $http.delete(`api/downloads/${id}`);
    }

    function generatePdf(url, language) {
      return $http
        .post("api/pdf", {
          url,
          language
        })
        .then((result) => {
          $rootScope.$emit("download");
          return result;
        });
    }
  });
