"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:evaluationWorkflowEditModal
 * @description Shows a modal to manage an evaluation workflow.
 */
angular.module("diceApp").component("evaluationWorkflowEditModal", {
  bindings: {
    entity: "<",
    evaluation: "<",
    workflow: "<",
    onChange: "&",
    onClose: "&"
  },
  templateUrl: "es6/workflow/evaluation/evaluation.workflow.edit.modal.html",
  controllerAs: "evaluationWorkflowEditController",
  controller: function (NotificationTemplate, EvaluationWorkflow) {
    const evaluationWorkflowEditController = this;

    evaluationWorkflowEditController.$onInit = function () {
      evaluationWorkflowEditController.preview = false;

      const templateId = _.result(
        evaluationWorkflowEditController.workflow,
        "notificationTemplate.id"
      );

      evaluationWorkflowEditController.loading = true;
      NotificationTemplate.find(templateId)
        .$promise.then((template) => {
          evaluationWorkflowEditController.template = template;
          setNotifications(template);
        })
        .finally(() => {
          evaluationWorkflowEditController.loading = false;
        });
    };

    function setNotifications(template) {
      if (_.isEmpty(evaluationWorkflowEditController.entity.notifications)) {
        evaluationWorkflowEditController.entity.notifications = _.map(
          template.bodies,
          (body) => _.omit(body, "id")
        );
      }
    }

    evaluationWorkflowEditController.close = function () {
      evaluationWorkflowEditController.onClose();
    };

    evaluationWorkflowEditController.save = function () {
      if (evaluationWorkflowEditController.preview === true) {
        EvaluationWorkflow.store(
          evaluationWorkflowEditController.entity
        ).$promise.then((result) => {
          evaluationWorkflowEditController.onChange({ result });
        });
      }
    };

    evaluationWorkflowEditController.remove = function () {
      EvaluationWorkflow.remove(
        evaluationWorkflowEditController.entity.id
      ).$promise.then((result) => {
        evaluationWorkflowEditController.onChange({ result });
      });
    };

    evaluationWorkflowEditController.setPreview = function (preview) {
      evaluationWorkflowEditController.preview = preview === true;
    };
  }
});
