"use strict";

angular.module("diceApp").component("diceRelationList", {
  templateUrl: "es6/relations/relation.list.html",
  controllerAs: "relationListController",
  controller: function (Relation, EvaluationType, Pageable, $uibModal) {
    const relationListController = this;

    relationListController.$onInit = function () {
      relationListController.pageable = Pageable.of();

      EvaluationType.query().$promise.then((evaluationTypes) => {
        relationListController.evaluationTypes = evaluationTypes;
        if (!_.isEmpty(evaluationTypes)) {
          relationListController.evaluationType = evaluationTypes[0].id;
          relationListController.search();
        }
      });
    };

    relationListController.search = function () {
      if (angular.isDefined(relationListController.evaluationType)) {
        const params = relationListController.pageable.build({
          evaluationTypeId: relationListController.evaluationType.id,
          text: relationListController.text
        });

        relationListController.loading = true;
        Relation.get(params)
          .$promise.then((relations) => {
            relationListController.relations = relations;
          })
          .finally(() => {
            relationListController.loading = false;
          });
      } else {
        delete relationListController.relations;
      }
    };

    relationListController.create = function () {
      open({});
    };

    relationListController.edit = function (relation) {
      const body = angular.copy(relation);
      body.type = relation.type.id;

      open(body);
    };

    function open(relation) {
      $uibModal.open({
        template: `
                    <relation-modal
                        relation="modalController.relation"
                        evaluation-type="modalController.evaluationType"
                        instance="modalController.instance"
                        on-save="modalController.onSave()">
                    </relation-modal>
                `,
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.relation = relation;
          modalController.evaluationType =
            relationListController.evaluationType;
          modalController.instance = $uibModalInstance;
          modalController.onSave = relationListController.search;
        }
      });
    }

    relationListController.remove = function (relation) {
      Relation.remove(relation.id).$promise.then(() => {
        relationListController.search();
      });
    };
  }
});
