"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:evaluationTypeSelect
 * @description
 * The evaluationTypeSelect directive.
 */
angular.module("diceApp").component("evaluationTypeSelect", {
  templateUrl: "es6/templates/type/evaluation.type.select.html",
  require: {
    ngModel: "ngModel"
  },
  bindings: {
    required: "@?",
    autoSelect: "@?"
  },
  controllerAs: "evaluationTypeSelectController",
  controller: function (EvaluationType, $scope) {
    const evaluationTypeSelectController = this;
    evaluationTypeSelectController.$onInit = function () {
      $scope.$watch(
        "evaluationTypeSelectController.ngModel.$modelValue",
        function () {
          evaluationTypeSelectController.value =
            evaluationTypeSelectController.ngModel.$modelValue;
        }
      );
    };

    EvaluationType.query().$promise.then((evaluationTypes) => {
      evaluationTypeSelectController.evaluationTypes = evaluationTypes;

      if (
        evaluationTypeSelectController.autoSelect === "true" &&
        angular.isUndefined(evaluationTypeSelectController.ngModel.$modelValue)
      ) {
        const defaultSelectedEvaluationType = _.find(
          evaluationTypes,
          "defaultSelected"
        );

        if (defaultSelectedEvaluationType) {
          evaluationTypeSelectController.value =
            defaultSelectedEvaluationType.id;
          evaluationTypeSelectController.onChange();
        }
      }
    });

    evaluationTypeSelectController.onChange = function () {
      evaluationTypeSelectController.ngModel.$setViewValue(
        evaluationTypeSelectController.value
      );
    };
  }
});
