"use strict";

angular.module("diceApp").component("requestLogModal", {
  templateUrl: "es6/base/audit/request.log.html",
  controllerAs: "logController",
  controller: function (RequestLog, Pageable, $uibModal) {
    const logController = this;

    logController.$onInit = function () {
      logController.pageable = Pageable.of({
        order: "requestDate",
        reverse: true
      });

      logController.search();
    };

    logController.search = function () {
      const params = logController.pageable.build({
        term: logController.term,
        body: logController.body,
        success: logController.success,
        date: logController.date
      });

      logController.loading = true;
      RequestLog.get(params)
        .$promise.then((logs) => {
          logController.logs = logs;
        })
        .finally(() => {
          logController.loading = false;
        });
    };

    logController.open = function (log) {
      RequestLog.get({
        id: log.id
      }).$promise.then(open);
    };

    function open(log) {
      $uibModal.open({
        size: "lg",
        templateUrl: "es6/base/audit/request.log.modal.html",
        controllerAs: "logModalController",
        controller: function ($uibModalInstance) {
          this.log = log;
          this.close = () => $uibModalInstance.dismiss();
        }
      });
    }
  }
});
