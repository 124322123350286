"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceBlockTemplateList
 * @description
 * # diceBlockTemplateList
 * Controller of the diceApp
 */
angular.module("diceApp").component("diceBlockTemplateList", {
  templateUrl: "es6/templates/block/block.template.list.html",
  controllerAs: "listController",
  controller: function (EvaluationType, BlockTemplate, language, Pageable) {
    const listController = this;

    listController.$onInit = function () {
      listController.searchString = "";
      listController.pageable = Pageable.of({
        order: "name"
      });

      EvaluationType.query().$promise.then(function (types) {
        listController.evaluationTypes = types;
        listController.evaluationType = null;
        listController.search();
      });
    };

    listController.search = () => {
      listController.syncing = true;

      const params = listController.pageable.build({
        language: language.get(),
        text: listController.searchString,
        evaluationType: listController.evaluationType
      });

      BlockTemplate.search(params)
        .$promise.then((result) => {
          listController.blocks = result;
        })
        .finally(() => {
          listController.syncing = false;
        });
    };

    listController.getDetails = function (template) {
      return BlockTemplate.get({ id: template.id });
    };
  }
});
