"use strict";

angular
  .module("diceApp")
  .directive("savePagination", function ($state, $timeout, SessionStorage) {
    return {
      restrict: "A",
      scope: false,
      require: "ngModel",
      link: function (scope, elm, attr, ngModel) {
        const savedData = SessionStorage.get($state.current.name);

        if (savedData) {
          $timeout(function () {
            scope.$apply(function () {
              ngModel.$setViewValue(savedData);
              SessionStorage.remove($state.current.name);
            });
          }, 0);
        }

        scope.$on("$stateChangeStart", function () {
          SessionStorage.set($state.current.name, ngModel.$modelValue);
        });
      }
    };
  });
