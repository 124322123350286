"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:dicePreviewQuestion
 * @description Previews a question.
 */
angular.module("diceApp").component("dicePreviewQuestion", {
  templateUrl: "es6/preview/question.html",
  bindings: {
    question: "=",
    previous: "<?",
    next: "<?",
    onAnswer: "&?",
    blocks: "<?"
  },
  controllerAs: "previewQuestionController",
  controller: function (
    $scope,
    language,
    questionType,
    memberChecker,
    memberService,
    descriptionFilter
  ) {
    const previewQuestionController = this;

    previewQuestionController.$onChanges = function () {
      prepareQuestion();
    };

    previewQuestionController.validateNumber = function (oldValue) {
      if (angular.isUndefined(previewQuestionController.question.answer)) {
        previewQuestionController.question.answer = oldValue;
      }
    };

    previewQuestionController.isAnswerOptionDisabled = function (
      answerOptionChecked
    ) {
      if (answerOptionChecked) {
        return false; // Do not disable the checked options; the user must be able to un-select these in order to select other options.
      }

      if (
        angular.isUndefined(
          previewQuestionController.question.maximumSelectableOptions
        )
      ) {
        return false;
      }

      const numberOfStandardOptionsChecked = _.filter(
        previewQuestionController.options,
        "checked"
      ).length;
      const isOtherOptionChecked =
        previewQuestionController.question.allowOther &&
        previewQuestionController.question.hasOther;
      const totalOptionsChecked =
        numberOfStandardOptionsChecked + (isOtherOptionChecked ? 1 : 0);

      return (
        totalOptionsChecked >=
        previewQuestionController.question.maximumSelectableOptions
      );
    };

    function prepareQuestion() {
      const question = previewQuestionController.question;
      previewQuestionController.tooltip = descriptionFilter(question.tooltips);
      previewQuestionController.language = getSuggestedLanguage();

      prepareOptions();

      if (_.startsWith(question.questionType, "MATRIX")) {
        previewQuestionController.matrixWidth = getMatrixWidth();
      } else if (question.questionType === "SINGLE_LINE_NUMBER") {
        if (angular.isDefined(question.characterLimit)) {
          previewQuestionController.maxNumber =
            Math.pow(10, question.characterLimit) - 1;
        }
      } else if (question.questionType === "MEMBER_LIST") {
        previewQuestionController.members = getMembers();
        previewQuestionController.membersPerType = _.groupBy(
          previewQuestionController.members,
          "type.id"
        );
        previewQuestionController.disabled = true;
        if (angular.isUndefined(previewQuestionController.question.answer)) {
          sendMembers();
        }
      }

      if (question.questionType) {
        previewQuestionController.templateUrl =
          (question.questionType || "").toLowerCase() + ".html";
        previewQuestionController.differentPrevious = isDifferent(
          previewQuestionController.previous
        );
        previewQuestionController.differentNext = isDifferent(
          previewQuestionController.next
        );
        previewQuestionController.last = angular.isUndefined(
          previewQuestionController.next
        );
      }
    }

    function getSuggestedLanguage() {
      let supported = _(previewQuestionController.question.labels || [])
        .filter((label) => !_.isEmpty(label.value))
        .map("language")
        .value();

      let current = language.get();
      if (_.includes(supported, current)) {
        return current;
      } else {
        return _.head(supported) || current;
      }
    }

    function prepareOptions() {
      const question = previewQuestionController.question;
      previewQuestionController.options = buildOptions(question);
      updateQuestion(question);
    }

    function buildOptions(question) {
      const options = getOptions(question);
      const values = _(options).map("value").uniq().value();

      const results = _.map(values, (value) => {
        let option = _.find(options, {
          value: value,
          language: previewQuestionController.language
        });

        // When the option is not available in the preview language, use another language
        if (angular.isUndefined(option)) {
          option = _.find(options, { value: value });
        }

        return option;
      });

      return sortOptions(results, question);
    }

    function getOptions(question) {
      let options = angular.copy(question.options);
      const optionTemplate = question.optionTemplate;
      if (_.isObject(question.optionTemplate)) {
        options = angular.copy(optionTemplate.options);
      }
      return _.sortBy(options, ["sequence", "language"]);
    }

    function sortOptions(options, question) {
      if (question.randomOrder === true) {
        return _.shuffle(options);
      } else {
        return _.sortBy(options, "sequence");
      }
    }

    function updateQuestion(question) {
      _.each(previewQuestionController.options, (option) => {
        updateOptionChecked(question, option);
      });

      question.checked = _.some(previewQuestionController.options, {
        checked: true
      });

      if (question.allowOther) {
        const other = getOther(question);
        question.hasOther = angular.isDefined(other);
        question.other = other;
      }
    }

    function updateOptionChecked(question, option) {
      if (_.isArray(question.answer)) {
        option.checked = _.includes(question.answer, option.value);
      } else {
        option.checked = question.answer === option.value;
      }
    }

    function getAnswers(question) {
      if (_.isArray(question.answer)) {
        return question.answer;
      }

      return [question.answer];
    }

    function getOther(question) {
      const answers = getAnswers(question);
      return _.find(answers, (answer) => {
        return !_.some(previewQuestionController.options, { value: answer });
      });
    }

    function isDifferent(other) {
      if (angular.isUndefined(other) || other.randomOrder === true) {
        return true;
      }

      return !isEqualQuestion(previewQuestionController.question, other);
    }

    function getMatrixWidth() {
      return 100 / previewQuestionController.options.length;
    }

    function isEqualQuestion(left, right) {
      let equal = false;
      if (left.questionType === right.questionType) {
        const leftValues = getOptionValues(left);
        const rightValues = getOptionValues(right);
        equal = _.isEqual(leftValues, rightValues);
      }
      return equal;
    }

    function getOptionValues(question) {
      let options = getOptions(question);
      return _(options).sortBy("sequence").map("value").uniq().value();
    }

    //
    // Members
    //

    previewQuestionController.onMemberSelect = function (member) {
      member.active = member.active !== true;
      sendMembers();
    };

    function sendMembers() {
      previewQuestionController.question.answer = _(
        previewQuestionController.members
      )
        .filter({ active: true })
        .map("id")
        .value();

      sendAnswer();
    }

    function getMembers() {
      if (angular.isUndefined(previewQuestionController.blocks)) {
        return [];
      }

      const question = previewQuestionController.question;
      const isMemberVisible = memberChecker.isVisible(question);

      return _(previewQuestionController.blocks)
        .map("member")
        .filter(angular.isDefined)
        .uniq()
        .map((member) => {
          const item = angular.copy(member);
          const person = memberService.buildPerson(member);
          item.active = isMemberVisible(member);
          item.displayName = person.displayName;
          return item;
        })
        .sortBy("displayName")
        .value();
    }

    //
    // Answering
    //

    previewQuestionController.answer = function (question, value) {
      updateAnswer(value);
      sendAnswer();
      $scope.$apply();
    };

    function updateAnswer(value) {
      const current = previewQuestionController.question;
      const type = questionType.getProperties(current.questionType);

      if (type.store !== false) {
        if (type.multiple) {
          current.answer = current.answer || [];

          const index = _.indexOf(current.answer, value);
          if (index >= 0) {
            current.answer.splice(index, 1);
          } else {
            // Override previous 'other' value
            const options = _.map(previewQuestionController.options, "value");
            if (!_.includes(options, value)) {
              _.remove(
                current.answer,
                (answer) => !_.includes(options, answer)
              );
            }

            current.answer.push(value);
          }
        } else {
          current.answer = value;
        }
      }

      updateQuestion(current);
    }

    function sendAnswer() {
      if (previewQuestionController.onAnswer) {
        previewQuestionController.onAnswer({
          question: previewQuestionController.question,
          value: previewQuestionController.question.answer
        });
      }
    }
  }
});
