"use strict";

/**
 * @ngdoc service
 * @name diceApp.filter
 * @description
 * # answerOptions
 * filterStore in the diceApp.
 */
angular.module("diceApp").factory("filterStore", function (LocalStorage) {
  const FILTER_KEY = "LIST_FILTER_KEY";

  /**
   * Filter instance.
   */
  let filterStore = get();

  function get() {
    const json = LocalStorage.get(FILTER_KEY);
    if (_.isNull(json)) {
      return undefined;
    } else {
      return angular.fromJson(json);
    }
  }

  function store() {
    LocalStorage.set(FILTER_KEY, angular.toJson(filterStore));
  }

  return {
    setFilter,
    setFilters,
    getFilters,
    getFilter
  };

  /**
   * Save the filter values for list.
   * @param listKey
   * @param filterValue
   */
  function setFilter(listKey, filterValue) {
    filterStore[listKey] = filterValue;
    store();
  }

  /**
   * Save the filter
   * @param filters
   */
  function setFilters(filter) {
    filterStore = filter;
    store();
  }

  /**
   * Get the filter values for list
   * @param listKey
   */
  function getFilter(listKey) {
    return filterStore[listKey];
  }

  /**
   * Get the filter store
   */
  function getFilters() {
    return filterStore;
  }
});
