"use strict";

angular.module("diceApp").component("diceDownloadButton", {
  bindings: {
    name: "@?",
    headers: "<?",
    getRows: "&?",
    size: "<?"
  },
  templateUrl: "es6/query/download.button.html",
  controllerAs: "downloadButtonController",
  controller: function (Query, translateFilter) {
    const downloadButtonController = this;

    downloadButtonController.downloadCsv = function () {
      getData((report) => {
        Query.csv(downloadButtonController.name, report.rows, report.headers);
      });
    };

    downloadButtonController.downloadExcel = function () {
      getData((report) => {
        Query.excel(downloadButtonController.name, report.rows, report.headers);
      });
    };

    function getData(onSuccess) {
      return getReport((report) => {
        const rows = _.map(report.rows, (row) => {
          if (row.data) {
            return row.data;
          }
          return row;
        });

        const headers = _(report.headers)
          .filter({ visible: true })
          .map((header) => {
            return _.extend(header, {
              name: translateFilter(header.name)
            });
          })
          .value();

        return onSuccess({ rows, headers });
      });
    }

    function getReport(onSuccess) {
      const promise = fetchRows(1).then((rows) => {
        return {
          rows,
          headers: downloadButtonController.headers
        };
      });

      downloadButtonController.loading = true;
      return promise.then(onSuccess).finally(() => {
        downloadButtonController.loading = false;
        delete downloadButtonController.progress;
      });
    }

    function fetchRows(page) {
      return downloadButtonController
        .getRows({
          page
        })
        .then((data) => {
          let result = data;

          const paged = angular.isDefined(data.last);
          if (paged) {
            result = data.content;

            downloadButtonController.progress = {
              current: data.number * data.size,
              total: data.totalElements
            };

            if (data.last === false) {
              return fetchRows(page + 1).then((children) => {
                return _.concat(result, children);
              });
            }
          }

          return result;
        });
    }
  }
});
