"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:CustomerStyle
 * @description Applies customer styling to the page.
 */
angular.module("diceApp").component("customerStyle", {
  bindings: {
    type: "<?"
  },
  templateUrl: "es6/base/app-owner/customer.style.html",
  transclude: true,
  controllerAs: "customerStyleController",
  controller: function ($q, language, Customer, EvaluationType) {
    const customerStyleController = this;

    customerStyleController.$onInit = function () {
      setStyles();

      customerStyleController.onLanguage = language.onChange(setClasses);
      setClasses();
    };

    customerStyleController.$onDestroy = function () {
      customerStyleController.onLanguage();
    };

    function setStyles() {
      const typeId = getTypeId();

      return $q
        .all([Customer.load(), EvaluationType.getStyle(typeId)])
        .then(([customer, type]) => {
          customerStyleController.customerStyle = customer.style;
          customerStyleController.typeStyle = type.style;
        });
    }

    function getTypeId() {
      if (_.isObject(customerStyleController.type)) {
        return _.result(customerStyleController.type, "id");
      }

      return customerStyleController.type;
    }

    function setClasses() {
      customerStyleController.classes = getClasses();
    }

    function getClasses() {
      let classes = [];

      const locale = language.get();
      if (locale) {
        classes.push(`customer-container-lang-${_.lowerCase(locale)}`);
      }

      const setXl = _.get(
        customerStyleController.type,
        "sidebarNavigation",
        false
      );
      if (setXl) {
        classes.push("customer-container-xl");
      }

      return classes;
    }
  }
});
