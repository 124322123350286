"use strict";

angular.module("diceApp").component("diceHeader", {
  templateUrl: "es6/base/layout/header.html",
  controllerAs: "headerController",
  controller: function (
    authentication,
    message,
    translateFilter,
    $q,
    $scope,
    $state,
    $rootScope,
    $window,
    Parameter,
    PersonSubstitute,
    Organization,
    Customer,
    $uibModal
  ) {
    const headerController = this;

    headerController.isAuthenticated = authentication.isAuthenticated;
    headerController.hasRole = authentication.hasRole;

    headerController.$onInit = function () {
      $q.all([Customer.load(), Parameter.load()]).then(([customer]) => {
        headerController.customerStyle = customer.style;
        headerController.environment = $rootScope.ENV;
        headerController.showProductSelector = Parameter.getParameterAsBoolean(
          "show.product_selector"
        );
        if (headerController.showProductSelector) {
          setOrganization();
        }
      });

      setCurrent();
      authentication.subscribe(setCurrent);
    };

    function setOrganization() {
      return Organization.get().$promise.then((organization) => {
        headerController.organization = organization;
        headerController.user = {
          appPermissions: organization.enabledProducts,
          scopes: organization.enabledProducts
        };
      });
    }

    // Load substitutes when logging in or switching users
    function setCurrent() {
      if (authentication.isAuthenticated()) {
        headerController.current = authentication.getCurrent();
        loadSubstitutes();
      } else {
        delete headerController.current;
      }
    }

    function loadSubstitutes() {
      const current = authentication.getCurrent();
      const personId = _.get(current, "id");

      if (angular.isDefined(personId)) {
        PersonSubstitute.available({
          personId: personId
        }).$promise.then((substitutes) => {
          headerController.substitutes = substitutes;
        });
      } else {
        headerController.substitutes = [];
      }
    }

    headerController.substitute = function (person) {
      authentication.become(person);
    };

    headerController.restore = function () {
      authentication.restore();
    };

    headerController.changePassword = function () {
      $uibModal.open({
        template: `
            <change-password-modal
              modal="modalController.modal"
              person="modalController.person">
            </change-password-modal>
          `,
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.modal = $uibModalInstance;
          modalController.person = authentication.getCurrent();
        }
      });
    };

    headerController.logout = function () {
      authentication.logout();
    };
  }
});
