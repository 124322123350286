"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:newsWidget
 * @description
 * newsWidget Welcome widget.
 */
angular.module("diceApp").component("newsWidget", {
  templateUrl: "es6/dashboard/news.widget.html",
  controllerAs: "newsWidgetController",
  controller: function (News, language, descriptionFilter) {
    const newsWidgetController = this;

    newsWidgetController.$onInit = function () {
      loadData();
      newsWidgetController.onLanguage = language.onChange(loadData);
    };

    newsWidgetController.$onDestroy = function () {
      newsWidgetController.onLanguage();
    };

    function loadData() {
      News.today().$promise.then((news) => {
        newsWidgetController.news = _.map(news, (it) => {
          return _.extend(it, {
            title: descriptionFilter(it.titles),
            message: descriptionFilter(it.messages)
          });
        });
      });
    }
  }
});
