"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:EvaluationList
 * @description
 * EvaluationList Shows the evaluations in list.
 */
angular.module("diceApp").component("diceEvaluationParticipate", {
  bindings: {
    evaluationId: "@",
    session: "@"
  },
  templateUrl: "es6/participate/participate.html",
  controllerAs: "participationController",
  controller: function (
    Participate,
    Block,
    evaluationPreparator,
    requestQueue,
    translateFilter,
    Parameter,
    $state,
    $window,
    $q
  ) {
    const participationController = this;

    participationController.$onInit = function () {
      loadData();

      Parameter.load().then(() => {
        if (
          Parameter.getParameterAsBoolean("show.alert.close.evaluation", false)
        ) {
          angular.element($window).on("beforeunload", (event) => {
            event.preventDefault();
            event.returnValue = translateFilter(
              "Page.Participate.Page.AlertMessage"
            );
            return event.returnValue;
          });
        }
      });
    };

    participationController.$onDestroy = function () {
      if (
        Parameter.getParameterAsBoolean("show.alert.close.evaluation", false)
      ) {
        angular.element($window).off("beforeunload");
      }
    };

    participationController.reload = function () {
      loadData();
    };

    participationController.answer = function (question, value) {
      requestQueue.push(
        Participate.answer,
        {
          evaluationId: participationController.evaluationId
        },
        {
          question: question.id,
          values: _.isArray(value) ? value : [value],
          participation: participationController.participation
        }
      );
    };

    participationController.complete = function () {
      participationController.loading = true;

      Participate.complete(
        {
          evaluationId: participationController.evaluationId
        },
        participationController.participation
      ).$promise.then(function (result) {
        if (result.targetUrl) {
          $window.location = result.targetUrl;
        } else {
          $state.go("dashboard");
          participationController.loading = false;
        }
      });
    };

    function loadData() {
      participationController.loading = true;

      $q.all([
        Participate.answers({
          evaluationId: participationController.evaluationId,
          session: participationController.session
        }).$promise,
        Block.query({
          evaluationId: participationController.evaluationId
        }).$promise
      ])
        .then(([result, blocks]) => {
          participationController.participation = result.participation;
          participationController.tags = result.tags;
          result.evaluation.blocks = blocks;

          if (result.targetUrl) {
            $window.location = result.targetUrl;
          } else {
            participationController.evaluation = evaluationPreparator.prepare(
              result.evaluation,
              result.answers
            );
          }
        })
        .finally(() => {
          participationController.loading = false;
        });
    }
  }
});
