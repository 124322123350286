"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:participantProgress
 * @description
 * participantProgress Shows the participant progress.
 */
angular.module("diceApp").component("participantProgress", {
  bindings: {
    progress: "<"
  },
  templateUrl: "es6/participate/participant.progress.html",
  controllerAs: "participantProgressController",
  controller: function ($scope) {
    const participantProgressController = this;

    calculateProgress();
    $scope.$watch("participantProgressController.progress", calculateProgress);

    function calculateProgress() {
      const progress = participantProgressController.progress || {};

      const total = progress.total;
      const started = progress.started;
      const finished = progress.finished;
      const notStarted = total - started - finished;

      participantProgressController.total = total;
      participantProgressController.notStarted = {
        value: notStarted,
        percentage: (notStarted / total) * 100
      };
      participantProgressController.started = {
        value: started,
        percentage: (started / total) * 100
      };
      participantProgressController.finished = {
        value: finished,
        percentage: (finished / total) * 100
      };
    }
  }
});
