"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:scheduleWorkflowList
 * @description
 * scheduleWorkflowList Shows the scheduled workflows.
 */
angular.module("diceApp").component("scheduleWorkflowList", {
  templateUrl: "es6/workflow/schedule/schedule.workflow.list.html",
  controllerAs: "scheduleWorkflowListController",
  controller: function (ScheduleWorkflow, Pageable, Promises) {
    const scheduleWorkflowListController = this;

    scheduleWorkflowListController.$onInit = function () {
      scheduleWorkflowListController.pageable = Pageable.of({
        order: "scheduleDate",
        reverse: false
      });

      scheduleWorkflowListController.scheduleDate = new Date().getTime();
      scheduleWorkflowListController.future = false;
      scheduleWorkflowListController.search();
    };

    scheduleWorkflowListController.search = function () {
      const params = scheduleWorkflowListController.pageable.build({
        scheduleDate: scheduleWorkflowListController.scheduleDate,
        future: scheduleWorkflowListController.future,
        status: scheduleWorkflowListController.status,
        participants: true
      });

      scheduleWorkflowListController.loading = true;
      ScheduleWorkflow.get(params)
        .$promise.then((schedules) => {
          scheduleWorkflowListController.schedules = schedules;
        })
        .finally(() => {
          scheduleWorkflowListController.loading = false;
        });
    };

    scheduleWorkflowListController.retry = function (schedule) {
      retry(schedule)
        .then(() => {
          scheduleWorkflowListController.search();
        })
        .finally(() => {
          scheduleWorkflowListController.loading = false;
        });
    };

    scheduleWorkflowListController.retryAll = function () {
      const requests = _(scheduleWorkflowListController.schedules.content)
        .filter({ status: "SKIPPED" })
        .map((schedule) => () => retry(schedule))
        .value();

      scheduleWorkflowListController.loading = true;
      const retries = Promises.chain(requests);
      retries.$promise
        .then(() => {
          scheduleWorkflowListController.search();
        })
        .finally(() => {
          scheduleWorkflowListController.loading = false;
        });
    };

    function retry(schedule) {
      return ScheduleWorkflow.retry({
        workflowId: schedule.workflow.id,
        evaluationId: schedule.evaluation.id,
        participantId: _.get(schedule.participant, "id")
      }).$promise;
    }
  }
});
