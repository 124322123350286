"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:SessionStorage
 * @description
 * # SessionStorage
 */
angular.module("diceApp").factory("SessionStorage", function (Storage) {
  return Storage.of(sessionStorage);
});
