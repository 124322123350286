"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceHttpLogModal
 * @description
 * diceHttpLogModal Shows the available HTTP log.
 */
angular.module("diceApp").component("diceHttpLogModal", {
  bindings: {
    log: "<",
    instance: "<"
  },
  templateUrl: "es6/base/audit/http.log.modal.html",
  controllerAs: "modalController",
  controller: function () {
    const modalController = this;
    modalController.close = function () {
      modalController.instance.dismiss();
    };
  }
});
