"use strict";

angular.module("diceApp").component("diceTagTypeahead", {
  bindings: {
    tags: "<",
    inputName: "<",
    hideAdd: "<?"
  },
  require: {
    ngModelCtrl: "ngModel"
  },
  templateUrl: "es6/tags/tag.typeahead.html",
  controllerAs: "tagTypeaheadController",
  controller: function () {
    const tagTypeaheadController = this;

    tagTypeaheadController.$onInit = function () {
      tagTypeaheadController.ngModelCtrl.$render = () => {
        setTags();
      };
    };

    tagTypeaheadController.$onChanges = function () {
      setTags();
    };

    function setTags() {
      const currentTags = tagTypeaheadController.ngModelCtrl.$viewValue;
      tagTypeaheadController.tags_ = _(tagTypeaheadController.tags)
        .filter((tag) => !_.includes(currentTags, tag))
        .value();
    }

    tagTypeaheadController.removeTag = function (tag) {
      const tags = angular.copy(tagTypeaheadController.ngModelCtrl.$viewValue);
      _.pull(tags, tag);

      setViewValue(tags);
    };

    tagTypeaheadController.addTag = function (tag, $event) {
      if ($event.target && _.isFunction($event.target.blur)) {
        // Remove focus from input field so that dropdown is not displayed after select
        $event.target.blur();
      }

      const newTag = _.trim(tag);
      if (!_.isEmpty(newTag)) {
        delete tagTypeaheadController.tag;

        const tags =
          angular.copy(tagTypeaheadController.ngModelCtrl.$viewValue) || [];
        tags.push(tag);

        setViewValue(tags);
      }
    };

    function setViewValue(tags) {
      const sorted = _(tags).uniq().sort().value();
      tagTypeaheadController.ngModelCtrl.$setViewValue(sorted);
      setTags();
    }
  }
});
