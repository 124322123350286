"use strict";

angular.module("diceApp").component("assignSubstitutes", {
  bindings: {
    person: "<",
    onCancel: "&",
    onSave: "&"
  },
  templateUrl: "es6/members/substitute/substitute.assign.html",
  controllerAs: "substituteController",
  controller: function ($scope, Person, PersonSubstitute, Pageable) {
    const substituteController = this;

    substituteController.$onInit = function () {
      substituteController.pageable = Pageable.of();
      substituteController.searchTerms = "";
    };

    substituteController.search = function () {
      substituteController.searched = true;

      const params = substituteController.pageable.build({
        text: _.toLower(substituteController.searchTerms)
      });

      Person.search(params)
        .$promise.then((persons) => {
          _.forEach(persons.content, (person) => {
            person.selected = _.some(substituteController.person.substitutes, {
              id: person.id
            });
          });

          substituteController.persons = persons;
        })
        .finally(() => {
          substituteController.searched = false;
        });
    };

    substituteController.select = function (substitute) {
      substituteController.selected = substitute;
    };

    substituteController.confirm = function () {
      PersonSubstitute.store({
        personId: substituteController.person.id,
        substituteId: substituteController.selected.id
      }).$promise.then((substitute) => {
        substituteController.onSave({ substitute });
      });
    };

    substituteController.cancel = function () {
      substituteController.onCancel();
    };
  }
});
