"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:BlockTemplateStates
 * @description
 * # BlockTemplateStates
 * Configures all states for block templates.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider
    .state("block-template-list", {
      url: "/block-templates",
      data: {
        root: true
      },
      template: "<dice-block-template-list></dice-block-template-list>"
    })
    .state("block-template-create", {
      url: "/block-templates/create",
      template: '<dice-block-template block="{}"></dice-block-template>'
    })
    .state("block-template", {
      url: "/block-templates/{id:[0-9]{1,8}}",
      resolve: {
        block: function ($stateParams, BlockTemplate) {
          return BlockTemplate.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "blockTemplateController",
      controller: function ($scope, block) {
        const blockTemplateController = this;
        blockTemplateController.block = block;
      },
      template:
        '<dice-block-template block="blockTemplateController.block"></dice-block-template>'
    })
    .state("block-template-copy", {
      url: "/block-templates/{id:[0-9]{1,8}}/copy",
      resolve: {
        block: function ($stateParams, BlockTemplate) {
          return BlockTemplate.get({
            id: $stateParams.id
          }).$promise;
        }
      },
      controllerAs: "blockTemplateCopyController",
      controller: function ($scope, $filter, block) {
        const blockTemplateCopyController = this;
        const clone = angular.copy(block);
        clone.name = $filter("translate")("Generic.CopyOf") + " " + clone.name;
        clone.titles = _.map(clone.titles, (label) => {
          delete label.id;
          label.value =
            $filter("translate")("Generic.CopyOf") + " " + label.value;
          return label;
        });
        clone.questions.forEach((question) => {
          delete question.id;
        });
        clone.descriptions.forEach((description) => {
          delete description.id;
        });
        blockTemplateCopyController.block = _.omit(clone, ["id"]);
      },
      template:
        '<dice-block-template block="blockTemplateCopyController.block"></dice-block-template>'
    });
});
