"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceTagSelect
 * @description
 * diceTagSelect Select the tags.
 */
angular.module("diceApp").component("diceTagSelect", {
  templateUrl: "es6/tags/tag.select.html",
  controllerAs: "tagSelectController",
  bindings: {
    tags: "<",
    type: "<",
    selected: "<?",
    onSelect: "&"
  },
  controller: function (Element, descriptionFilter) {
    const tagSelectController = this;

    tagSelectController.$onInit = function () {
      Element.query({
        type: tagSelectController.type
      }).$promise.then((elements) => {
        tagSelectController.elements = _(tagSelectController.tags)
          .map((tag) => {
            const element = _.find(elements, { eternalId: tag.name }) || {};

            return _.extend(tag, {
              label: `${tag.value} (${descriptionFilter(element.names) || tag.name})`,
              visible: element.visible !== false,
              sequence: element.sequence
            });
          })
          .filter({ visible: true })
          .sortBy(["sequence", "label"])
          .value();
      });
    };

    tagSelectController.onChange = function () {
      const tag = _.find(tagSelectController.elements, {
        name: tagSelectController.selected
      });

      tagSelectController.onSelect({ tag });
    };
  }
});
