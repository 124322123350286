"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Tags
 * @description
 * # Tags
 * Tags factory of the diceApp
 */
angular.module("diceApp").factory("Tags", function () {
  return { build };

  function build(tags) {
    return _(tags || [])
      .map((tag) => {
        const label = _.capitalize(_.replace(tag.name, "_", " "));
        return _.extend({ label }, tag);
      })
      .filter((tag) => !!tag.value)
      .sortBy("label")
      .value();
  }
});
