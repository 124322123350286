"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceQuestionTemplate
 * @description Manages a question template.
 */
angular.module("diceApp").component("diceQuestionTemplate", {
  bindings: {
    question: "<"
  },
  templateUrl: "es6/templates/question/question.template.html",
  controllerAs: "questionController",
  controller: function (
    $q,
    QuestionTemplate,
    BlockTemplate,
    OptionTemplate,
    language,
    message,
    $state,
    questionType
  ) {
    const questionController = this;

    questionController.$onInit = function () {
      questionController.questionType = questionType;
      loadData();
    };

    function loadData() {
      questionController.loading = true;
      $q.all([
        BlockTemplate.search({
          questionTemplate: questionController.question.id,
          language: language.get(),
          page: 0,
          size: 25,
          sort: "name,ASC"
        }).$promise,
        QuestionTemplate.tags().$promise
      ])
        .then(([blocks, tags]) => {
          questionController.blocks = blocks;
          questionController.tags = tags;
        })
        .finally(() => (questionController.loading = false));
    }

    questionController.save = function (form) {
      const body = mapToForm(questionController.question);
      return QuestionTemplate.store(body).$promise.then(function (result) {
        form.$setPristine();
        if (angular.isUndefined(questionController.question.id)) {
          $state.go("question-template", { id: result.id });
        }
        message.addSuccessLabel("Message.Save.Success");
      });
    };

    function mapToForm(question) {
      const body = angular.copy(question);

      // Ensure no previous value for 'maximumSelectableOptions' remains when switching question types that don't support it.
      if (
        !questionController.questionType.isVisible(
          question.questionType,
          "maximumSelectableOptions"
        )
      ) {
        body.maximumSelectableOptions = null;
      }

      body.optionTemplate = _.result(question, "optionTemplate.id");
      return body;
    }

    questionController.optionsChanged = function (options, templateId) {
      questionController.question.options = options;

      if (!templateId && templateId !== 0) {
        questionController.question.optionTemplate = null;
        return;
      }

      const isOptionTemplateChanged = !_.isEqual(
        _.get(questionController.question, "optionTemplate.id"),
        templateId
      );

      if (isOptionTemplateChanged) {
        OptionTemplate.get({ id: templateId }).$promise.then(
          (optionTemplate) => {
            questionController.question.optionTemplate = optionTemplate;
          }
        );
      } else if (!questionController.question.optionTemplate) {
        questionController.question.optionTemplate = {
          id: templateId
        };
      } else {
        questionController.question.optionTemplate.id = templateId;
      }
    };

    questionController.remove = function () {
      QuestionTemplate.remove(questionController.question.id).$promise.then(
        function () {
          message.addSuccessLabel("Message.Delete.Success");
          $state.go("question-template-list");
        }
      );
    };
  }
});
