"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:personModal
 * @personEdit Edits a person
 */
angular.module("diceApp").component("personModal", {
  bindings: {
    modal: "<",
    person: "<",
    isEditable: "<",
    onSave: "&",
    onDelete: "&"
  },
  templateUrl: "es6/members/person.modal.html",
  controllerAs: "personController",
  controller: function (Person) {
    const personController = this;

    personController.close = function () {
      personController.modal.close();
    };

    personController.save = function () {
      Person.store(personController.person).$promise.then((result) => {
        if (!_.isEmpty(personController.person.password)) {
          Person.updatePassword(
            {
              id: result.id
            },
            {
              password: personController.person.password
            }
          ).$promise.then((updatedPerson) => {
            personController.onSave({ person: updatedPerson });
            personController.close();
          });
        } else {
          personController.onSave({ person: result });
          personController.close();
        }
      });
    };

    personController.remove = function () {
      Person.remove(personController.person.id).$promise.then(function () {
        personController.onDelete({ person: personController.person });
        personController.close();
      });
    };
  }
});
