"use strict";

angular.module("diceApp").filter("fileSize", function (numberFilter) {
  const units = [
    { size: 1000 * 1000, name: "mb" },
    { size: 1000, name: "kb" }
  ];

  return function (bytes) {
    const unit = _.find(units, (it) => bytes >= it.size);
    if (angular.isDefined(unit)) {
      return numberFilter(bytes / unit.size, 0) + unit.name;
    }
    return bytes + " bytes";
  };
});
