"use strict";

angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("news", {
    url: "/news",
    data: {
      root: true
    },
    template: "<news-list></news-list>"
  });
});
