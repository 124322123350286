"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:ScheduledTask
 * @description
 * ScheduledTask Shows the scheduled task form.
 */
angular.module("diceApp").component("workflowEdit", {
  bindings: {
    workflow: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/workflow/workflow.edit.modal.html",
  controllerAs: "workflowEditController",
  controller: function (Workflow, NotificationTemplate, StatusTransition) {
    const workflowEditController = this;

    workflowEditController.$onInit = function () {
      NotificationTemplate.query().$promise.then(function (templates) {
        workflowEditController.notificationTemplates = _.filter(
          templates,
          hasEvaluationType
        );
      });

      StatusTransition.query().$promise.then(function (transitions) {
        workflowEditController.statusTransitions = _.filter(
          transitions,
          hasEvaluationType
        );
      });
    };

    workflowEditController.close = function () {
      workflowEditController.modal.close();
    };

    workflowEditController.save = function () {
      Workflow.store(workflowEditController.workflow).$promise.then(
        (result) => {
          workflowEditController.onSave(result);
          workflowEditController.close();
        }
      );
    };

    function hasEvaluationType(item) {
      const evaluationType = Math.max(
        workflowEditController.workflow.evaluationType,
        -1
      );
      return item.evaluationType === evaluationType;
    }
  }
});
