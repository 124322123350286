"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:newsEditModal
 * @description Shows the edit modal for news.
 */
angular.module("diceApp").component("newsEditModal", {
  bindings: {
    news: "<",
    onClose: "&",
    onSave: "&"
  },
  templateUrl: "es6/news/news.edit.modal.html",
  controllerAs: "newsEditController",
  controller: function () {
    const newsEditController = this;

    newsEditController.close = function () {
      newsEditController.onClose();
    };

    newsEditController.save = function () {
      return newsEditController.onSave({
        news: newsEditController.news
      });
    };
  }
});
