"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:EvaluationTypeStates
 * @description Configures the evaluation type states
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider.state("evaluation-type-list", {
    url: "/evaluation-types",
    data: {
      root: true
    },
    resolve: {
      evaluationTypes: function (EvaluationType) {
        return EvaluationType.query().$promise;
      }
    },
    controllerAs: "evaluationTypeListController",
    controller: function (evaluationTypes) {
      const evaluationTypeListController = this;
      evaluationTypeListController.evaluationTypes = evaluationTypes;
    },
    template: `
      <dice-evaluation-type-list
        evaluation-types="evaluationTypeListController.evaluationTypes">
      </dice-evaluation-type-list>`
  });
});
