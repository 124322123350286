"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:statusTypeSelect
 * @description
 * The statusTypeSelect directive.
 */
angular.module("diceApp").component("statusTypeSelect", {
  templateUrl: "es6/status/type/status.type.select.html",
  bindings: {
    evaluationType: "<"
  },
  require: {
    ngModel: "ngModel"
  },
  controllerAs: "statusTypeSelectController",
  controller: function (StatusType, $scope) {
    const statusTypeSelectController = this;

    statusTypeSelectController.$onInit = function () {
      loadData();
      $scope.$watch("statusTypeSelectController.evaluationType", loadData);
      $scope.$watch(
        "statusTypeSelectController.ngModel.$modelValue",
        function () {
          statusTypeSelectController.value =
            statusTypeSelectController.ngModel.$modelValue;
        }
      );
    };

    statusTypeSelectController.onChange = function () {
      statusTypeSelectController.ngModel.$setViewValue(
        statusTypeSelectController.value
      );
    };

    function loadData() {
      StatusType.query({}).$promise.then(function (statusTypes) {
        statusTypeSelectController.statusTypes = _.filter(statusTypes, {
          evaluationType: statusTypeSelectController.evaluationType
        });
      });
    }
  }
});
