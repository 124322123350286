"use strict";

angular.module("diceApp").component("downloadWidget", {
  templateUrl: "es6/export/download/download.widget.html",
  controllerAs: "downloadWidgetController",
  controller: function (
    $rootScope,
    $interval,
    $uibModal,
    Export,
    DownloadObserver,
    Pageable
  ) {
    const downloadWidgetController = this;

    downloadWidgetController.$onInit = () => {
      downloadWidgetController.minimized = false;
      downloadWidgetController.pageable = Pageable.of({
        order: "created",
        reverse: true
      });

      DownloadObserver.setRefreshCallback(() => {
        downloadWidgetController.restart();
      });

      start();
      downloadWidgetController.onDownload = $rootScope.$on("download", start);
    };

    downloadWidgetController.$onDestroy = () => {
      stop();
      downloadWidgetController.onDownload();
    };

    downloadWidgetController.restart = () => {
      stop();
      start();
    };

    downloadWidgetController.delete = (download) => {
      Export.deleteDownload(download).finally(() => {
        downloadWidgetController.restart();
      });
    };

    downloadWidgetController.minimize = (minized) => {
      downloadWidgetController.minimized = minized;
    };

    downloadWidgetController.open = (download) => {
      $uibModal.open({
        templateUrl: "es6/export/download/download.modal.html",
        controllerAs: "downloadModalController",
        controller: function ($uibModalInstance) {
          const downloadModalController = this;
          downloadModalController.download = download;
          downloadModalController.url =
            downloadWidgetController.downloadUrl(download);
          downloadModalController.close = () => $uibModalInstance.dismiss();
        }
      });
    };

    downloadWidgetController.downloadUrl = (download) => {
      const prefix =
        // eslint-disable-next-line no-undef
        TE_EV_APP.ENV === "development" ? "http://localhost:8080" : "";
      return `${prefix}/api/downloads/${download.id}`;
    };

    function start() {
      if (!isStarted()) {
        load();

        downloadWidgetController.interval = $interval(() => {
          if (isStarted()) {
            load();
          }
        }, 5000);
      }
    }

    function isStarted() {
      return _.isEmpty(downloadWidgetController.interval) === false;
    }

    function stop() {
      if (isStarted()) {
        $interval.cancel(downloadWidgetController.interval);
        downloadWidgetController.interval = null;
      }
    }

    function load() {
      const params = downloadWidgetController.pageable.build();
      return Export.findMine(params).then(({ data }) => {
        downloadWidgetController.page = data;

        const { content } = data;
        const done = !_.some(content, (row) => {
          return row.status === "QUEUED" || row.status === "STARTED";
        });

        if (done) {
          stop();
        }
      });
    }
  }
});
