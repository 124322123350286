"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:EnumSelect
 * @description
 * The EnumSelect directive.
 */
angular.module("diceApp").directive("enumSelect", function (Enums) {
  return {
    restrict: "E",
    templateUrl: "es6/form-elements/enum.select.html",
    replace: true,
    scope: {
      ngModel: "=?",
      ngChange: "=?",
      type: "@",
      sort: "@?"
    },
    link: function (scope, e) {
      e.wrap('<div class="select-box"></div>');
    },
    controller: function ($scope, $filter) {
      $scope.ngModel =
        angular.isUndefined($scope.ngModel) || $scope.ngModel === ""
          ? null
          : $scope.ngModel;
      Enums.query({
        type: $scope.type
      }).$promise.then((enumerations) => {
        const property = $scope.sort !== "false" ? "name" : "ordinal";

        $scope.enumerations = _(enumerations)
          .map((enumeration, $index) => {
            return {
              value: enumeration,
              ordinal: $index,
              name: $filter("enum")(enumeration)
            };
          })
          .orderBy(property)
          .value();
      });
    }
  };
});
