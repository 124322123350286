"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceMemberType
 * @description Manages a member type.
 */
angular.module("diceApp").component("diceMemberType", {
  bindings: {
    memberType: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/members/type/member.type.modal.html",
  controllerAs: "memberTypeController",
  controller: function (MemberType) {
    const memberTypeController = this;

    memberTypeController.close = function () {
      memberTypeController.modal.close();
    };

    memberTypeController.save = function () {
      MemberType.store(memberTypeController.memberType).$promise.then(
        function (result) {
          memberTypeController.onSave(result);
          memberTypeController.close();
        }
      );
    };
  }
});
