"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:Script
 * @description
 * The Script service.
 */
angular.module("diceApp").factory("Script", function (DiceResource) {
  return DiceResource("/scripts", {
    queue: {
      url: "/scripts/queue",
      method: "GET",
      isArray: true
    },
    clearQueue: {
      url: "/scripts/queue",
      method: "DELETE",
      isArray: true
    }
  });
});
