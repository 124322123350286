"use strict";

angular.module("diceApp").component("diceImageUpload", {
  require: {
    ngModel: "ngModel"
  },
  bindToController: true,
  templateUrl: "es6/base/image/image.edit.html",
  controllerAs: "imageEditController",
  controller: function ($scope, $cookies, Upload, resizeService, $q) {
    const imageEditController = this;

    imageEditController.$onInit = function () {
      $scope.$watch("imageEditController.ngModel.$modelValue", function () {
        imageEditController.image = imageEditController.ngModel.$modelValue;
      });

      $scope.$watch("imageEditController.imageFile", function () {
        imageEditController.upload = {};
      });
    };

    imageEditController.uploadImage = function () {
      readFileInput(imageEditController.imageFile).then((base64Img) => {
        resizeService.createImage(base64Img).then(function (image) {
          let resizedImage = resizeService.resizeImageWidthHeight(
            image,
            // eslint-disable-next-line no-undef
            TE_EV_APP.resizeImageWidth,
            null,
            5,
            "image/png"
          );

          Upload.upload({
            url: "/api/images/upload",
            data: {
              file: Upload.dataUrltoBlob(
                resizedImage,
                imageEditController.imageFile.name + ".png"
              ),
              headers: { "X-XSRF-TOKEN": $cookies.get("XSRF-TOKEN") }
            }
          }).then(
            function (response) {
              imageEditController.ngModel.$setViewValue(response.data.id);

              imageEditController.upload.isSucces = true;
              imageEditController.upload.isUploading = false;
            },
            function () {
              imageEditController.upload.isError = true;
              imageEditController.upload.isUploading = false;
            },
            function (evt) {
              imageEditController.upload.isUploading = true;
              imageEditController.upload.progress = parseInt(
                (100.0 * evt.loaded) / evt.total
              );
            }
          );
        });
      });
    };

    imageEditController.remove = function () {
      imageEditController.ngModel.$setViewValue(null);
      if (angular.isDefined(imageEditController.imageFile)) {
        imageEditController.imageFile = null;
      }
    };

    function readFileInput(file) {
      var deferred = $q.defer();

      var reader = new FileReader();
      reader.onload = function (e) {
        deferred.resolve(e.target.result);
      };
      reader.readAsDataURL(file);
      return deferred.promise;
    }
  }
});
