"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:PreviewButton
 * @description
 * The PreviewButton service.
 */
angular.module("diceApp").directive("previewButton", function () {
  return {
    restrict: "E",
    scope: {
      btnType: "@?",
      btnSize: "@?",
      btnIconOnly: "=?",
      btnContext: "@?"
    },
    link: function (scope) {
      scope.btnContext = scope.btnContext || "default";
    },
    templateUrl: "es6/base/forms/buttons/preview.button.html"
  };
});
