"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:mailWidget
 * @description
 * mailWidget Widget showing the total mails send per day.
 */
angular.module("diceApp").component("mailWidget", {
  templateUrl: "es6/notifications/logs/mail.widget.html",
  controllerAs: "mailWidgetController",
  controller: function (MailLog, authentication) {
    const mailWidgetController = this;

    mailWidgetController.$onChanges = function () {
      mailWidgetController.visible = authentication.hasRole([
        "SYSTEM_ADMIN",
        "ADMIN"
      ]);

      if (mailWidgetController.visible) {
        loadData();
      } else {
        delete mailWidgetController.days;
      }
    };

    function loadData() {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);

      mailWidgetController.loading = true;
      MailLog.reports({
        startDate: startDate.getTime(),
        limit: 5
      })
        .$promise.then((days) => {
          mailWidgetController.days = days;
        })
        .finally(() => {
          mailWidgetController.loading = false;
        });
    }
  }
});
