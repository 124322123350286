"use strict";

import googleLogo from "../../images/google.png";
import surfconextLogo from "../../images/surfconext.svg";
import timeeditLogo from "../../images/timeedit-squared.svg";
import keyIcon from "../../images/key-round.png";

/**
 * @ngdoc function
 * @name diceApp.component:loginButton
 * @description
 * # loginButton
 * Button used for logging in
 */
angular.module("diceApp").component("loginButton", {
  bindings: {
    registration: "<"
  },
  templateUrl: "es6/security/login.button.html",
  controllerAs: "loginButtonController",
  controller: function () {
    const loginButtonController = this;

    loginButtonController.$onInit = function () {
      loginButtonController.googleLogoUrl = googleLogo;
      loginButtonController.surfconextLogoUrl = surfconextLogo;
      loginButtonController.timeeditLogoUrl = timeeditLogo;
      loginButtonController.keyIconUrl = keyIcon;
    };
  }
});
