"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:DescriptionInput
 * @description
 * The DescriptionInput directive.
 */
angular.module("diceApp").component("descriptionInput", {
  templateUrl: "es6/i18n/description.input.html",
  bindings: {
    type: "@?",
    editable: "<",
    required: "@?",
    name: "@?",
    placeholder: "@?"
  },
  require: {
    ngModel: "ngModel"
  },
  bindToController: true,
  controllerAs: "descriptionInputController",
  controller: function (language, $scope, systemPropertyStore) {
    const descriptionInputController = this;

    descriptionInputController.$onInit = function () {
      descriptionInputController.descriptions = [];
      descriptionInputController.id = Math.random().toString(36).substring(7);
      descriptionInputController.htmlEditorOptions = [
        systemPropertyStore.findValue("htmlEditor", [])
      ];

      $scope.$watch(
        "descriptionInputController.ngModel.$modelValue",
        function () {
          const current = descriptionInputController.ngModel.$modelValue || [];
          descriptionInputController.descriptions = _.map(
            language.supported,
            function (supported) {
              let model = _.find(current, { language: supported });
              if (angular.isUndefined(model)) {
                model = {
                  language: supported,
                  value: ""
                };
              }
              return model;
            }
          );
        }
      );
    };

    descriptionInputController.update = function () {
      _.each(descriptionInputController.descriptions, (description) => {
        description.value = decorate(description.value);
      });

      descriptionInputController.ngModel.$setViewValue(
        descriptionInputController.descriptions
      );
    };

    function decorate(value) {
      if (!_.isEmpty(value)) {
        value = value.replace('target=""', 'target="blank"');
      }
      return value;
    }
  }
});
