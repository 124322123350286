"use strict";

/**
 * @ngdoc service
 * @name diceApp.filter:Enum
 * @description
 * The Enum filter.
 */
angular.module("diceApp").filter("enum", function ($filter) {
  function enumToString(value) {
    if (_.isArray(value)) {
      return _(value)
        .map(enumToString)
        .filter((val) => !!val)
        .uniq()
        .sort()
        .join(", ");
    } else if (!value) {
      return "";
    }

    return $filter("translate")("Enum." + value);
  }

  return enumToString;
});
