"use strict";

angular.module("diceApp").component("securedOperationSelect", {
  require: {
    ngModel: "ngModel"
  },
  bindings: {
    // Type of operations to show. Must be either 'EVALUATION_MEMBER_RELATION' or 'EVALUATION_STATUS'
    type: "@",
    inputId: "@?",
    isRequired: "<?"
  },
  templateUrl: "es6/security/secured.operation.select.html",
  controllerAs: "selectOperationController",
  controller: function ($scope, $log, translateFilter, SecuredOperation) {
    const selectOperationController = this;

    selectOperationController.$onInit = function () {
      selectOperationController.type =
        selectOperationController.type || "EVALUATION_MEMBER_RELATION";

      SecuredOperation.query().$promise.then((operations) => {
        selectOperationController.operations = _.sortBy(
          operations,
          selectOperationController.getDisplayName
        );
      });

      $scope.$watch("selectOperationController.ngModel.$modelValue", () => {
        selectOperationController.value =
          selectOperationController.ngModel.$modelValue;
      });
    };

    selectOperationController.getDisplayName = function (operation) {
      return translateFilter(`Enum.${operation}`);
    };

    selectOperationController.onChange = function () {
      selectOperationController.ngModel.$setViewValue(
        selectOperationController.value
      );
    };
  }
});
