"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:evaluationCopier
 * @description Copies an evaluation as a new evaluation.
 */
angular.module("diceApp").factory("evaluationCopier", function () {
  return { copy };

  function copy(evaluation) {
    let blocks = _.get(evaluation, "blocks", []);

    blocks = _(blocks).map(convertToBlock).value();

    return {
      blocks
    };
  }

  function convertToBlock(block) {
    return {
      template: block.template,
      member: convertToMember(block.member),
      sequence: block.sequence,
      visible: block.visible,
      active: block.active,
      manual: block.manual,
      defaultSelected: block.defaultSelected,
      required: block.required,
      titles: _.map(block.titles, omitId),
      descriptions: _.map(block.descriptions, omitId),
      questions: _.map(block.questions, convertToQuestion),
      filters: _.map(block.filters, omitId),
      type: block.type
    };
  }

  function convertToMember(member) {
    if (!_.isObject(member)) {
      return undefined;
    }

    return {
      personId: _.get(member, "person.id"),
      typeId: _.get(member, "type.id"),
      sequence: member.sequence,
      manual: member.manual,
      disabled: member.disabled
    };
  }

  function omitId(object) {
    return _.omit(object, "id");
  }

  function convertToQuestion(question) {
    return {
      questionType: question.questionType,
      template: question.template,
      questionNumber: question.questionNumber,
      externalId: question.externalId,
      autoSelectQuestion: question.autoSelectQuestion,
      labels: _.map(question.labels, omitId),
      tooltips: _.map(question.tooltips, omitId),
      sequence: question.sequence,
      required: question.required,
      options: _.map(question.options, convertToOption),
      characterLimit: question.characterLimit,
      optionTemplate: question.optionTemplate,
      image: question.image
    };
  }

  function convertToOption(option) {
    return {
      language: option.language,
      label: option.label,
      value: option.value,
      sequence: option.sequence,
      externalId: option.externalId,
      calculate: option.calculate
    };
  }
});
