"use strict";

angular
  .module("diceApp")
  .factory("authenticationErrorInterceptor", function (message, $injector, $q) {
    return {
      responseError: function (response) {
        if (response.status === 401) {
          const location = $injector.get("$location").url();
          const state = $injector.get("$state");
          const current = angular.copy(state.current);

          if (
            !(
              _.startsWith(location, "/login") ||
              _.startsWith(location, "/support") ||
              _.startsWith(location, "/participate") ||
              _.result(current, "data.public") === true
            )
          ) {
            message.addInfo("Please log in with your username/password.");
            state.go("login", {
              successUrl: encodeURIComponent(location)
            });
          }
        } else if (response.status === 403) {
          message.addWarning("You have no access to this page.");
        }

        return $q.reject(response);
      }
    };
  });
