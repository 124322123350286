"use strict";

/**
 * Strips any HTML content from a string.
 * Usage goes as follows:
 * {{ 'text' | stripHtml }}
 */
angular.module("diceApp").filter("stripHtml", function () {
  return function (text) {
    if (!text) {
      return "";
    }

    return text.replace(/<[^>]*>?/gm, "");
  };
});
