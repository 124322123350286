"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:Parameter
 * @description
 * The Parameter service.
 */
angular.module("diceApp").factory("Parameter", function (DiceResource) {
  const resource = DiceResource("/parameter");

  function isEmpty(value) {
    return value === null || angular.isUndefined(value) || value === "";
  }

  return _.extend(resource, {
    findValue: function (parameters, name, defaultValue) {
      const result = _.find(parameters, {
        name: name
      });
      if (result) {
        return result.value;
      } else {
        return defaultValue || "";
      }
    },
    getParameterValue: function (name, defaultValue) {
      const parameter = resource.getFromStore({ name });
      const value = _.get(parameter, "value");
      if (!isEmpty(value)) {
        return value;
      }
      return isEmpty(defaultValue)
        ? _.get(parameter, "defaultValue")
        : defaultValue;
    },
    getParameterAsList: function (name) {
      const value = resource.getParameterValue(name);
      return _.split(value, ",").filter((v) => !_.isEmpty(v));
    },
    getParameterAsBoolean: function (name, defaultValue) {
      const value = resource.getParameterValue(name, defaultValue);
      return angular.isDefined(value) ? String(value) === "true" : value;
    },
    getParameterAsInt: function (name, defaultValue) {
      const value = resource.getParameterValue(name, defaultValue);
      return angular.isDefined(value) ? _.parseInt(value) : value;
    }
  });
});
