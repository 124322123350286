"use strict";

angular.module("diceApp").component("backToTop", {
  templateUrl: "es6/base/fixed-header/back.to.top.html",
  controllerAs: "backToTopController",
  controller: function ($anchorScroll) {
    const backToTopController = this;
    backToTopController.scroll = $anchorScroll;
  }
});
