"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:reportHeading
 * @description Shows the heading for the closed questions part of the report.
 */
angular.module("diceApp").component("reportHeading", {
  bindings: {
    member: "<?",
    blockLabel: "@?"
  },
  controllerAs: "reportHeadingController",
  template: `
      <span ng-if="reportHeadingController.member">
        <dice-person-avatar
          view-classes="'m-r-5'"
          person="reportHeadingController.member">
        </dice-person-avatar>
      </span>
      <strong ng-if="reportHeadingController.blockLabel">{{ reportHeadingController.blockLabel }}</strong>
      <span ng-if="reportHeadingController.member">{{ reportHeadingController.member.name }}</span>
    `
});
