"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:EvaluationList
 * @description
 * EvaluationList Shows the evaluations in list.
 */
angular.module("diceApp").component("diceEvaluationList", {
  bindings: {
    periods: "<",
    statuses: "<",
    evaluationTypes: "<"
  },
  templateUrl: "es6/evaluations/evaluation.list.html",
  controllerAs: "listController",
  controller: function (
    Evaluation,
    Query,
    QueryTemplate,
    Pageable,
    Sort,
    $q,
    $scope,
    $timeout,
    authentication,
    language,
    filterStore,
    pinnedRegistry
  ) {
    const listController = this;
    listController.hasRole = authentication.hasRole;

    listController.$onInit = function () {
      listController.filters = getFilters();

      listController.text = _.result(listController.filters, "text", "");
      listController.pageable = Pageable.of({
        order: _.result(listController.filters, "order", "code"),
        reverse: _.result(listController.filters, "reverse"),
        currentPage: _.result(listController.filters, "currentPage"),
        pageSize: _.result(listController.filters, "pageSize")
      });

      $q.all([
        QueryTemplate.getDefault("evaluations"),
        Evaluation.report({
          language: language.get(),
          definition: true
        }).$promise
      ])
        .then(([template, report]) => {
          listController.template = template;
          listController.groups = report.groups;
          listController.columns = report.headers;

          setTemplateColumns(template);
        })
        .finally(() => {
          listController.onLanguage = language.onChange(listController.update);
          listController.update();
        });
    };

    function setTemplateColumns(template) {
      const headers = _.map(template.headers, "code");
      _.forEach(listController.columns, (column) => {
        if (!_.isEmpty(headers)) {
          column.visible = _.includes(headers, column.code);
        }
      });
    }

    function getFilters() {
      let filters = filterStore.getFilters();
      if (angular.isUndefined(filters)) {
        filters = resetFilter();
        filters.showMine = !authentication.hasRole([
          "SYSTEM_ADMIN",
          "ADMIN",
          "EVALUATION_COORDINATOR"
        ]);
      }
      return filters;
    }

    listController.$onDestroy = () => {
      listController.onLanguage();
    };

    function resetFilter() {
      let currentPeriod = findCurrentPeriod();
      let defaultType = findDefaultType();

      filterStore.setFilters({
        status: undefined,
        period: undefined,
        startDate: currentPeriod
          ? currentPeriod.startDate
          : new Date().getTime(),
        endDate: currentPeriod ? currentPeriod.endDate : undefined,
        type: defaultType ? defaultType.id : undefined,
        showMine: false,
        person: undefined,
        memberType: undefined,
        tags: undefined
      });

      return filterStore.getFilters();
    }

    function findCurrentPeriod() {
      return _.find(listController.periods, function (period) {
        return period.startDate <= Date.now() && Date.now() <= period.endDate;
      });
    }

    function findDefaultType() {
      return _.find(listController.evaluationTypes, { defaultSelected: true });
    }

    listController.countFilters = function () {
      const filters = _.omit(listController.filters, [
        "columns",
        "text",
        "order",
        "reverse",
        "pageSize",
        "currentPage"
      ]);
      return _(filters).omitBy(_.isNil).size();
    };

    listController.clearFilters = function () {
      _.forOwn(listController.filters, function (value, key) {
        if (value instanceof Date || new Date(value) instanceof Date) {
          listController.filters[key] = undefined;
        } else {
          listController.filters[key] = "";
        }
      });

      listController.text = "";
      listController.pageable.currentPage = 1;
    };

    listController.update = function () {
      let params = createParams();
      updateFilters();

      listController.searching = true;
      search(params)
        .then((data) => {
          listController.evaluations = data;
        })
        .finally(() => {
          listController.searching = false;
        });
    };

    listController.getRows = function (page) {
      const params = _.extend(createParams(), {
        page
      });

      return search(params, true);
    };

    function updateFilters() {
      const filters = angular.copy(listController.filters);

      const extended = _.extend(filters, {
        text: listController.text,
        order: listController.pageable.order,
        reverse: listController.pageable.reverse,
        pageSize: listController.pageable.pageSize,
        currentPage: listController.pageable.currentPage
      });

      filterStore.setFilters(extended);
    }

    listController.onColumn = function (code, visible) {
      const column = _.find(listController.columns, { code });
      if (angular.isDefined(column)) {
        column.visible = visible;
        listController.update();
      }
    };

    listController.onTemplate = function (template) {
      listController.template = template;
      setTemplateColumns(template);
      QueryTemplate.setDefault("evaluations", template);
    };

    function createParams() {
      return {
        language: language.get(),
        page: listController.pageable.currentPage,
        size: listController.pageable.pageSize,
        sort: createSortOrder()
      };
    }

    function createSortOrder() {
      let order;
      if (listController.pageable.order === "period.year") {
        order = [
          Sort.get(listController.pageable),
          Sort.get({
            order: "period.period",
            reverse: listController.pageable.reverse
          })
        ];
      } else {
        order = Sort.get(listController.pageable);
      }
      return order;
    }

    function search(params, wait) {
      const searchParams = createSearchParams(params);
      return Evaluation.report(searchParams).$promise.then((page) => {
        return Query.ensure(page, listController.columns, wait);
      });
    }

    function createSearchParams(params) {
      return _.chain(params)
        .set("text", _.toLower(listController.text))
        .set("periodId", _.defaultTo(filterStore.getFilter("period"), ""))
        .set("startDate", _.defaultTo(filterStore.getFilter("startDate"), null))
        .set(
          "reminderDate",
          _.defaultTo(filterStore.getFilter("reminderDate"), null)
        )
        .set("endDate", _.defaultTo(filterStore.getFilter("endDate"), null))
        .set("typeId", _.defaultTo(filterStore.getFilter("type"), ""))
        .set("statusId", _.defaultTo(filterStore.getFilter("status"), ""))
        .set(
          "showMine",
          isEmpty(filterStore.getFilter("showMine"))
            ? false
            : filterStore.getFilter("showMine")
        )
        .set("tags", _.defaultTo(filterStore.getFilter("tags"), ""))
        .set("personId", filterStore.getFilter("person"))
        .set("memberTypeId", filterStore.getFilter("memberType"))
        .value();
    }

    listController.getEvaluationsForAction = function () {
      let params = createParams();
      params.size = 9999;

      const evaluationIds = _.map(pinnedRegistry.load(), "id");
      if (_.isEmpty(evaluationIds)) {
        params = createSearchParams(params);
      } else {
        params = _.extend(params, { id: evaluationIds });
      }

      return Evaluation.search(params).$promise.then((data) => {
        return _.map(data.content, buildEvaluationForAction);
      });
    };

    function buildEvaluationForAction(evaluation) {
      return {
        id: evaluation.id,
        code: evaluation.code,
        name: evaluation.name,
        type: evaluation.type.id,
        status: evaluation.status.name
      };
    }

    $scope.$watch("listController.text", function (tmpStr, value) {
      if (tmpStr !== value) {
        $timeout(() => {
          if (
            (tmpStr.length !== 0 && tmpStr === listController.text) ||
            listController.text === ""
          ) {
            listController.update();
          }
        }, 400);
      }
    });

    function isEmpty(value) {
      return angular.isUndefined(value) || value === null || value === "";
    }
  }
});
