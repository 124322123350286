"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Threshold
 * @description
 * # Threshold
 * Threshold resource of the diceApp
 */
angular.module("diceApp").factory("Threshold", function (DiceResource) {
  return DiceResource("/thresholds");
});
