"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:accessRuleListForStatusTypeModal
 * @description Shows a modal to manage access rules for a status type.
 */
angular.module("diceApp").component("accessRuleListForStatusTypeModal", {
  bindings: {
    statusType: "<",
    modal: "<"
  },
  templateUrl: "es6/security/access.rule.list.status.type.modal.html",
  controllerAs: "accessRuleListController",
  controller: function (MemberType, AccessRule, $filter) {
    const accessRuleListController = this;

    accessRuleListController.$onInit = loadData;

    function loadData() {
      accessRuleListController.loading = true;
      AccessRule.query()
        .$promise.then(function (rules) {
          accessRuleListController.rules = _(rules)
            .filter(
              (rule) =>
                !rule.memberType &&
                rule.statusType &&
                rule.statusType.id === accessRuleListController.statusType.id
            )
            .map(function (rule) {
              const result = angular.copy(rule);
              result.operationName = $filter("enum")(rule.operation);
              return result;
            })
            .value();
        })
        .finally(() => (accessRuleListController.loading = false));
    }

    accessRuleListController.toggle = function (rule) {
      accessRuleListController.rule = {
        id: rule.id,
        operation: rule.operation,
        statusType: accessRuleListController.statusType.id
      };
    };

    accessRuleListController.cancel = function () {
      delete accessRuleListController.rule;
    };

    accessRuleListController.close = function () {
      accessRuleListController.modal.close();
    };

    accessRuleListController.remove = function (rule) {
      AccessRule.delete({ id: rule.id }).$promise.then(function () {
        loadData();
      });
    };

    accessRuleListController.save = function (rule, form) {
      AccessRule.store(rule).$promise.then(function () {
        form.$setPristine(false);
        accessRuleListController.cancel();
        loadData();
      });
    };
  }
});
