"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:diceMemberPhoto
 * @description Shows the photo of a member
 */
angular.module("diceApp").component("diceMemberPhoto", {
  templateUrl: "es6/members/member.photo.html",
  bindings: {
    member: "<"
  },
  controllerAs: "photoController",
  controller: function ($scope, memberService) {
    const photoController = this;

    photoController.$onInit = loadData;
    $scope.$watch("photoController.member", loadData);

    function loadData() {
      photoController.person = memberService.buildPerson(
        photoController.member
      );
    }
  }
});
