"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:organisationList
 * @description
 * organisationList Shows the organisations in list.
 */
angular.module("diceApp").component("diceOrganisationList", {
  templateUrl: "es6/organisation/organisation.list.html",
  controllerAs: "organisationListController",
  controller: function (Organisation, Pageable, $uibModal) {
    const organisationListController = this;

    organisationListController.$onInit = function () {
      organisationListController.pageable = Pageable.of({
        sort: "code"
      });

      organisationListController.loadData();
    };

    organisationListController.loadData = function () {
      organisationListController.loading = true;

      const params = organisationListController.pageable.build();
      Organisation.get(params)
        .$promise.then((organisations) => {
          organisationListController.organisations = organisations;
        })
        .finally(() => {
          organisationListController.loading = false;
        });
    };

    organisationListController.create = function () {
      open({});
    };

    organisationListController.edit = function (organisation) {
      const body = angular.copy(organisation);
      open(body);
    };

    function open(organisation) {
      $uibModal.open({
        template: `
              <organisation-modal
                organisation="modalController.organisation"
                instance="modalController.instance"
                on-save="modalController.onSave()">
              </organisation-modal>
            `,
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.organisation = organisation;
          modalController.instance = $uibModalInstance;
          modalController.onSave = organisationListController.loadData;
        }
      });
    }

    organisationListController.remove = function (organisation) {
      Organisation.remove(organisation.id).$promise.then(() => {
        organisationListController.loadData();
      });
    };
  }
});
