"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:BlockTemplate
 * @description
 * # BlockTemplate
 * BlockTemplate resource of the diceApp
 */
angular.module("diceApp").factory("BlockTemplate", function (DiceResource) {
  return DiceResource("/block-templates", {
    search: {
      url: "/block-templates/search",
      method: "GET",
      isArray: false
    }
  });
});
