"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:notificationConfirmModal
 * @description Confirms the notification to be sent.
 */
angular.module("diceApp").component("notificationConfirmModal", {
  bindings: {
    notification: "<",
    callback: "<",
    modal: "<"
  },
  require: "ngModel",
  templateUrl: "es6/notifications/notification.confirm.modal.html",
  controllerAs: "confirmController",
  controller: function () {
    const confirmController = this;
    confirmController.dismiss = function () {
      confirmController.modal.close();
    };
    confirmController.perform = function () {
      confirmController.callback();
      confirmController.modal.close();
    };
  }
});
