"use strict";

/**
 * @ngdoc service
 * @name diceApp.factory:Sort
 * @description
 * The Sort service.
 */
angular.module("diceApp").factory("Sort", function () {
  function get(parameters) {
    const { order, reverse } = parameters;

    if (_.isEmpty(order)) {
      return "";
    }

    return `${order},${reverse ? "desc" : "asc"}`;
  }

  function parse(sort) {
    let order = "";
    let reverse = false;

    if (!_.isEmpty(sort)) {
      const fragments = sort.split(",");
      order = fragments[0];
      reverse = fragments[1] === "desc";
    }

    return { order, reverse };
  }

  return { get, parse };
});
