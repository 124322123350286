"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:StatusTransition
 * @description
 * # StatusTransition
 * StatusTransition resource of the diceApp
 */
angular.module("diceApp").factory("StatusTransition", function (DiceResource) {
  return DiceResource("/status-transitions");
});
