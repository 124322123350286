"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:EvaluationStates
 * @description
 * # EvaluationStates
 * Configures all states in relation to evaluations.
 */
angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider
    .state("evaluation-list", {
      url: "/evaluations",
      data: {
        root: true
      },
      resolve: {
        statuses: function (StatusType) {
          return StatusType.query().$promise;
        },
        periods: function (Period) {
          return Period.query().$promise;
        },
        evaluationTypes: function (EvaluationType) {
          return EvaluationType.query().$promise;
        }
      },
      controllerAs: "evaluationStateController",
      controller: function (statuses, periods, evaluationTypes) {
        const evaluationStateController = this;
        evaluationStateController.statuses = statuses;
        evaluationStateController.periods = _(periods)
          .sortBy(["startDate", "endDate"])
          .reverse()
          .value();
        evaluationStateController.evaluationTypes = evaluationTypes;
      },
      template: `<dice-evaluation-list
           evaluation-types="evaluationStateController.evaluationTypes"
           statuses="evaluationStateController.statuses"
           periods="evaluationStateController.periods">
         </dice-evaluation-list>`
    })
    .state("evaluation", {
      url: "/evaluations/{id:[0-9]{1,8}}",
      abstract: true,
      resolve: {
        evaluation: function ($stateParams, Evaluation) {
          return Evaluation.get({ id: $stateParams.id }).$promise;
        },
        operations: function ($stateParams, authorization) {
          return authorization.operations($stateParams.id);
        }
      },
      controllerAs: "evaluationStateController",
      controller: function (evaluation, operations, $state) {
        if (angular.isUndefined(evaluation.id)) {
          $state.go("evaluation-list");
        }

        const evaluationStateController = this;
        evaluationStateController.evaluation = evaluation;
        evaluationStateController.operations = operations;
      },
      template: `<dice-evaluation
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations">
         </dice-evaluation>`
    })
    .state("evaluation.general", {
      url: "/general",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
      },
      template: `<dice-evaluation-general
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations">
         </dice-evaluation-general>`
    })
    .state("evaluation.blocks", {
      url: "/blocks",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
        evaluationStateController.onChange = function (evaluation) {
          _.extend($scope.$parent.evaluationController.evaluation, evaluation);
        };
      },
      template: `<dice-evaluation-blocks
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations"
           on-change="evaluationStateController.onChange(evaluation)">
         </dice-evaluation-blocks>`
    })
    .state("evaluation.questions", {
      url: "/questions",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
      },
      template: `<dice-questions
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations">
         </dice-questions>`
    })
    .state("evaluation.pick", {
      url: "/pick",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
        evaluationStateController.onChange = function (evaluation) {
          _.extend($scope.$parent.evaluationController.evaluation, evaluation);
        };
      },
      template: `<dice-evaluation-template-blocks
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations"
           on-change="evaluationStateController.onChange(evaluation)">
         </dice-evaluation-template-blocks>`
    })
    .state("evaluation.extra", {
      url: "/extra",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
        evaluationStateController.onChange = function (evaluation) {
          _.extend($scope.$parent.evaluationController.evaluation, evaluation);
        };
      },
      template: `<dice-evaluation-extra
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations"
           on-change="evaluationStateController.onChange(evaluation)">
         </dice-evaluation-extra>`
    })
    .state("evaluation.participants", {
      url: "/participants",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
      },
      template: `
        <participant-list
          evaluation="evaluationStateController.evaluation"
          operations="evaluationStateController.operations">
        </participant-list>
      `
    })
    .state("evaluation.feedback", {
      url: "/feedback",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
      },
      template: `<evaluation-feedback
           evaluation="evaluationStateController.evaluation">
         </evaluation-feedback>`
    })
    .state("evaluation.report", {
      url: "/reports?templateId&questions&filters&language",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope, $stateParams, language) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
        evaluationStateController.questions = $stateParams.questions;
        evaluationStateController.filters = angular.fromJson(
          $stateParams.filters
        );
        evaluationStateController.templateId = $stateParams.templateId;

        if (!_.isEmpty($stateParams.language)) {
          language.set($stateParams.language.toUpperCase());
        }
      },
      template: `
        <dice-evaluation-report
          evaluation="evaluationStateController.evaluation"
          operations="evaluationStateController.operations"
          questions="evaluationStateController.questions"
          filters="evaluationStateController.filters"
          template-id="evaluationStateController.templateId">
        </dice-evaluation-report>
      `,
      params: {
        questions: {
          value: [],
          array: true
        },
        filters: {
          value: "{}"
        }
      }
    })
    .state("evaluation.logs", {
      url: "/logs",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
      },
      template: `
        <evaluation-logs
          evaluation="evaluationStateController.evaluation">
        </evaluation-logs>
      `
    })
    .state("evaluation.admin", {
      url: "/admin",
      parent: "evaluation",
      controllerAs: "evaluationStateController",
      controller: function ($scope) {
        const evaluationStateController = this;
        const evaluationController = $scope.$parent.evaluationController;
        evaluationStateController.evaluation = evaluationController.evaluation;
        evaluationStateController.operations = evaluationController.operations;
      },
      template: `<dice-evaluation-admin
           evaluation="evaluationStateController.evaluation"
           operations="evaluationStateController.operations">
         </dice-evaluation-admin>`
    })
    .state("evaluation-create", {
      url: "/evaluations/create",
      controllerAs: "evaluationStateController",
      resolve: {
        types: function (EvaluationType) {
          return EvaluationType.query({
            manual: true
          }).$promise;
        },
        templates: function (EvaluationTemplate) {
          return EvaluationTemplate.query().$promise;
        }
      },
      controller: function (types, templates) {
        const evaluationStateController = this;
        evaluationStateController.types = types;
        evaluationStateController.templates = templates;
      },
      template: `<dice-evaluation-create
           types="evaluationStateController.types"
           templates="evaluationStateController.templates">
         </dice-evaluation-create>`
    })
    .state("evaluation-copy", {
      url: "/evaluations/copy/{id:[0-9]{1,8}}",
      controllerAs: "evaluationStateController",
      resolve: {
        evaluation: function (Evaluation, $stateParams) {
          return Evaluation.getFull($stateParams.id);
        }
      },
      controller: function (evaluation) {
        const evaluationStateController = this;
        evaluationStateController.evaluation = evaluation;
      },
      template: `<evaluation-copy
           base="evaluationStateController.evaluation">
         </evaluation-copy>`
    });
});
