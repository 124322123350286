"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:diceForm
 * @description
 *  Creates a form that acts as a data provider for <dice-input>'s.
 *
 *  Provides the following data to <form-input>'s:
 *
 *    1) Provide the prefix for ng-model to the <form-input>
 *    2) Provide the prefix for the validator for the jarb-input directive
 *    3) Provide the name of the <form> for ng-messages and ng-model $error objects and states
 *
 *  For example:
 *
 *  <form dice-form model="userController.user" validator="User" name="userForm">
 *    <form-input id="username" label="Username"></form-input>
 *  </form>
 */
angular.module("diceApp").directive("diceForm", function ($compile) {
  return {
    restrict: "A",
    bindToController: {
      form: "<?"
    },
    controllerAs: "formController",
    controller: function ($attrs, $scope) {
      // Provide the prefix for ng-model to the <form-input>
      this.model = $attrs.model;

      // Provide the prefix for the validator for the jarb-input directive
      this.validator = _.upperFirst(_.camelCase($attrs.validator));

      // Provide the name of the <form> for ng-messages and ng-model $error objects and states.
      this.name = $attrs.name;

      this.labelWidth = $attrs.labelWidth;

      // Editable
      var disabled = $scope.$eval($attrs.ngDisabled);
      this.editable = disabled !== true;
    },
    link: function link(scope, element, attrs) {
      let formError = angular.element(
        '<form-error form="' + attrs.name + '"></form-error>'
      );
      let firstFormGroup = element.find(".form-group")[0];

      if (angular.isDefined(firstFormGroup)) {
        angular.element(firstFormGroup).before(formError);
      } else {
        element.prepend(formError);
      }

      $compile(formError)(scope);
    }
  };
});
