"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceParticipateAnswers
 * @description
 */
angular.module("diceApp").component("diceParticipateAnswers", {
  bindings: {
    evaluationId: "<",
    session: "<?",
    blocks: "<?"
  },
  templateUrl: "es6/participate/participate.answers.html",
  controllerAs: "participateAnswersController",
  controller: function ($q, Block, Participate) {
    const participateAnswersController = this;

    participateAnswersController.$onInit = function () {
      participateAnswersController.open = false;
      loadData();
    };

    function loadData() {
      if (!_.isEmpty(participateAnswersController.session)) {
        participateAnswersController.loading = true;
        $q.all([
          Participate.answers({
            evaluationId: participateAnswersController.evaluationId,
            session: participateAnswersController.session
          }).$promise,
          Block.query({
            evaluationId: participateAnswersController.evaluationId
          }).$promise
        ])
          .then(([data, blocks]) => {
            participateAnswersController.blocks = getBlocks(
              blocks,
              data.answers
            );
          })
          .finally(() => {
            participateAnswersController.loading = false;
          });
      }
    }

    function getBlocks(blocks, answers) {
      return _(blocks)
        .sortBy("sequence")
        .map((block) => {
          block.questions = _(block.questions)
            .sortBy("sequence")
            .filter((question) => {
              return question.questionType !== "MEMBER_LIST";
            })
            .map((question) => {
              return _.extend(question, {
                answer:
                  _(answers)
                    .filter({ question: question.id })
                    .map("values")
                    .find() || []
              });
            })
            .filter((question) => question.answer.length > 0)
            .value();
          return block;
        })
        .filter((block) => block.questions.length > 0)
        .value();
    }
  }
});
