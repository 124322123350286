"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceNewsList
 * @description
 * diceNewsList Shows the news as list.
 */
angular.module("diceApp").component("newsList", {
  templateUrl: "es6/news/news.list.html",
  controllerAs: "newsListController",
  controller: function (News, Pageable, $uibModal) {
    const newsListController = this;

    newsListController.$onInit = function () {
      newsListController.pageable = Pageable.of({
        page: 0,
        order: "startDate",
        reverse: true
      });

      loadData();
    };

    function loadData() {
      newsListController.loading = true;

      const params = newsListController.pageable.build();
      News.get(params)
        .$promise.then((news) => {
          newsListController.news = news;
        })
        .finally(() => {
          newsListController.loading = false;
        });
    }

    newsListController.search = function () {
      loadData();
    };

    newsListController.create = function () {
      open({});
    };

    newsListController.edit = function (news) {
      open(angular.copy(news));
    };

    newsListController.remove = function (news) {
      News.delete({
        id: news.id
      }).$promise.then(() => {
        loadData();
      });
    };

    function open(news_) {
      $uibModal.open({
        template: `
                    <news-edit-modal
                        news="newsModalController.news"
                        on-close="newsModalController.close()"
                        on-save="newsModalController.save(news)">
                    </news-edit-modal>
                `,
        controllerAs: "newsModalController",
        controller: function ($uibModalInstance) {
          const newsModalController = this;
          newsModalController.news = news_;

          newsModalController.close = function () {
            $uibModalInstance.dismiss();
          };

          newsModalController.save = function (news) {
            News.store(news).$promise.then(() => {
              newsModalController.close();
              loadData();
            });
          };
        }
      });
    }
  }
});
