"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceDashboard
 * @description
 * diceDashboard Shows the dashboard.
 */
angular.module("diceApp").component("diceDashboard", {
  templateUrl: "es6/dashboard/dashboard.html",
  controllerAs: "dashboardController",
  bindings: {
    customer: "<"
  },
  controller: function (Evaluation, EvaluationWidget, $q, authentication) {
    const dashboardController = this;
    dashboardController.getCurrent = authentication.getCurrent;

    dashboardController.widgets = {};
    dashboardController.$onInit = function () {
      loadWidgets()
        .then(function (data) {
          dashboardController.widgets = parseWidgetData(data);
        })
        .finally(function () {
          dashboardController.loading = false;
        });
    };

    function loadWidgets() {
      dashboardController.loading = true;

      const widgets = [
        {
          x: 2,
          y: 0,
          template: "<news-widget></news-widget>"
        },
        {
          x: 2,
          y: 1,
          template: "<mail-widget></mail-widget>"
        }
      ];

      if (dashboardController.customer.condensedReportWidgetEnabled) {
        widgets.push({
          x: 2,
          y: 2,
          template: "<condensed-report-widget></condensed-report-widget>"
        });
      }

      return $q
        .all([EvaluationWidget.query().$promise, Evaluation.mine().$promise])
        .then(function (results) {
          dashboardController.evaluations = _(results[0])
            .sortBy(["alert", "progress"])
            .map(function (widget) {
              var roles = widget.roles;
              var data = _.filter(results[1], function (container) {
                var statusId = _.get(container.evaluation.status, "id", "");
                var requiredTypeIds = _(roles)
                  .filter(function (role) {
                    return role.status.id === statusId;
                  })
                  .map((r) => r.type.id)
                  .uniq()
                  .value();
                var availableTypeIds = _(container.roles)
                  .map("id")
                  .uniq()
                  .value();
                return (
                  _.intersection(requiredTypeIds, availableTypeIds).length > 0
                );
              });

              return { widget, data };
            })
            .filter((e) => e.data.length > 0)
            .reverse()
            .value();

          if (dashboardController.evaluations.length === 0) {
            widgets.push({
              alert: false,
              x: 0,
              y: 1,
              template: "<empty-widget></empty-widget>"
            });
          }

          _.each(dashboardController.evaluations, function (container, $index) {
            widgets.push({
              alert: container.alert,
              x: $index % 2 === 0 ? 0 : 1,
              y: $index,
              template:
                "<evaluation-widget " +
                'widget="dashboardController.evaluations[' +
                $index +
                '].widget"' +
                'evaluations="dashboardController.evaluations[' +
                $index +
                '].data">' +
                "</evaluation-widget>"
            });
          });

          return widgets;
        });
    }

    function parseWidgetData(data) {
      return _(data).sortBy(["x", "y"]).groupBy("x").value();
    }
  }
});
