"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:OptionTemplate
 * @description
 * # OptionTemplate
 * OptionTemplate resource of the diceApp
 */
angular.module("diceApp").factory("OptionTemplate", function (DiceResource) {
  const resource = DiceResource("/option-templates", {
    search: {
      url: "/option-templates/search",
      method: "GET",
      isArray: false
    }
  });

  return _.extend(resource, {
    mapToOptions: function (template) {
      const results = _.groupBy(template.options, "language");
      _.each(_.keys(results), function (language) {
        results[language] = _.sortBy(results[language], "sequence");
      });
      return results;
    }
  });
});
