"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceWidgetList
 * @description Manages a list of widgets.
 */
angular.module("diceApp").component("diceWidgetList", {
  bindings: {
    widgets: "<"
  },
  templateUrl: "es6/dashboard/widget.list.html",
  controllerAs: "widgetListController",
  controller: function ($uibModal, EvaluationWidget, message) {
    const widgetListController = this;

    widgetListController.remove = function (widget) {
      EvaluationWidget.remove(widget.id).$promise.then(function () {
        message.addSuccessLabel("Message.Delete.Success");
        _.remove(widgetListController.widgets, { id: widget.id });
      });
    };

    widgetListController.open = function (widget) {
      $uibModal.open({
        template: `
            <widget-modal
              widget="modalController.widget"
              on-save="modalController.onSave"
              modal="modalController.modal">
            </widget-modal>
          `,
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.modal = $uibModalInstance;
          modalController.widget = angular.copy(widget);

          modalController.onSave = function (savedWidget) {
            var found = _.find(widgetListController.widgets, {
              id: savedWidget.id
            });
            if (found) {
              _.extend(found, savedWidget);
            } else {
              widgetListController.widgets.push(savedWidget);
            }
            message.addSuccessLabel("Message.Save.Success");
          };
        }
      });
    };
  }
});
