"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:ReportTemplateList
 * @description
 * ReportTemplateList Shows the report templates in list.
 */
angular.module("diceApp").component("diceReportTemplateList", {
  templateUrl: "es6/report/template/report.template.list.html",
  controllerAs: "reportTemplateListController",
  controller: function (
    $q,
    $uibModal,
    message,
    EvaluationType,
    ReportTemplate,
    CondensedReportTemplate
  ) {
    const reportTemplateListController = this;

    reportTemplateListController.$onInit = function () {
      EvaluationType.query().$promise.then((evaluationTypes) => {
        reportTemplateListController.evaluationTypes = evaluationTypes;
      });
    };

    reportTemplateListController.search = function () {
      if (angular.isDefined(reportTemplateListController.evaluationType)) {
        loadData();
      } else {
        reportTemplateListController.templates = [];
      }
    };

    function loadData() {
      reportTemplateListController.loading = true;
      $q.all([
        ReportTemplate.query({
          evaluationType: reportTemplateListController.evaluationType
        }).$promise.then((templates) => {
          _.forEach(templates, (template) => {
            template.editStateName = "report-template-edit";
          });
          return templates;
        }),
        CondensedReportTemplate.query({
          evaluationType: reportTemplateListController.evaluationType
        }).$promise.then((templates) => {
          _.forEach(templates, (template) => {
            template.editStateName = "condensed-report-template-edit";
          });
          return templates;
        })
      ])
        .then(([templates, condensed]) => {
          const reportTemplates = templates.concat(condensed);
          reportTemplateListController.reportTemplates = _.sortBy(
            reportTemplates,
            ["reportType", "startDate", "endDate"]
          );
        })
        .finally(() => {
          reportTemplateListController.loading = false;
        });
    }
  }
});
