"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:PersonSubstitute
 * @description
 * # PersonSubstitute
 * PersonSubstitute resource of the diceApp
 */
angular.module("diceApp").factory("PersonSubstitute", function (DiceResource) {
  return DiceResource("/person-substitutes", {
    available: {
      method: "GET",
      url: "/person-substitutes/available",
      isArray: true
    }
  });
});
