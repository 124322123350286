"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:CustomerStyle
 * @description Applies customer styling to the page.
 */
angular.module("diceApp").directive("questionWatch", function () {
  return {
    restrict: "A",
    scope: {
      debounce: "@?",
      questionWatch: "@",
      callback: "&?"
    },
    link: function (scope, element) {
      let callback = scope.callback;
      if (scope.debounce) {
        callback = _.debounce(scope.callback, parseInt(scope.debounce));
      }

      element.bind(scope.questionWatch, function () {
        const value = element.val();
        const question = element.attr("question-id");
        callback({ question, value });
      });
    }
  };
});
