"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:sendNotificationsModal
 * @description Shows modal to send notifications from a selected notifications template.
 */
angular.module("diceApp").component("sendNotificationsModal", {
  bindings: {
    evaluation: "<",
    templates: "<",
    operations: "<",
    modal: "<"
  },
  templateUrl: "es6/notifications/notifications.send.modal.html",
  controllerAs: "sendNotificationsController",
  controller: function (message, Notifications) {
    const sendNotificationsController = this;
    sendNotificationsController.sending = false;
    sendNotificationsController.notification = {};

    sendNotificationsController.$onInit = function () {
      const evaluation = sendNotificationsController.evaluation;
      const automated =
        sendNotificationsController.operations.SEND_NOTIFICATIONS_AUTOMATED ===
        true;
      sendNotificationsController.filteredTemplates = _(
        sendNotificationsController.templates
      )
        .filter({
          evaluationType: evaluation.type.id
        })
        .filter((t) => t.manual === true || automated)
        .filter(function (template) {
          return (
            angular.isUndefined(template.statusType) ||
            template.statusType === evaluation.status.id
          );
        })
        .sortBy(["sequence", "id"])
        .value();
    };

    sendNotificationsController.onTemplate = function () {
      sendNotificationsController.disabled = !_.get(
        sendNotificationsController.selectedTemplate,
        "editable",
        false
      );

      if (!sendNotificationsController.selectedTemplate) {
        return;
      }

      const selectedTemplate = angular.copy(
        sendNotificationsController.selectedTemplate
      );
      Notifications.getRecipients({
        evaluation: sendNotificationsController.evaluation.id,
        template: sendNotificationsController.selectedTemplate.id
      }).$promise.then((availableRecipients) => {
        sendNotificationsController.availableRecipients = availableRecipients;
      });

      sendNotificationsController.notification.relationTypeId =
        selectedTemplate.relationType;
      sendNotificationsController.notification.subjects =
        selectedTemplate.subjects;
      sendNotificationsController.notification.texts = selectedTemplate.bodies;
    };

    sendNotificationsController.send = function (notification) {
      sendNotificationsController.sending = true;

      const body = angular.copy(notification);
      const params = {
        evaluation: sendNotificationsController.evaluation.id,
        template: sendNotificationsController.selectedTemplate.id
      };

      Notifications.send(params, body)
        .$promise.then(() => {
          message.addSuccess("Notification sent successfully");
        })
        .finally(function () {
          sendNotificationsController.sending = false;
          sendNotificationsController.close();
        });
    };

    sendNotificationsController.close = function () {
      sendNotificationsController.modal.dismiss();
    };
  }
});
