"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:authenticateToken
 * @description
 * # authenticateToken
 * Retrieves the JWT token from TE Auth and forwards it to the API.
 */
angular.module("diceApp").component("authenticateToken", {
  bindings: {
    action: "<"
  },
  templateUrl: "es6/security/authenticate.token.html",
  controllerAs: "authenticateTokenController",
  controller: function (authentication, AuthenticateToken) {
    const authenticateTokenController = this;

    authenticateTokenController.$onInit = function () {
      perform();
    };

    function perform() {
      authenticateTokenController.loading = true;
      return authentication
        .getConfig()
        .then((config) => {
          return AuthenticateToken.authenticate(
            config,
            authenticateTokenController.action
          );
        })
        .finally(() => {
          authenticateTokenController.loading = false;
        });
    }
  }
});
