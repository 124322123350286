"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:DescriptionInput
 * @description
 * The DescriptionInput directive.
 */
angular.module("diceApp").component("requiredLabel", {
  bindings: {
    ngModel: "<"
  },
  templateUrl: "es6/base/forms/validation/required.label.html",
  controllerAs: "labelController",
  controller: function (valueChecker) {
    const labelController = this;

    labelController.isCorrect = function () {
      return !valueChecker.isEmpty(labelController.ngModel);
    };
  }
});
