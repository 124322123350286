"use strict";

angular.module("diceApp").component("diceMessages", {
  templateUrl: "es6/base/alerts/messages.html",
  controllerAs: "messageController",
  controller: function (message) {
    const messageController = this;
    messageController.messages = message.getMessages();
  }
});
