"use strict";

angular.module("diceApp").component("elementValueSelect", {
  bindings: {
    inputId: "@?",
    type: "<?",
    externalId: "<?",
    ngDisabled: "<?"
  },
  templateUrl: "es6/elements/element.value.select.html",
  require: {
    ngModelCtrl: "ngModel"
  },
  controllerAs: "elementValueSelectController",
  controller: function ($q, $scope, Element) {
    const elementValueSelectController = this;

    elementValueSelectController.$onInit = function () {
      elementValueSelectController.ngModelCtrl.$render = () => {
        elementValueSelectController.value =
          elementValueSelectController.ngModelCtrl.$viewValue;
      };
    };

    elementValueSelectController.$onChanges = function (changes) {
      if (changes.type || changes.externalId) {
        loadData();
      }
    };

    function loadData() {
      getElement().then((element) => {
        const values = _.result(element, "values", []);
        elementValueSelectController.values = _(values).sort().value();
      });
    }

    function getElement() {
      if (
        elementValueSelectController.type &&
        elementValueSelectController.externalId
      ) {
        return Element.get({
          type: elementValueSelectController.type,
          externalId: elementValueSelectController.externalId
        }).$promise;
      }

      return $q.resolve();
    }

    elementValueSelectController.onChange = function () {
      elementValueSelectController.ngModelCtrl.$setViewValue(
        elementValueSelectController.value
      );
    };
  }
});
