"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Components
 * @description
 * # Components
 * Components resource of the diceApp
 */
angular.module("diceApp").factory("Synchronize", function (DiceResource) {
  return DiceResource("/synchronization", {
    components: {
      method: "POST",
      url: "/synchronization/components/:periodId",
      isArray: true
    },
    persons: {
      method: "POST",
      url: "/synchronization/persons/:id",
      isArray: true
    }
  });
});
