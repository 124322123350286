"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:statusConfirmChangeModal
 * @description Shows a modal to confirm the status to be changed.
 */
angular.module("diceApp").component("statusConfirmChangeModal", {
  bindings: {
    transition: "<",
    callback: "<",
    modal: "<"
  },
  require: "ngModel",
  templateUrl: "es6/status/status.confirm.change.modal.html",
  controllerAs: "confirmController",
  controller: function () {
    const confirmController = this;
    confirmController.dismiss = function () {
      confirmController.modal.close();
    };
    confirmController.perform = function () {
      confirmController.callback();
      confirmController.modal.close();
    };
  }
});
