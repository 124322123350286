"use strict";

/**
 * @ngdoc function
 * @name diceApp.config:CsvStates
 * @description
 * # CsvStates
 */
angular.module("diceApp").config(function ($stateProvider) {
  // Widgets
  $stateProvider.state("csv", {
    url: "/csv",
    data: {
      root: true
    },
    template: "<dice-csv-upload></dice-csv-upload>"
  });
});
