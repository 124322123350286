"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:workflowList
 * @description
 * workflowList Shows the workflows in list.
 */
angular.module("diceApp").component("workflowList", {
  templateUrl: "es6/workflow/workflow.list.html",
  controllerAs: "listController",
  controller: function (
    Workflow,
    EvaluationType,
    systemPropertyStore,
    $scope,
    $window,
    $uibModal,
    message
  ) {
    const listController = this;

    listController.$onInit = function () {
      listController.Math = Math;

      EvaluationType.query().$promise.then((types) => {
        listController.evaluationTypes = types;
        listController.evaluationTypeId = _(types)
          .filter({ defaultSelected: true })
          .map("id")
          .find();
        listController.search();
      });

      listController.timeTravelEnabled = systemPropertyStore.findValue(
        "schedulerTimeTravelEnabled",
        false
      );
      listController.resetStatusEnabled = systemPropertyStore.findValue(
        "schedulerResetStatusEnabled",
        false
      );
    };

    listController.search = function () {
      listController.loading = true;
      Workflow.query()
        .$promise.then((workflows) => {
          listController.workflows = _.filter(workflows, (workflow) => {
            return (
              workflow.evaluationType.id === listController.evaluationTypeId
            );
          });
        })
        .finally(() => {
          listController.loading = false;
        });
    };

    listController.create = function () {
      listController.open({
        evaluationType: listController.evaluationTypeId,
        offsetInDays: 0
      });
    };

    listController.open = function (workflow) {
      $uibModal.open({
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.modal = $uibModalInstance;

          if (angular.isDefined(workflow.id)) {
            modalController.workflow = Workflow.get({
              id: workflow.id
            });
          } else {
            modalController.workflow = workflow;
          }

          modalController.onSave = function () {
            message.addSuccessLabel("Message.Save.Success");
            listController.search();
          };
        },
        template: `
            <workflow-edit
              workflow="modalController.workflow" 
              on-save="modalController.onSave" 
              modal="modalController.modal">
            </workflow-edit>
          `
      });
    };

    listController.remove = function (workflow) {
      return Workflow.remove(workflow.id).$promise.then(() => {
        message.addSuccessLabel("Message.Delete.Success");
        return listController.search();
      });
    };

    listController.execute = function () {
      $uibModal.open({
        templateUrl: "es6/workflow/workflow.execute.modal.html",
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.updating = false;
          modalController.date = new Date().getTime();

          modalController.close = function () {
            $uibModalInstance.close();
          };

          modalController.execute = function () {
            if (_.isUndefined(modalController.date)) {
              message.addErrorLabel("Page.Workflows.Execute.Modal.Error.Date");
            } else {
              modalController.updating = true;
              Workflow.execute({
                date: modalController.date
              })
                .$promise.then(() => {
                  $uibModalInstance.close();
                })
                .finally(() => {
                  modalController.updating = false;
                });
            }
          };
        }
      });
    };

    listController.reset = function () {
      $uibModal.open({
        templateUrl: "es6/workflow/workflow.reset.modal.html",
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;

          modalController.close = function () {
            $uibModalInstance.close();
          };

          modalController.execute = function () {
            Workflow.reset().$promise.then(function () {
              $uibModalInstance.close();
              message.addSuccessLabel(
                "All evaluations and workflows are reset!"
              );
            });
          };
        }
      });
    };
  }
});
