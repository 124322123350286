"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceForbidden
 * @description
 * # diceForbidden
 * Shown when operation is forbidden.
 */
angular.module("diceApp").component("diceForbidden", {
  templateUrl: "es6/security/forbidden.html",
  controllerAs: "forbiddenController",
  controller: function (Customer) {
    const forbiddenController = this;

    forbiddenController.$onInit = function () {
      Customer.load().then((customer) => {
        forbiddenController.customer = customer;
      });
    };
  }
});
