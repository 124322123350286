"use strict";

/**
 * @ngdoc function
 * @name diceApp.factory:Person
 * @description
 * # Person
 * Person resource of the diceApp
 */
angular.module("diceApp").factory("Person", function (DiceResource) {
  return DiceResource("/persons", {
    search: {
      method: "GET",
      url: "/persons/search"
    },
    publicSearch: {
      method: "GET",
      url: "/persons/public-search"
    },
    updatePassword: {
      method: "POST",
      url: "/persons/:id/password"
    },
    changePassword: {
      method: "POST",
      url: "/persons/:id/change-password"
    }
  });
});
