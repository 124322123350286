"use strict";

angular.module("diceApp").config(function ($stateProvider) {
  $stateProvider
    .state("condensed-report-template-edit", {
      url: "/condensed-report-template/{id:[0-9]{1,8}}",
      resolve: {
        template: function ($stateParams, CondensedReportTemplate) {
          return CondensedReportTemplate.get({ id: $stateParams.id }).$promise;
        }
      },
      controllerAs: "condensedReportTemplateStateController",
      controller: function (template) {
        const condensedReportTemplateStateController = this;
        condensedReportTemplateStateController.template = template;
      },
      template: `
        <condensed-report-template
          template="condensedReportTemplateStateController.template">
        </condensed-report-template>`
    })
    .state("condensed-report-template-create", {
      url: "/condensed-report-template/create",
      resolve: {
        periods: function (Period) {
          return Period.query().$promise.then((periods) => {
            return _.filter(periods, (period) => {
              return period.period === 0; // Period 0 is the whole year. We only want to make templates for whole years as it is a year report.
            });
          });
        },
        template: function (periods) {
          const currentYear = new Date().getFullYear();
          const periodForCurrentYear = _.find(periods, (period) => {
            return period.year === currentYear;
          });

          return {
            year: periodForCurrentYear ? periodForCurrentYear.year : null
          };
        }
      },
      controllerAs: "condensedReportTemplateStateController",
      controller: function (template) {
        const condensedReportTemplateStateController = this;
        condensedReportTemplateStateController.template = template;
      },
      template: `
        <condensed-report-template
          template="condensedReportTemplateStateController.template">
        </condensed-report-template>`
    });
});
