"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceOptionComponent
 * @description Manages a option component.
 */
angular.module("diceApp").component("diceAnswerOptionsReadOnly", {
  bindings: {
    options: "<"
  },
  templateUrl: "es6/templates/option/answer.options.read.only.html",
  controllerAs: "optionReadOnlyController",
  controller: function (OptionTemplate) {
    const optionReadOnlyController = this;
    optionReadOnlyController.$onInit = loadData;
    optionReadOnlyController.$onChanges = loadData;

    function loadData() {
      optionReadOnlyController.grouped = OptionTemplate.mapToOptions({
        options: optionReadOnlyController.options
      });
    }
  }
});
