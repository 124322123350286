"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:editQuestionModal
 * @description add an extra question to the evaluation.
 */
angular.module("diceApp").component("editQuestionModal", {
  bindings: {
    modal: "<",
    question: "<",
    evaluationType: "<",
    advanced: "<?"
  },
  templateUrl: "es6/evaluations/blocks/question.edit.modal.html",
  controllerAs: "questionController",
  controller: function (OptionTemplate, questionType, $filter) {
    const questionController = this;

    questionController.$onInit = function () {
      questionController.questionType = questionType;

      questionController.questionTypes = _(
        questionController.evaluationType.questionTypes
      )
        .map((type) => {
          return {
            value: type,
            name: $filter("enum")(type)
          };
        })
        .orderBy("name")
        .value();
    };

    questionController.close = function () {
      questionController.modal.close();
    };

    questionController.continue = function () {
      questionController.close();
    };

    questionController.optionsChanged = function (options, templateId) {
      questionController.question.options = options;

      if (!templateId && templateId !== 0) {
        questionController.question.optionTemplate = null;
        return;
      }

      const isOptionTemplateChanged = !_.isEqual(
        _.get(questionController.question, "optionTemplate.id"),
        templateId
      );

      if (isOptionTemplateChanged) {
        // Here, we only need the ID for optionTemplate. In question.template, we need the whole object for the preview.
        OptionTemplate.get({ id: templateId }).$promise.then(
          (optionTemplate) => {
            questionController.question.optionTemplate = optionTemplate.id;
          }
        );
      } else {
        questionController.question.optionTemplate = templateId;
      }
    };
  }
});
