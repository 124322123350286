"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:dicePinned
 * @description
 * dicePinned Shows the pinned evaluations.
 */
angular.module("diceApp").component("dicePinned", {
  templateUrl: "es6/pinning/pinned.html",
  controllerAs: "pinnedController",
  controller: function (
    authentication,
    authorization,
    pinnedRegistry,
    $q,
    language,
    Evaluation
  ) {
    const pinnedController = this;

    pinnedController.$onInit = function () {
      pinnedController.userRole = authentication.getCurrent().role;
      pinnedController.evaluations = pinnedRegistry.load() || [];
      pinnedController.exportableEvaluations = [];
      pinnedController.shown = pinnedRegistry.isOpen;

      pinnedRegistry.addOnChangeCallback((evaluations) =>
        setExportPermissionState(evaluations)
      );
      setExportPermissionState(pinnedController.evaluations);
    };

    pinnedController.clear = function () {
      pinnedRegistry.clear();
    };

    pinnedController.togglePinnedState = function () {
      pinnedRegistry.togglePinnedState();
    };

    pinnedController.toggle = function () {
      pinnedController.shown = pinnedController.shown === false;
    };

    pinnedController.getPinnedForReport = function () {
      const evaluationIds = _.map(pinnedController.evaluations, "id");

      if (_.isEmpty(evaluationIds)) {
        return $q.resolve([]);
      } else {
        return getEvaluations(evaluationIds);
      }
    };

    function getEvaluations(evaluationIds) {
      return Evaluation.search({
        id: evaluationIds,
        language: language.get(),
        size: 9999
      }).$promise.then((data) => {
        return _.map(data.content, (evaluation) => ({
          id: evaluation.id,
          code: evaluation.code,
          name: evaluation.name,
          type: evaluation.type.id,
          status: evaluation.status.name
        }));
      });
    }

    pinnedController.hasExportableEvaluations = function () {
      return _.some(
        pinnedController.evaluations,
        pinnedController.isExportAllowed
      );
    };

    pinnedController.isExportAllowed = function (evaluation) {
      return pinnedController.exportableEvaluations.indexOf(evaluation) >= 0;
    };

    pinnedController.getEvaluations = () => {
      return pinnedController.evaluations;
    };

    function setExportPermissionState(pinnedEvaluations) {
      pinnedController.exportableEvaluations = [];

      pinnedEvaluations.forEach((evaluation) => {
        isExportAllowed(evaluation).then((allowed) => {
          if (allowed) {
            pinnedController.exportableEvaluations.push(evaluation);
          }
        });
      });
    }

    function isExportAllowed(evaluation) {
      return authorization.operations(evaluation.id).then((operations) => {
        return operations.DOWNLOAD === true;
      });
    }

    pinnedController.getExportUrl = function () {
      return `/api/export?strategy=EXCEL&language=${language.get()}`;
    };

    pinnedController.getExportBody = function () {
      return _.map(pinnedController.evaluations, "id").join();
    };
  }
});
