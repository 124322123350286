"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:pdfButton
 */
angular.module("diceApp").component("pdfButton", {
  bindings: {
    viewClasses: "<"
  },
  templateUrl: "es6/report/pdf.button.html",
  controllerAs: "pdfButtonController"
});
