"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:memberTypeSequenceModal
 * @description Sets the sequence of the member types
 */
angular.module("diceApp").component("memberTypeSequenceModal", {
  bindings: {
    memberTypes: "<",
    evaluationType: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/members/type/member.type.sequence.modal.html",
  controllerAs: "memberTypeSequenceController",
  controller: function (MemberType, $scope) {
    const memberTypeSequenceController = this;

    memberTypeSequenceController.$onInit = function () {
      memberTypeSequenceController.currentEvaluationType = _.result(
        memberTypeSequenceController.evaluationType,
        "id"
      );
    };

    memberTypeSequenceController.onMoved = function (items, index) {
      items.splice(index, 1);
    };

    memberTypeSequenceController.close = function () {
      memberTypeSequenceController.modal.close();
    };

    memberTypeSequenceController.save = function () {
      let memberTypeSequences = [];
      _(memberTypeSequenceController.filteredMemberTypes).forEach(
        function (memberType, index) {
          memberTypeSequences.push({
            id: memberType.id,
            sequence: index
          });
        }
      );

      MemberType.storeOrderSequence(memberTypeSequences).$promise.then(
        (result) => {
          memberTypeSequenceController.onSave(result);
          memberTypeSequenceController.close();
        }
      );
    };

    $scope.$watch("memberTypeSequenceController.currentEvaluationType", () => {
      memberTypeSequenceController.filteredMemberTypes = _(
        memberTypeSequenceController.memberTypes
      )
        .filter({
          evaluationType: memberTypeSequenceController.currentEvaluationType
        })
        .orderBy("sequence")
        .value();
    });
  }
});
