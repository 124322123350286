"use strict";

/**
 * @ngdoc function
 * @name diceApp.controller:EvaluationList
 * @description
 * EvaluationList Shows the evaluations in list.
 */
angular.module("diceApp").component("diceEvaluation", {
  bindings: {
    evaluation: "<",
    operations: "<"
  },
  templateUrl: "es6/evaluations/evaluation.html",
  controllerAs: "evaluationController",
  controller: function (
    Block,
    Evaluation,
    EvaluationTemplate,
    StatusTransition,
    NotificationTemplate,
    message,
    evaluationPreparator,
    $q,
    $state,
    $uibModal,
    $window
  ) {
    const evaluationController = this;

    evaluationController.$onInit = function () {
      loadData();
    };

    evaluationController.load = function () {
      return $q.resolve([evaluationController.evaluation]);
    };

    evaluationController.openReport = function () {
      const address = $state.href("single-report", {
        id: evaluationController.evaluation.id,
        type: "PUBLIC_REPORT"
      });
      $window.open(address, "_blank");
    };

    evaluationController.openNotifications = function () {
      $uibModal.open({
        template: `
            <send-notifications-modal
              evaluation="modalController.evaluation"
              templates="modalController.templates"
              operations="modalController.operations"
              modal="modalController.instance">
            </send-notifications-modal>
          `,
        controllerAs: "modalController",
        resolve: {
          evaluation: function () {
            return evaluationController.evaluation;
          },
          operations: function () {
            return evaluationController.operations;
          },
          templates: function () {
            return NotificationTemplate.query().$promise;
          }
        },
        controller: function (
          $uibModalInstance,
          evaluation,
          operations,
          templates
        ) {
          const modalController = this;
          modalController.evaluation = evaluation;
          modalController.templates = templates;
          modalController.operations = operations;
          modalController.instance = $uibModalInstance;
        },
        windowClass: "lg-modal-window"
      });
    };

    evaluationController.hasNoneOperation = function (expected) {
      return !_.some(expected, (name) => {
        return evaluationController.operations[name] === true;
      });
    };

    evaluationController.goToCopy = function (evaluation) {
      $state.go("evaluation-copy", {
        id: evaluation.id
      });
    };

    evaluationController.prepareForPreview = function (evaluation) {
      const preview = angular.copy(evaluation);

      return Block.query({
        evaluationId: evaluation.id
      }).$promise.then((blocks) => {
        preview.blocks = blocks;

        _.each(preview.blocks, (block) => {
          if (angular.isDefined(block.template) && _.isEmpty(block.questions)) {
            const templateForBlock = _.find(
              evaluationController.template.blocks,
              (item) => item.block.id === block.template
            );
            const questions =
              _.result(templateForBlock, "block.questions") || [];
            block.questions = _.map(questions, (question) => {
              const result = angular.copy(question.question);
              return _.extend(result, {
                sequence: question.sequence
              });
            });
            delete block.template;
          }
        });
        return evaluationPreparator.prepare(preview);
      });
    };

    evaluationController.changeStatus = function (transition) {
      confirmStatusChange(transition, function () {
        Evaluation.changeStatus(
          {
            evaluationId: evaluationController.evaluation.id
          },
          {
            newStatusName: transition.toStatus.name
          }
        ).$promise.then(goToGeneral);
      });
    };

    function goToGeneral() {
      $state.go(
        "evaluation.general",
        {
          id: evaluationController.evaluation.id
        },
        {
          reload: true
        }
      );
    }

    evaluationController.validateStatus = function (transition) {
      confirmStatusChange(transition, function () {
        Evaluation.validateStatus(
          {
            evaluationId: evaluationController.evaluation.id
          },
          {}
        ).$promise.then(goToGeneral);
      });
    };

    function confirmStatusChange(transition, callback) {
      $uibModal.open({
        template: `
            <status-confirm-change-modal
              transition="modalController.transition"
              callback="modalController.callback"
              modal="modalController.instance">
            </status-confirm-change-modal>
          `,
        controllerAs: "modalController",
        controller: function ($uibModalInstance) {
          const modalController = this;
          modalController.transition = transition;
          modalController.callback = callback;
          modalController.instance = $uibModalInstance;
        }
      });
    }

    function loadData() {
      if (angular.isDefined(evaluationController.evaluation.template)) {
        EvaluationTemplate.get({
          id: evaluationController.evaluation.template
        }).$promise.then(function (template) {
          evaluationController.template = template;
        });
      }

      StatusTransition.query({
        evaluationType: evaluationController.evaluation.type.id,
        currentStatus: evaluationController.evaluation.status
          ? evaluationController.evaluation.status.name
          : ""
      }).$promise.then(function (transitions) {
        evaluationController.transitions = transitions;
      });
    }
  }
});
