"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:statusTypeModal
 * @description Shows a modal to manage a status type.
 */
angular.module("diceApp").component("statusTypeModal", {
  bindings: {
    statusType: "<",
    onSave: "<",
    modal: "<"
  },
  templateUrl: "es6/status/type/status.type.modal.html",
  controllerAs: "statusTypeController",
  controller: function (StatusType) {
    const statusTypeController = this;

    statusTypeController.close = function () {
      statusTypeController.modal.close();
    };

    statusTypeController.save = function () {
      statusTypeController.statusType.name = _.toUpper(
        statusTypeController.statusType.name
      );
      StatusType.store(statusTypeController.statusType).$promise.then(
        function (result) {
          statusTypeController.onSave(result);
          statusTypeController.close();
        }
      );
    };
  }
});
