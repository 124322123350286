"use strict";

angular.module("diceApp").component("parameterList", {
  templateUrl: "es6/parameter/parameter.list.html",
  controllerAs: "parameterController",
  controller: function ($q, message, Pageable, Parameter) {
    const parameterController = this;

    parameterController.$onInit = function () {
      parameterController.updated = [];
      parameterController.pageable = Pageable.of();

      loadData();
    };

    function loadData() {
      parameterController.loading = true;

      Parameter.reload()
        .then((parameters) => {
          parameterController.parameters = _.sortBy(parameters, "name");
          parameterController.filtered = parameterController.parameters;
        })
        .finally(() => {
          parameterController.loading = false;
        });
    }

    parameterController.search = function (search) {
      parameterController.filtered = _.filter(
        parameterController.parameters,
        (parameter) =>
          !search || _.includes(_.toLower(parameter.name), _.toLower(search))
      );
    };

    parameterController.save = function (form) {
      const promises = _(parameterController.parameters)
        .filter((parameter) => parameter.editable)
        .filter((parameter) => hasChanged(parameter, form))
        .map((parameter) => Parameter.save(parameter).$promise)
        .value();

      $q.all(promises).then(() => {
        message.onSaved();
        loadData();
      });
    };

    function hasChanged(parameter, form) {
      return _.result(form[parameter.name], "$dirty");
    }
  }
});
