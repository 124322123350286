"use strict";

/**
 * @ngdoc component
 * @name diceApp.component:htmlInput
 * @description
 * The htmlInput component.
 */
angular.module("diceApp").component("htmlInput", {
  templateUrl: "es6/i18n/html.input.html",
  transclude: true,
  bindings: {
    language: "@?",
    ngDisabled: "<?",
    ngRequired: "<?",
    minLength: "<?",
    patterns: "<?"
  },
  require: {
    ngModelCtrl: "^ngModel"
  },
  controllerAs: "htmlInputController",
  controller: function () {
    const htmlInputController = this;

    htmlInputController.$onInit = function () {
      htmlInputController.ngModelCtrl.$render = () => {
        htmlInputController.value =
          htmlInputController.ngModelCtrl.$modelValue || "";
      };

      htmlInputController.ngModelCtrl.$validators.minLength = () => {
        return (
          !htmlInputController.minLength ||
          (htmlInputController.value || "").length >=
            htmlInputController.minLength
        );
      };

      htmlInputController.ngModelCtrl.$validators.patterns = () => {
        return _.every(htmlInputController.patterns, (expected) => {
          return _.includes(htmlInputController.value, expected);
        });
      };
    };

    htmlInputController.update = function () {
      const formatted = applyFormat(angular.copy(htmlInputController.value));
      htmlInputController.ngModelCtrl.$setViewValue(formatted);
    };

    function applyFormat(text) {
      if (_.isEmpty(text)) {
        return text;
      }

      // Replace all paragraphs with break rules
      return text
        .replace(/&nbsp;/g, " ")
        .replace(/<p[^>]*><br><\/p>/g, "<br/>")
        .replace(/<p[^>]*>/g, "")
        .replace(/<\/p>$/g, "")
        .replace(/<\/p>/g, "<br/>")
        .replace(/<br>$/g, "");
    }
  }
});
