"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:diceEvaluationTemplateBlocks
 * @description
 * diceEvaluationTemplateBlocks Allows users to pick which blocks to use.
 */
angular.module("diceApp").component("diceQuestions", {
  bindings: {
    evaluation: "<",
    operations: "<"
  },
  templateUrl: "es6/evaluations/evaluation.questions.html",
  controllerAs: "questionsController",
  controller: function (authentication, EvaluationTemplate, Block, language) {
    const questionsController = this;

    questionsController.$onInit = function () {
      loadData();
      setLanguage();
      questionsController.onLanguage = language.onChange(setLanguage);
      questionsController.isAuthorized = authentication.hasRole([
        "SYSTEM_ADMIN",
        "ADMIN",
        "EVALUATION_COORDINATOR",
        "USER_COORDINATOR"
      ]);
    };

    function setLanguage() {
      questionsController.language = language.get();
    }

    questionsController.$onDestroy = function () {
      questionsController.onLanguage();
    };

    function loadData() {
      if (angular.isDefined(questionsController.evaluation.template)) {
        questionsController.template = EvaluationTemplate.get({
          id: questionsController.evaluation.template
        });
      }

      loadBlocks();
    }

    function loadBlocks() {
      Block.query({
        evaluationId: questionsController.evaluation.id
      }).$promise.then((blocks) => {
        questionsController.blocks = _(blocks)
          .filter("active")
          .sortBy("sequence")
          .map((block) => {
            const blockCopy = angular.copy(block);

            blockCopy.questions = _.map(block.questions, (question) => {
              const result = angular.copy(question);
              result.options = _(question.options).sortBy("sequence").value();
              return result;
            });

            return blockCopy;
          })
          .value();
      });
    }

    questionsController.prepareForPreview = function (block) {
      if (_.isEmpty(block.questions)) {
        return angular.copy(block.item.block);
      } else {
        return angular.copy(block);
      }
    };

    questionsController.toggleBlock = (blockId) => {
      questionsController.blocks = _.map(
        questionsController.blocks,
        (block) => {
          block.isOpen = blockId === block.id && !block.isOpen;
          if (!block.isOpen) {
            block.questions = _.map(block.questions, (question) => {
              question.isOpen = false;
              return question;
            });
          }

          return block;
        }
      );
    };

    questionsController.toggleQuestion = (questions, questionId) => {
      questions = _.map(questions, (question) => {
        question.isOpen = questionId === question.id && !question.isOpen;
        return question;
      });
    };
  }
});
