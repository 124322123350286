"use strict";

import avatar from "../../images/avatar.jpeg";

/**
 * @ngdoc directive
 * @name diceApp.directive:dicePersonAvatar
 * @description Shows the avatar photo of a person
 */
angular.module("diceApp").component("dicePersonAvatar", {
  templateUrl: "es6/members/person.avatar.html",
  bindings: {
    person: "<",
    viewClasses: "<?"
  },
  controllerAs: "avatarController",
  controller: function () {
    const avatarController = this;

    avatarController.$onInit = function () {
      avatarController.avatarUrl = avatar;
    };
  }
});
