"use strict";

/**
 * @ngdoc directive
 * @name diceApp.directive:NotificationTemplateInput
 * @description
 * The NotificationTemplateInput directive.
 */
angular.module("diceApp").component("notificationTemplateInput", {
  templateUrl: "es6/notifications/template/notifications.template.input.html",
  require: {
    ngModelCtrl: "^ngModel"
  },
  bindings: {
    inputId: "@?",
    ngDisabled: "<?",
    ngRequired: "<?",
    placeholders: "<?",
    minLength: "<?"
  },
  controllerAs: "descriptionInputController",
  controller: function (
    language,
    $scope,
    $window,
    $document,
    NotificationTemplate
  ) {
    const descriptionInputController = this;

    descriptionInputController.$onInit = function () {
      descriptionInputController.id = Math.random().toString(36).substring(7);

      descriptionInputController.descriptions = [];
      descriptionInputController.ngModelCtrl.$render = () => {
        setDescriptions();
      };

      const placeholders = _.split(
        descriptionInputController.placeholders,
        ","
      );
      descriptionInputController.patterns = _(placeholders)
        .map(_.trim)
        .filter((value) => !!value)
        .map((value) => `[${value}]`)
        .value();

      setTags();
    };

    function setDescriptions() {
      const current = descriptionInputController.ngModelCtrl.$modelValue || [];
      descriptionInputController.descriptions = _.map(
        language.supported,
        (supported) => {
          var model = _.find(current, { language: supported });
          if (angular.isUndefined(model)) {
            model = {
              language: supported
            };
          }
          return model;
        }
      );
    }

    function setTags() {
      NotificationTemplate.tags().$promise.then((result) => {
        descriptionInputController.tags = [];
        _.forOwn(result, (value, key) => {
          if (_.isString(value)) {
            descriptionInputController.tags.push({
              name: key,
              value: value
            });
          }
        });
      });
    }

    descriptionInputController.onChange = function () {
      descriptionInputController.ngModelCtrl.$setViewValue(
        descriptionInputController.descriptions
      );
    };

    descriptionInputController.getCursorPosition = function () {
      if (descriptionInputController.tag) {
        var sel, range;
        sel = $window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.insertNode(
            $document[0].createTextNode(
              "[" + descriptionInputController.tag + "]"
            )
          );
          delete descriptionInputController.tag;
        }
      }
    };
  }
});
