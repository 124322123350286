"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:evaluationTypeahead
 * @description Select an evaluation
 */
angular.module("diceApp").component("evaluationTypeahead", {
  bindings: {
    evaluationTypeId: "<",
    isDisabled: "<?"
  },
  templateUrl: "es6/evaluations/evaluation.typeahead.html",
  require: {
    ngModelCtrl: "ngModel"
  },
  controllerAs: "evaluationTypeaheadController",
  controller: function (Evaluation, $element, language) {
    const evaluationTypeaheadController = this;

    evaluationTypeaheadController.$onInit = function () {
      evaluationTypeaheadController.ngModelCtrl.$parsers.push((viewValue) => {
        if (_.isObject(viewValue)) {
          return _.get(viewValue, "id");
        } else if (_.isNumber(viewValue)) {
          return viewValue;
        }
      });

      evaluationTypeaheadController.ngModelCtrl.$render = () => {
        if (evaluationTypeaheadController.ngModelCtrl.$viewValue) {
          Evaluation.get({
            id: evaluationTypeaheadController.ngModelCtrl.$viewValue
          }).$promise.then((evaluation) => {
            evaluationTypeaheadController.evaluation = evaluation;
          });
        } else {
          delete evaluationTypeaheadController.evaluation;
        }
      };
    };

    evaluationTypeaheadController.getEvaluations = function (text) {
      const params = _.extend(evaluationTypeaheadController.params, {
        evaluationType: evaluationTypeaheadController.evaluationTypeId,
        language: language.get(),
        text: _.lowerCase(text),
        page: 0,
        size: 10,
        order: ["code,ASC", "period.startDate,DESC"]
      });

      evaluationTypeaheadController.loading = true;
      return Evaluation.search(params)
        .$promise.then((result) => {
          evaluationTypeaheadController.totalElements = result.totalElements;
          return _.map(result.content, (evaluation) => {
            return _.extend(evaluation, {
              label: `${evaluation.code} - ${evaluation.name} (${evaluation.period.period} / ${evaluation.period.year})`
            });
          });
        })
        .finally(() => {
          evaluationTypeaheadController.loading = false;
        });
    };

    evaluationTypeaheadController.setFocus = function () {
      $element.find("input")[0].focus();
    };

    evaluationTypeaheadController.onChange = function (value) {
      if (angular.isUndefined(value)) {
        delete evaluationTypeaheadController.evaluation;
      }

      evaluationTypeaheadController.ngModelCtrl.$setViewValue(
        angular.copy(value)
      );
    };
  }
});
