"use strict";

/**
 * @ngdoc function
 * @name diceApp.component:Timeline
 * @description
 * Timeline display data in a timeline.
 */
angular.module("diceApp").component("statusTimeline", {
  bindings: {
    evaluation: "<"
  },
  require: "ngModel",
  templateUrl: "es6/status/status.timeline.html",
  controllerAs: "timelineController",
  controller: function ($scope, StatusType, StatusLog) {
    const timelineController = this;

    timelineController.$onInit = function () {
      timelineController.timeline = [];

      StatusType.query().$promise.then(function (statuses) {
        timelineController.allStatuses = _.filter(statuses, {
          evaluationType: timelineController.evaluation.type.id
        });
      });
    };

    $scope.$watch("timelineController.passed", filterStatuses);
    $scope.$watch("timelineController.allStatuses", filterStatuses);

    function filterStatuses() {
      if (_.isEmpty(timelineController.timeline)) {
        _.forEach(timelineController.allStatuses, function (r) {
          timelineController.timeline.push({
            name: r.name,
            labels: r.labels,
            sequence: r.sequence,
            status: r.sequence === 0 ? "passed" : "remaining",
            showInTimeline: r.showInTimeline
          });
        });
      }

      _.forEach(timelineController.timeline, function (item) {
        _.forEach(timelineController.passed, function (passed) {
          if (item) {
            if (passed.endStatus === item.name) {
              item.created = passed.created;
              item.userName = passed.userName;
              item.status = "passed";
            }
          }
        });

        item.hide = item.status === "remaining" && !item.showInTimeline;
      });

      setCurrent();
      setStyle();
    }

    function setStyle() {
      var visibles = _.filter(timelineController.timeline, { hide: false });
      var width = 100 / visibles.length;
      _.forEach(visibles, function (item, index) {
        item.style = {
          width: width + "%",
          left: width * index + "%"
        };
      });
    }

    function setCurrent() {
      let item = _.find(timelineController.timeline, (current) => {
        return current.name === timelineController.evaluation.status.name;
      });
      if (angular.isDefined(item)) {
        item.status = "current";
      }
    }

    timelineController.getCSSClassForStatus = function (status) {
      if (status === "passed") {
        return "dot";
      } else if (status === "current") {
        return "dot dot-current";
      } else {
        return "dot dot-remaining";
      }
    };

    $scope.$watch("timelineController.evaluation.status", getStatusLogs);

    function getStatusLogs() {
      if (angular.isDefined(timelineController.evaluation.id)) {
        StatusLog.query({
          evaluationId: timelineController.evaluation.id
        }).$promise.then(function (statusLogs) {
          timelineController.passed = statusLogs;
        });
      }
    }
  }
});
