"use strict";

angular.module("diceApp").component("diceForgotPasswordPerform", {
  templateUrl:
    "es6/security/forgot-password/forgot-password-perform.component.html",
  controllerAs: "forgotPasswordPerformController",
  bindings: {
    personId: "@",
    code: "@"
  },
  controller: function (forgotPasswordDatasource, $state) {
    const forgotPasswordPerformController = this;
    forgotPasswordPerformController.form = {};

    forgotPasswordPerformController.validator = function () {
      return (
        forgotPasswordPerformController.form.newPassword ===
        forgotPasswordPerformController.form.confirmPassword
      );
    };

    forgotPasswordPerformController.submit = function () {
      forgotPasswordDatasource
        .changePassword(
          forgotPasswordPerformController.personId,
          forgotPasswordPerformController.code,
          forgotPasswordPerformController.form.newPassword
        )
        .then(() => {
          $state.go("login");
        });
    };
  }
});
