"use strict";

/**
 * Formats a date to the dice date format.
 * Usage goes as follows:
 * {{ date | diceDate }}
 */
angular.module("diceApp").filter("diceDate", function ($filter) {
  return function (date) {
    if (!date) {
      return "";
    } else {
      // eslint-disable-next-line no-undef
      return $filter("date")(date, TE_EV_APP.dateFormat);
    }
  };
});
